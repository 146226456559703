import { takeEvery, takeLatest, all } from "redux-saga/effects";
import * as actionType from '../actions/actionTypes'
import * as allSaga  from "./dashBoardSaga";




export function* watchDashBoard (){
    all([ yield takeLatest(actionType.INIT_STORE_HOURS, allSaga.getHoursSaga),
    yield takeLatest(actionType.SELECTED_MONTH, allSaga.handleSelectionSaga),
    yield takeLatest(actionType.INIT_STORE_EXPENSES, allSaga.GetExpensesSaga),
    yield takeEvery(actionType.INIT_GET_ALLMONTHS, allSaga.getMonthAggregatedSaga),
    yield takeEvery(actionType.UPDATE_ROW, allSaga.updateRowSaga),
    yield takeEvery(actionType.INIT_STORE_PROJECT_LIST,allSaga.getProjectSaga),
    yield takeEvery(actionType.INIT_HANDLE_PROJECT_SECTECTION, allSaga.handleSelectedProjectSaga),
    yield takeEvery(actionType.UPLOAD_IMAGE, allSaga.uploadImgSaga),
    yield takeEvery(actionType.UPLOAD_XLS, allSaga.uploadXLSSaga),
    yield takeEvery(actionType.TEE_KYSELY, allSaga.teeKysely),
    yield takeLatest(actionType.INIT_DATA, allSaga.initializeData),
    yield takeLatest(actionType.SEARCH_PERSON, allSaga.searchPersonSaga),
    yield takeLatest(actionType.SEARCH_FILE, allSaga.searchFileSaga),
    yield takeLatest(actionType.ADD_PROJECT, allSaga.addProjectSaga),
    yield takeLatest(actionType.INIT_STORE_SHORTLISTED_MONTHLYHOURS, allSaga.shortListedSaga),
    yield takeEvery(actionType.UPLOAD_FILE, allSaga.uploadFileSaga),
    yield takeEvery(actionType.SEND_INVOICE, allSaga.makeInvoiceSaga),
    yield takeEvery(actionType.SEND_CLIENT_INVOICE, allSaga.makeClientInvoiceSaga),
    yield takeEvery(actionType.COPY_REF, allSaga.copyRef)
    ])  
}
