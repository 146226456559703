import React, { Component } from "react";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from 'moment'
//import { date2isodate } from "../../../Helpers";

// air-BnB maintains this date picker there are lots of stars on gitgub
class Dates extends Component {
  state = { date: this.props.value ? moment(this.props.value, "YYYY-MM-DD") : null,
            focused: false ,
            showcleardate: (this.props.showcleardate && true) };

  handleDateChange = date => {
    const dateStr = moment(date).format("YYYY-MM-DD");
    const {uuid,keyedChangeHandler, key, field,name} = this.props
    if(dateStr!=="Invalid date") {
      keyedChangeHandler(uuid, key || field ||name , dateStr);
    }else{
      keyedChangeHandler(uuid, key || field ||name , null);
    }
    this.setState({ date });
  };

  render() {    
    const { focused,showcleardate} = this.state
    const date = this.props.value ? moment(this.props.value, "YYYY-MM-DD"):null 
    const returnFalse = ()=>false
    return (
      <div style={{display:'inline-block'}}>
        <SingleDatePicker
          showClearDate={showcleardate}
          displayFormat="YYYY-MM-DD"
          small={true}
          appendToBody={true}
          firstDayOfWeek={1}
          numberOfMonths={1}
          isDayBlocked={returnFalse}
          isOutsideRange={returnFalse}
          date={date}
          onDateChange={this.handleDateChange}
          focused={focused}
          onFocusChange={({ focused }) => this.setState({ focused })}
          openDirection="down"
          hideKeyboardShortcutsPanel={true}          
        />
      </div>
    );
  }
}
export default Dates;
