import React, { Component } from 'react'
import { Button } from "semantic-ui-react";

 class XLSUploader extends Component {

     state = {
         file: null,
         
     };

     inputChangeHandler = e => {
         const { uploadXLS } = this.props
         const file = e.target.files[0] 
         uploadXLS({
             xls_data: file
         })
     };
    



  render() {
    
    return <React.Fragment>
        <input style={{ display: "none" }} 
            onChange={this.inputChangeHandler} 
            ref={fileInput => (this.fileInput = fileInput)} type="file" />
            <Button
                      onClick= { e=>this.fileInput.click() }
                      className="leftButton"
                      color="blue"
                      content="Lähetä XLS"
                      icon="cloud upload"
                    />

      </React.Fragment>;
  }
}
export default XLSUploader;