import React, {PureComponent} from 'react'
import { Icon } from "semantic-ui-react";

export class TokenView extends PureComponent{
    
    render(){
        const {items, style, onKill} = this.props
        const container_style = {
            bgcolor:'#ffffffcc',
            border: '1px grey solid',
            borderRadius: '3px',
            padding:'5px',
            maxWidth:'340px',
            minHeight:'50px'
        }
        const item_style = {
            background: '#ccccffcc',
            border: '1px blue solid',
            borderRadius: '5px',
            margin: '5px',
            padding: '2px',
            display:'inline-block'

        }
        

        return (
            <div style={{...container_style, ...style}} >
            {items.map((i)=>(
                <span style={item_style} key={i}> {i} <Icon color="teal" name="close" title='Poista' onClick={()=>{onKill(i)}} /></span>
            ))}
            </div>
        )

    }
}
export default TokenView