import React, { Component,Fragment } from "react";
import {date2isomonth,fix2} from '../../../Helpers'



class MonthlyRow extends Component {
    className = (e, future = false) => {
        if(e.active){
            return "activeMonthRow"
        }else if(future === false){
            return "normalMonthRow"
        }else{
            return "futureMonthRow"
        }
    }
    render(){
        const thisMonth = date2isomonth(new Date() )
        const {monthInfo,onClick} = this.props
        if(monthInfo.month > thisMonth){            
            return (
                <div
                onClick = { onClick }
                className = {this.className(monthInfo, true)}
               > 
               <div className="monthHeader">{monthInfo.month}</div>
               <span className="monthDetail"> Ennuste: {monthInfo.ennuste} h
               </span>
               </div>
   
            )
        }
        
        return (
            <div
             onClick = { onClick }
             className = {this.className(monthInfo)}
            > 
            <div className="monthHeader">{monthInfo.month}</div>
            <span className="monthDetail"> {fix2(monthInfo.hours)} h
                ( {monthInfo.atunnit > 0 && <Fragment>{fix2(monthInfo.atunnit)}A </Fragment> }
                 {monthInfo.btunnit > 0 && <Fragment>{fix2(monthInfo.btunnit)}B </Fragment> }
                 {monthInfo.ctunnit > 0 && <Fragment>{fix2(monthInfo.ctunnit)}C </Fragment> }
                 {monthInfo.dtunnit > 0 && <Fragment>{fix2(monthInfo.dtunnit)}D </Fragment> }
                ){ fix2(monthInfo.atunnit / monthInfo.hours * 100)} A%
                {monthInfo.expences > 0 ? <span>,  {monthInfo.expences} €</span>: <span />}
                {monthInfo.sickdays > 0 ? <span>, {monthInfo.sickdays} sairaspäivä{monthInfo.sickdays !== 1 && 'ä'}</span> : <span />}
                {monthInfo.vacations> 0 ? <span>, {monthInfo.vacations} lomapäivä{monthInfo.vacations !== 1 && 'ä'}</span>: <span />}                
                {monthInfo.lomarahapaivia> 0 ? <span>, {monthInfo.lomarahapaivia} lomarahapäivä{monthInfo.lomarahapaivia !== 1 && 'ä'}</span>: <span />}                
                { monthInfo.saldo && <span style={{position:'absolute',right:'5px'}}>Saldo: {fix2(monthInfo.saldo)} h</span>}
            </span>
            </div>
        )
    }
}

export default MonthlyRow;