import React, {PureComponent} from 'react'
import {Icon} from "semantic-ui-react";
import {timestamp2month} from '../../../Helpers'
import { v4 as uid } from 'uuid';
import {month2isodate, fix1, fix0, fix2} from '../../../Helpers'
import DropDown from '../DropDown'

const EDIT_INTERVAL = 1000;


class ProjektiTyoEnnuste extends PureComponent {
    state = {
        editing: false,
        project_uid: this.props.project_uid,
        project: this.props.project,
        updateProjectData: this.props.updateProjectData,
        updateRow: this.props.updateRow,
        newPeople: [],
        shortlisted:false
    }

    static getDerivedStateFromProps(props, state) {
        if (!props.shortlisted) {
            return {...state, ...props, editing: false}
        }
        if (props.project_uid !== state.project_uid) {
            return {...state, ...props, editing: false};
        }
        if (!state.editing) {
            return {...state, ...props};
        }
        return null;
    }

    constructor(props) {
        super(props)
        this.lookupMap = props.lookupData.persons.reduce((a, c) => {
            a[c.uid] = c
            return a
        }, {})
    }

    limited_grade = (g) => g==='Analyytikko'||g==='Manager'||g==='Konsultti'||g==='Senior Manager'
    person_limit  = (pdata, hrs)=>{
        return pdata && hrs>=5 && hrs<30
            && this.limited_grade(pdata.grade)
            && pdata.tiimi<200 && pdata.tiimi !== 107
    }
    person_monthlimit = (pdata,muuennuste)=>{
        return muuennuste>150
            && this.limited_grade(pdata.grade)
            && pdata.tiimi !== 202
            && pdata.tiimi !== 107
    }


    editTimers = {}
    onChange = (month, person, event) => {
        if(person==='Delta') return;//ei tehdä muutoksia Deltalle
        const {updateProjectData, project,shortlisted} = this.state
        const {lookupData}=this.props
        let smaller = false
        let old_data = {
            henkilo_uid: person,
            projekti_uid: project.uid,
            projekti_uuid: project.uuid,
            kuukausi: month,
            tuntimaara: event.target.value,
            uuid: uid()
        }

        let old_index = project.tyoennuste.findIndex(a2 => a2.kuukausi === month && a2.henkilo_uid === person)
        if (old_index >= 0) {
            old_data = project.tyoennuste[old_index]
            smaller = event.target.value < old_data['tuntimaara']
            old_data['tuntimaara'] = event.target.value
            project.tyoennuste[old_index] = old_data
        } else {
            project.tyoennuste.push(old_data)
        }
        updateProjectData('projekti', project)
        // ei lähetetä kantaan, jos ei täytä ehtoja
        if( !shortlisted && !(lookupData && lookupData.persons)){ // ei voida tarkistaa
            return;
        }
        if(false && !shortlisted && project && project.tyyppi === 'A'){ // ei enää pakoteta
            const pdata = lookupData.persons.find(a=>a.uid===person)
            const muuennuste = lookupData.hloennusteet[`${month}:${person}`]
            if( this.person_limit(pdata,event.target.value) ) return;
            if( this.person_monthlimit(pdata,muuennuste) && !smaller) return;
        }
        clearTimeout(this.editTimers[old_data.uuid])
        this.editTimers[old_data.uuid] = setTimeout(() => this.sendEdit({
            ...old_data,
            kuukausi: month2isodate(old_data.kuukausi)
        }), EDIT_INTERVAL)
        this.setState({project})
    }

    sendEdit = (ennuste) => {
        this.props.updateRow({
            info: 'projekti.tyoennuste',
            className: 'projektiennuste',
            action: 'upsert',
            data: ennuste // convert to full date for db
        })
    }

    toggleEditing = () => {
        if (this.state.editing) {
            this.setState({editing: false})
        } else {
            this.setState({editing: true})
        }
    }
    el = ({rowMatrix, onChange, editing,project, month, person}) => {
        const {lookupData} = this.props
        const muuennuste = lookupData.hloennusteet[`${month}:${person}`]
        let tooltip = `${person}/${month} (${muuennuste} h)`
        let style = {textAlign: 'center', display: 'block'}

        const record = rowMatrix[month].find(a => a.henkilo_uid === person)
        let e = record ? record.tuntimaara : ''

        if (person === 'Delta') {

            style.color = e > 0 ? '#ff2288' : '#22aa11'
        }else if(person ==='Yhteensä' && project && project.tyyppi==='A'){
            if( e<100){ // Liian pieni bookkaus yhteensä
                style.color= '#ff2288'
            }
        } else if(project && project.tyyppi==='A'){
            const pdata = lookupData && lookupData.persons ? lookupData.persons.find(a=>a.uid===person) : null
            if( this.person_limit(pdata,e) ){
                style.fontWeight=700
                style.color= '#ff2288'
            }
            //150 per hlo raja
            if( this.person_monthlimit(pdata,muuennuste)){
                style.fontWeight=500
                style.color= '#f38c41'
            }
        }


        const key = `${person}/${month}`
        if ((!e || e === '') && !editing) return <span key={key}/> // do not render null or empty items unless editing
        if (!e) e = '' // avoid null values in react components https://reactjs.org/docs/forms.html#controlled-input-null-value
        const iw = (Math.max(3, (e + "").length) * 1.1) + 'ch' // input width
        return editing && month !== '1970-01' && month !== 'Yhteensä' && person !== 'Yhteensä' ?
            <span style={{padding: '0', margin: '0'}}> <input style={{width: iw, padding: '2px', margin: '0'}}
                                                              type='text' name={key}
                                                              title={tooltip} className="tightProjectInput" value={e}
                                                              onChange={(e) => onChange(month, person, e)}
                                                              placeholder={0}/> &nbsp;</span>
            : <span style={style} title={tooltip}>{e}</span>
    }

    title4nimi(nimi, person) {
        const {lookupData} = this.props
        if (person === 'Yhteensä') {
            return ""
        } else if (person === 'Delta') {
            return ""
        } else {
            return nimi + " / " + fix0(lookupData.laskutushinnat[person]) + "€"
        }
    }

    style4nimi(nimi, person) {
        if (person === 'Delta') return {'color': '#ff2288'}
        return {}
    }

    render() {
        const {project, editing, newPeople} = this.state
        const {dropDownData} = this.props

        // compute matrix to show hours in a table
        // show last 6 months of actual data and lupm older to final row
        const next6 = (() => {
            let d = new Date(timestamp2month(+new Date() - (1000 * 3600 * 24 * 35 * 3)))
            let a = [timestamp2month(+d)]
            for (let i = 0; i < 14; i++) {
                d = new Date(timestamp2month(+new Date(d - -(1000 * 3600 * 24 * 35))))
                a.push(timestamp2month(+d))
            }
            return a
        })()
        next6.push('9999-01')

        const rowMatrix = (() => {
            let tot = [...project.tyoennuste]
            let m = {Totals: {}}
            for (let i in next6) {
                const kk = next6[i]
                let r = tot.filter(e => e.kuukausi === kk)
                r.forEach((i) => {
                    m['Totals'][i.henkilo_uid] = (m['Totals'][i.henkilo_uid] || 0) - -i.tuntimaara
                })
                m[kk] = r
            }
            m['Yhteensä'] = Object.keys(m['Totals']).map(a => ({henkilo_uid: a, tuntimaara: m['Totals'][a]}))

            return m
        })()
        next6.push('Yhteensä')

        const totals = rowMatrix['Totals']
        let people = Object.keys(totals)
        //people.sort((a,b)=>{return -(totals[a]-totals[b])})
        people.sort()//(a,b)=>{return a.attr.localeCompare(b.attr) })
        people = [...(new Set([...newPeople, ...people, 'Yhteensä', 'Delta']))]
        let tot = [...project.tyototeuma]
        Object.keys(rowMatrix).forEach(m => {
            try {
                const summa = rowMatrix[m].map(a => a.tuntimaara).reduce((a, c) => a - -c, 0)
                if (m < next6[5]) {
                    const kktot = tot.filter(a => (a.kuukausi === m)).reduce((a, c) => (a - -c.tuntimaara), 0)
                    rowMatrix[m] = [...rowMatrix[m], {
                        henkilo_uid: 'Yhteensä',
                        tuntimaara: summa
                    }, {henkilo_uid: 'Delta', tuntimaara: fix0(kktot - summa)}]
                } else {
                    rowMatrix[m] = [...rowMatrix[m], {henkilo_uid: 'Yhteensä', tuntimaara: summa}]
                }
            } catch (e) {
                // no biggie
            }
        })

        const def = {rowMatrix, editing, onChange: this.onChange, project}

        const fullTotal = project.tyoennuste.reduce((a, c) => next6.includes(c.kuukausi) ? a - -c.tuntimaara : a, 0)
        const lmap = this.lookupMap
        return (
            <div style={{marginLeft: '20px'}}>
             <span className="hoursIcon" onClick={() => this.toggleEditing()}>
                <Icon color={editing ? "red" : "black"} size="large" name="edit"/>
            </span>

                <table style={{border: '1px solid lightgray', padding: '5px', borderRadius: '5px'}}>
                    <thead>
                    <tr>
                        <td>&nbsp;</td>
                        {next6
                            .map(kk => {
                                if (kk === '1970-01') return 'Aiemmin'
                                if (kk === '9999-01') return 'Myöhemmin'
                                return kk
                            })
                            .map(h => <td key={h} style={{margin: '3px 5px 3px 5px', padding: '3px 5px'}}><span key={h}
                                                                                                                style={{
                                                                                                                    fontWeight: 400,
                                                                                                                    marginLeft: '0px',
                                                                                                                    marginRight: '0px'
                                                                                                                }}>{h[0] === "2" ? h.slice(-2) : h}</span>
                            </td>)}</tr>
                    </thead>
                    <tbody>
                    {people.map(person => {
                        const nimi = lmap && lmap[person] ? lmap[person].nimi : ""
                        return (<tr key={person} title={this.title4nimi(nimi, person)}
                                    style={this.style4nimi(nimi, person)}>
                                <th>{person}</th>
                                {next6.map(month => <td style={{margin: '3px 5px 3px 5px', padding: '3px 5px'}}
                                                        key={month}>
                                    {this.el({...def, month, person})}
                                </td>)}</tr>
                        )
                    })}
                    </tbody>
                </table>
                <div style={{marginLeft: '10px', fontWeight: '600'}}>Yhteensä: {fix2(fullTotal)} h
                    ({fix1(fullTotal / 7.5)} pv)
                </div>
                {editing &&
                <DropDown
                    valuemap={dropDownData.persons}
                    keyedChange={this.addPersonChange}
                    uuid={project.uuid}
                    name={'projektityontekija'}
                    placeholder={"Lisää työntekijä"}/>
                }
            </div>
        )
    }

    addPersonChange = (uuid, key, value) => {
        // tämä lisää vain stateen henkilön, commit kantaan tulee vasta kun sille laitetaan tunteja
        this.setState({newPeople: [value, ...this.state.newPeople]})
    }

}

export default ProjektiTyoEnnuste