import {put, all, select} from "redux-saga/effects";
import axios from "axios";
import * as actions from "../actions/actionCreators";
import url from '../../BackEnd';
import {currentMonth} from '../../Helpers';
import {delay} from "redux-saga/effects";
import * as store from './selectors';
import { v4 as uuid } from 'uuid';

const nonfatalerror = (e) => {
    console.log(e.message)
}
const getWithToken = async (jwt,url) => {
    console.log(url)
    const config = {headers: {"Authorization": `Bearer ${jwt}`}}
    const res = axios.get(url, config).catch(e => nonfatalerror(e));
    return res;
}
const postWithToken = async (jwt,url, form) => {
    const res =  axios.post(url, form
        , {headers: {"Authorization": `Bearer ${jwt}`}}
    ).catch(e => fatalerror(e) );
    return res
}


const fatalerror = (e) => {
    if (!e || !e.response) {
        alert("Tietoa ei voitu tallettaa; Mahdollinen verkkovirhe. Päivitä sivu ja yritä uudelleen")
        console.log(e)
        return;
    }
    const status = e.response.status
    if (status === 409) {
        alert("Tietoa ei voitu tallettaa; kantaan tulisi päällekäisiä kirjauksia")
    } else if (status === 500) {
        alert("Tietoa ei voitu tallettaa; päivitä sivu ja yritä uudelleen")
        console.log(e)
    } else {
//    Auth.shared().signOut();
        alert("Tietoa ei voitu tallettaa; Mahdollinen verkkovirhe. Päivitä sivu ja yritä uudelleen");
        console.log(e)
        window.location.reload(true)
    }
}

// All the saga function that handle side effects from actions generated from Dashboard.js component
export function* getHoursSaga(action) {
    const selectedMonth = yield action.month;
    const pseudo_uid = yield select(store.pseudo_uid)
    const jwt= yield select(store.jwt)
    const hours = yield getWithToken(jwt,`${url}hours?pseudo_uid=${pseudo_uid}&month=${selectedMonth}`)
    yield put(actions.storeHours(hours.data));
}

export function* getProjectSaga() {
    const jwt= yield select(store.jwt)
    const projects = yield getWithToken(jwt,`${url}projectsAggregated`)
    yield all([
        put(actions.storeProjects(projects.data)),
        put(actions.displayProject())
    ]);
}

export function* handleSelectionSaga(action) {
    try {
        const jwt= yield select(store.jwt)
        const [hours, expenses] = yield all([
            getWithToken(jwt,`${url}hours?month=${action.month}&pseudo_uid=${action.pseudo_uid}`),
            getWithToken(jwt,`${url}expenses?month=${action.month}&pseudo_uid=${action.pseudo_uid}`)
        ])
        yield all([
            put(actions.storeHours(hours.data)),
            put(actions.storeExpenses(expenses.data))
        ]);

    } catch (error) {
        console.log(error);
    }
}

export function* handleSelectedProjectSaga(action) {
    if (!action || !action.project) return;
    try {
        const uuid = action.project.uuid
        const uid = action.project.uid
        const jwt= yield select(store.jwt)
        const project = yield uuid ? getWithToken(jwt,`${url}project?project_uuid=${uuid}`)
            : getWithToken(jwt,`${url}project?project_uid=${uid}`)
        if (null == project) return;
        yield all([
            put(actions.storeCurrentProject(project.data))
        ]);
    } catch (error) {
        console.log(error);
    }
}


export function* GetExpensesSaga(action) {
    try {
        const pseudo_uid = yield select(store.pseudo_uid)
        const jwt= yield select(store.jwt)
        const expenses = yield getWithToken(jwt,`${url}expenses?month=${action.month}&pseudo_uid=${pseudo_uid}`)
        yield put(actions.storeExpenses(expenses.data));
    } catch (error) {
        console.log(error);
    }
}

export function* initializeData(action) {
    try {
        console.log("initializing data")
        const accn = action.data.account
        const jwt= action.data.jwt
        const config = {headers: {"Authorization": `Bearer ${jwt}`}}
        console.log("got jwt")
        const [person, dropDown, lookup, monthAggregated, projects] = yield all([
            getWithToken(jwt,`${url}person`),
            getWithToken(jwt,`${url}dropDownData`),
            getWithToken(jwt,`${url}lookupData`),
            getWithToken(jwt,`${url}monthAggregated`),
            getWithToken(jwt,`${url}projectsAggregated`)
        ])
        console.log("got urls")

        const currentMonthData = monthAggregated.data.find(a => a.month === currentMonth)
        console.log(currentMonthData)

        yield all([
            put(actions.storeUser(action.data)),
            put(actions.storePerson(person.data)),
            put(actions.storeDropDown(dropDown.data)),
            put(actions.storeLookup(lookup.data)),
            put(actions.storeMonthLyHours(monthAggregated.data)),
            put(actions.storeProjects(projects.data)),
            put(actions.initstoreHours(currentMonth)),
            put(actions.initGetExpenses(currentMonth)),
//      put(actions.handleSelection(currentMonth,null,null))
            put(actions.getSelectedMonth(currentMonth, currentMonthData, null)),
            put(actions.displayHours())
        ])
        console.log("initialization ready")
    } catch (error) {
        console.log(error)
    }
}

/*
  Initializer action is now refactored to initializeData, which loads other data as well
  This still gets the updated data needed when a user presses "hours"
  However, it's never updated and thus this is never called.
  TODO => define update triggers to update data when hourly data changes

 */
export function* getMonthAggregatedSaga(action) {
    try {
        if (action.data.project_uid) {
            const uid = action.data.project_uid;
            const jwt= yield select(store.jwt)
            const project = yield getWithToken(jwt,`${url}project?project_uid=${uid}`)
            yield all([
                put(actions.storeCurrentProject(project.data))
            ]);
        } else {
            const selectedMonth = action.data.month;
            console.log(selectedMonth)
            const pseudo_uid = yield select(store.pseudo_uid)
            const jwt      = yield select(store.jwt)
            const [person, monthAggregated] = yield all([
                    getWithToken(jwt,`${url}person?pseudo_uid=${pseudo_uid}`),
                    getWithToken(jwt,`${url}monthAggregated?pseudo_uid=${pseudo_uid}`)
                ]
            );

            yield all([
                put(actions.storeMonthLyHours(monthAggregated.data)),
                put(actions.storePerson(person.data)),
                put(actions.initstoreHours(selectedMonth)),
                put(actions.initGetExpenses(selectedMonth))

            ]);
        }
    } catch (error) {
        console.log(error);
        // i will add an action that will store error in the store
    }
}

let token_timestamp =0
let token_user_uuid=""
let token_counter=0
function ncounter() {
    const now = new Date().getTime()
    if ((now - token_timestamp) > 300000) { // jos edellisestä editistä on yli 300 s, paketti on iäksi mennyt enkä välitä enää järjestyksestä
        token_user_uuid = uuid()
        token_counter = 0
    }
    token_timestamp = now
    const counter = token_counter++;
    const user_uuid = token_user_uuid;
    return {counter, user_uuid}
}
export function* updateRowSaga(action) {
    try {
        const {counter, user_uuid} = ncounter()
        const req = {
            'transaktio': user_uuid,
            'seq': counter,
            rows: [action.row]
        };
        const jwt      = yield select(store.jwt)
        yield postWithToken(jwt,`${url}edit`, req)
        console.log(new Date() + ": posted update... " + (action.row.info || ""))

        //TODO check if search is set
        // also update projektiMittarit/current project
        if (action.row.className === 'projekti'
        ) {
            yield delay(1000)
            const search = yield select(store.search)
            console.log("using search: " + search)
            const uuid = action.row.data.uuid
            yield searchPersonSaga({search})
            yield handleSelectedProjectSaga({project: {uuid}})
        }
        if (action.row.className === 'projektiasiakas'
            || action.row.className === 'projektiennuste'
            || action.row.className === 'laskutusennuste'
        ) {
            yield delay(1000)
            const search = yield select(store.search)
            const uid = action.row.data.projekti_uid
            const uuid = action.row.data.projekti_uuid
            yield searchPersonSaga({search})
            yield handleSelectedProjectSaga({project: {uid, uuid}})
        }
    } catch (error) {
        console.log("error in updateRow: " + error);
    }
}

// keeps trace of the progress in file/images upload
const config = {
    headers: {"Content-Type": "multipart/form-data"},
    onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
        );
        console.log(percentCompleted);
        actions.uploadProgress(percentCompleted)
    }
};

export function* uploadImgSaga(action) {
    try {
//val id:String, val filename:String, val uuid:String, val imageData: ByteArray )
        //for updating the redux store && sending to the server
        const formData = new FormData();
        formData.set("image_data", action.img.image_data);
        formData.set("ref_table", action.img.ref_table);
        formData.set("ref_key", action.img.ref_key);
        formData.set("uuid", action.img.uuid)
        const jwt      = yield select(store.jwt)
        config.headers["Authorization"] = `Bearer ${jwt}`
        yield axios.post(`${url}imageUpload`, formData, config).catch(e => fatalerror(e))
        yield delay(1000)
        const month = yield select(store.month)
        const pseudo_uid = yield select(store.pseudo_uid)

        yield handleSelectionSaga({month, pseudo_uid})
    } catch (error) {
        console.log(error);
    }
}

export function* copyRef(action) {
    const full_url = `${url}copyRef?from=${action.project_uid}&to=${action.target_uid}`
    yield axios.get(full_url)
    yield put(actions.initGetCurrentProject({uid: action.target_uid}))
}

export function* uploadFileSaga(action) {
    try {
        const formData = new FormData();
        formData.set("file_data", action.file.file_data);
        formData.set("ref_table", action.file.ref_table);
        formData.set("ref_key", action.file.ref_key);
        formData.set("uuid", action.file.uuid)
        formData.set("tags", action.file.tags)

        const jwt      = yield select(store.jwt)
        config.headers["Authorization"] = `Bearer ${jwt}`
        yield axios.post(`${url}fileUpload`, formData, config).catch(e => fatalerror(e))

        if (action.file.ref_table === 'projekti') {
            const uuid = action.file.ref_key
            yield delay(500)

            yield handleSelectedProjectSaga({project: {uuid}})
        }

    } catch (error) {
        console.log(error);
    }
}

export function* uploadXLSSaga(action) {
    try {
        const formData = new FormData();
        formData.set("xls_data", action.payload.xls_data);
        const jwt      = yield select(store.jwt)
        config.headers["Authorization"] = `Bearer ${jwt}`
        yield axios.post(`${url}xlsUpload`, formData, config).then(response => {
            alert("XLS lähetetty onnistuneesti.")
        }).catch(e => fatalerror(e))
    } catch (error) {
        console.log(error);
    }
}

export function* searchPersonSaga(action) {
    try {
        const s = encodeURIComponent(action.search)
        const jwt      = yield select(store.jwt)
        const {data} = yield getWithToken(jwt,`${url}projectsAggregated?search=${s}`)
        yield put(actions.storeProjects(data))
        yield put(actions.storeSearch(action.search))

    } catch (error) {
        console.log(error);
    }
}

export function* searchFileSaga(action) {
    try {
        const s = encodeURIComponent(action.search)
        const jwt      = yield select(store.jwt)
        const {data} = yield getWithToken(jwt,`${url}files?search=${s}`)
        yield put(actions.storeFiles(data))
        yield put(actions.storeFileSearch(action.search))

    } catch (error) {
        console.log(error);
    }
}

//const strip2uuid = ({uuid})=>({uuid})
export function* makeClientInvoiceSaga(action) {
    try {
        const {counter, user_uuid} = ncounter();
        let invoice = {
            ...action.invoice,
            expenses: action.invoice.expenses.filter(a => a.shouldInvoice === true).map(a => a.uuid)
        }
        //TODO tähän täytyy lisätä päivämäärän vienti back-endiin (sitten kun on editoitava)
        invoice = {
            ...invoice,
            project: invoice.project.uuid,
            ennuste: invoice.ennuste.uuid,
            asiakas: invoice.asiakas.uuid
        }
        const req = {
            'transaktio': user_uuid,
            'seq': counter,
            invoice: invoice
        };
        const jwt      = yield select(store.jwt)
        yield postWithToken(jwt,`${url}createClientInvoice`, req)

        yield delay(1000)
        const search = yield select(store.search)
        const uid = action.invoice.ennuste.projekti_uid
        yield searchPersonSaga({search})
        yield handleSelectedProjectSaga({project: {uid}})
        yield put(actions.cancelClientInvoiceModal())
    } catch (error) {
        console.log(error);
    }

}

export function* makeInvoiceSaga(action) {
    try {
        const {counter, user_uuid} = yield ncounter();
        const pseudo_uid = yield select(store.pseudo_uid)

        const req = {
            'pseudo_uid': pseudo_uid,
            'transaktio': user_uuid,
            'seq': counter,
            expenses: action.invoices.map(a => a.uuid)
        };
        const jwt = yield select(store.jwt)
        yield postWithToken(jwt,`${url}createInvoice`, req)

        yield delay(1000)
        const person = yield getWithToken(jwt,`${url}person`)
        yield put(actions.storePerson(person.data))
        yield put(actions.cancelExpenseModal())
    } catch (error) {
        console.log(error);
    }
}

export function* shortListedSaga(action) {
    try {
        const jwt      = yield select(store.jwt)
        const [monthlytHours, person] = yield all([
            getWithToken(jwt,`${url}monthAggregated?pseudo_uid=${action.pseudo_uid}`),
            getWithToken(jwt,`${url}person?pseudo_uid=${action.pseudo_uid}`)
        ])

        yield all([
            put(actions.storeMonthLyHours(monthlytHours.data)),
            put(actions.storePerson(person.data))
        ])
    } catch (error) {
        console.log(error);
    }
}

export function* addProjectSaga(action) {
    yield all([
        put(actions.storeProjects(action.project.projects)),
        put(actions.storeCurrentProject(action.project.project)),
    ]);
}

export function* teeKysely(action) {
    const os = encodeURIComponent(action.data.osoitteet)
    const jwt      = yield select(store.jwt)
    yield getWithToken(jwt,`${url}teeKysely?osoitteet=${os}&projekti=${action.data.project.uid}`)
}

axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
    if (err.response.status === 403) { // forbidden
        return Promise.reject(err);
    }
    if (err.response.status === 409) {
        return Promise.reject(err);
    }
    if (err.response.status === 500) {
        console.log("rejecting")
        return Promise.reject(err);
    }
    var config = err.config;
    if (!config) return Promise.reject(err);

    // Set the variable for keeping track of the retry count
    config.__retryCount = config.__retryCount || 0;

    // Check if we've maxed out the total number of retries
    if (config.__retryCount >= 3) {
        // Reject with the error
        return Promise.reject(err);
    }

    // Increase the retry count
    config.__retryCount += 1;

    // Create new promise to handle exponential backoff
    var backoff = new Promise(function (resolve) {
        setTimeout(function () {
            resolve();
        }, 5000 || 1);
    });

    // Return the promise in which recalls axios to retry the request
    return backoff.then(function () {
        return axios(config);
    });
});