import React from 'react'
import {timestamp2iso} from '../../../Helpers'
import url from '../../../BackEnd'

const el = (lasku,jwt)=>{
    return(
        <div className='laskuRow' key={lasku.uuid}>
        <span style={{fontWeight:600}}>  {timestamp2iso(lasku.pvm)} &nbsp; &nbsp;
        { ( + lasku.tyosumma || 0).toFixed(2) } + { ( + lasku.kulusumma || 0).toFixed(2) } € </span>
        <span style={{fontWeight:300}}> {lasku.asiakas}, {lasku.selite} </span>
        {lasku.pdf_uuid && <span><a href={`${url}file?jwt=${jwt}&uuid=${lasku.pdf_uuid}`} download > Lasku PDF</a> </span> }
        {lasku.liite_uuid && <span> <a href={`${url}file?jwt=${jwt}&uuid=${lasku.liite_uuid}`} download > Liite PDF</a> </span>}
        </div>
    )
}
const LaskutusToteuma = ({project,jwt}) =>{
    const laskut = project.laskut
    const totaltyo = laskut.map(e=>e.tyosumma).reduce( (o,e) => o- -e , 0.0) 
    const totalkulu = laskut.map(e=>e.kulusumma).reduce( (o,e) => o- -e , 0.0) 

    return (
        <div>        
        {project.laskut.map(e=>el(e,jwt))}
        <div>&nbsp;</div>
        <div style={{fontWeight:600}}> Total: {totaltyo.toFixed(2)} + {totalkulu.toFixed(2)} € </div>
        </div>
    )
}

export default LaskutusToteuma