import React from 'react'
import { Image, List } from "semantic-ui-react";
import {  fix1, fix2 } from '../../Helpers';
import backEnd from '../../BackEnd'
import {fileIcon} from "../../FileIconHelpers"




const UserInfo = ({user,person,displayExpenseModal,jwt}) => {
  const kulusumma = person && person.openExpenses && person.openExpenses.reduce((a,c)=> a+c.verollinenkorvausyhteensa, 0)
  const istyle = {width:'16px', height:'16px', margin:'2px 2px -1px 0 '}
  const NAYTALOMAT = false

  return user ? <div style={{ paddingTop: "20px", textAlign: "left" }}>
    
    {person && person.email !== user.U3 ?
       (person.kuvaurl && <Image centered src={person.kuvaurl} size="small" circular /> )
      :<Image centered src={user.Paa} size="small" circular />
    }
      <div style={{ width: "80%", margin: "0 auto" }}>
        <List>
        {/*
            <List.Item>            
             {user.U3} &nbsp; {person && person.shortlisted && <a title="Download CV-refs" style={{color:'#ffffff',visited:'#aaaaaa'}} href={`${backEnd}/CVReferenssit?henkilo_uid=${person.uid}` } download> <img alt="" src={fileIcon('doc')} style={istyle}/></a>}
            </List.Item>
         */}
          {person && person.email !== user.U3 ?
            <List.Item>
             {person.email} &nbsp; {person /*&& person.shortlisted*/ && <a title="Download CV-refs" style={{color:'#ffffff',visited:'#aaaaaa'}} href={`${backEnd}/CVReferenssit?jwt=${jwt}&henkilo_uid=${person.uid}` } download> <img alt="" src={fileIcon('doc')} style={istyle}/></a>}
           </List.Item>         
           : <List.Item>            
             {user.U3} &nbsp; {person /*&& person.shortlisted*/ && <a title="Download CV-refs" style={{color:'#ffffff',visited:'#aaaaaa'}} href={`${backEnd}/CVReferenssit?jwt=${jwt}&henkilo_uid=${person.uid}` } download> <img alt="" src={fileIcon('doc')} style={istyle}/></a>}
            </List.Item>

          }
          
          {person &&
            <List.Item>
            <span style={{fontWeight:600}}>{person.tyonantaja}</span>
            </List.Item>            
          }
          {person &&
            <span> {person.kapasiteetti} h/kk, {fix1(person.vuodenApros*100)} A%</span>}
          {NAYTALOMAT && person && /*(person.lomiaPitamatta > -1) && */
            <List.Item><span className='smalltext'>{person.lomiaPitamatta} lomapäivää jäljellä</span></List.Item>
          }
          {NAYTALOMAT && person && (person.lomarahapaiviaPitamatta > -1) &&
            <List.Item><span className='smalltext'>{person.lomarahapaiviaPitamatta} lomarahapäivää jäljellä</span></List.Item>
          }
          { kulusumma && kulusumma > 0.0 ?
            <List.Item><span className='smalltext'> Laskuttamatta: {fix2(kulusumma)} € {/*person.shortlisted &&*/ <button onClick={displayExpenseModal}>Laskuta</button>}</span></List.Item>
            :<span />
          }

          
        </List>
      </div>
    </div> : null;
}

export default UserInfo
