import {Icon} from "semantic-ui-react";
import React, {PureComponent, Fragment} from "react";
import { v4 as uuid } from 'uuid';
import {date} from "./MonthlyHours";
import CSVModal from "./CSVModal";
import ExpenseRowNew from "./ExpenserRowNew";
import {date2isodate} from '../../../Helpers'
import {kms2eur, laskePaivaraha} from './Matkakulut';


const WAIT_INTERVAL = 6000;
const EDIT_INTERVAL = 2000;

class MonthlyExpenses extends PureComponent {
    state = {
        selectedMonth: this.props.selectedMonth,
        editing: false,
        expenses: this.props.expenses,
        person: this.props.person
    };
    timer = null;
    editTimers = {};

    static getDerivedStateFromProps(props, state) {
        if (!props.shortlisted) {
            return {...state, ...props, editing: false}
        }
        if (state.editing) {
            return {state}
        }
        if (props.selectedMonth !== state.selectedMonth) {
            return {...state, ...props, editing: false}
        }
        if (!state.editing || props.expenses !== state.expenses) {
            return {...state, ...props};
        }
        return null;
    }

    onChangeHandler = (uuid, key, value) => {
        clearTimeout(this.timer)

        const {selectedMonth, updateExpense} = this.props;
        const old_expenses = this.state.expenses; // we're not mutating so just get reference

        const old_element = old_expenses.find(e => e.uuid === uuid); // find old element, we're not going to mutate it
        let new_element = {...old_element, [key]: value}; // create new element by overriding key:value from old_element


        // fix computational values
        if (!isNaN(new_element.km)) {
            new_element.kmkorvaus = kms2eur(new_element)
        }
        new_element.verotonsumma = (new_element.verollinensumma || 0.0) / (1 - -(new_element.alvprosentti || 0.0))

        if (key === 'pvm' && old_element.pvm === old_element.paluupvm) {
            new_element.paluupvm = new_element.pvm
        }

        if (key === 'lahto' || key === 'paluu' || key === 'paluupvm' || key === 'pvm') {
            if (new_element.lahto != null && new_element.paluu != null) {
                new_element.paivaraha = laskePaivaraha(new_element.pvm, new_element.lahto, new_element.paluupvm, new_element.paluu)
            }
        }

        new_element.verotonkorvausyhteensa = (new_element.verotonsumma || 0.0) - -(new_element.paivaraha || 0.0) - -(new_element.kmkorvaus || 0.0)
        new_element.verollinenkorvausyhteensa = (new_element.verotonkorvausyhteensa - new_element.verotonsumma - -new_element.verollinensumma) //    = new_element.verotonsumma - - new_element.paivaraha - -  new_element.verollinenkorvausyhteensa - new_element.verollinensumma * ((new_element.alvprosentti||0.0)/(1+(new_element.alvprosentti||0.0))  ||0.0 )


        const expenses = old_expenses.map(e => (e.uuid === uuid ? new_element : e)); // create new expenses by mapping the new element in place of the old one
        this.setState({expenses});
        if (new_element.pvm && new_element.pvm.slice(0, 7) === selectedMonth) {
            this.timer = setTimeout(() => {
                const start = +(new Date())
                updateExpense(expenses);
                this.refreshChange();
                console.log("updated: " + (new Date() - start));
            }, WAIT_INTERVAL);
        }
        clearTimeout(this.editTimers[uuid])
        this.editTimers[uuid] = setTimeout(() => this.sendEdit(new_element), EDIT_INTERVAL);


    };

    sendEdit = (new_element) => {
        const {selectedMonth, updateRow} = this.props
        updateRow({
            info: selectedMonth,
            className: "kulu",
            action: "upsert",
            data: new_element
        });
    }

    refreshChange = () => {
        const {selectedMonth, initGetMonthAggregated} = this.props;
        initGetMonthAggregated({month: selectedMonth});
    };

    addNewentries = () => {
        const expenses = [...this.state.expenses];
        const henkilo_uid = this.props.pseudo_uid || this.state.person.uid; // vai pseudo_uid

        const emptyRow = {
            images: [],
            pvm: date(),
            paluupvm: date(),
            tunnit: "",
            projekti_uid: "X100099",
            selite: "",
            laskutettava: "",
            verollinensumma: 0,
            alvprosentti: 0.10,
            km: 0,
            asiakasorganisaatio: "",
            asiakaskumppanit: "",
            lahto: "",
            paluu: "",
            paivaraha: 0,
            verotonsumma: 0,
            kmkorvaus: 0,
            maksutapa: "Itse maksettu",
            laskutettu: false,
            verollinenkorvausyhteensa: 0,
            verotonkorvausyhteensa: 0,
            henkilo_uid: henkilo_uid,
            uuid: uuid(),
            matkustajia: 0
        };
        expenses.unshift(emptyRow);
        this.setState({expenses});
        const {selectedMonth} = this.props;
        this.props.updateRow({
            info: selectedMonth,
            className: "kulu",
            action: "upsert",
            data: emptyRow
        });

        this.props.updateExpense(expenses);
    };

    duplicateExp = uuid2copy => {
        const old_expense = this.state.expenses.filter(
            a => a.uuid === uuid2copy
        )[0];
        const selite = old_expense.selite + " kopio"
        const new_expense = {
            ...old_expense,
            images: [],
            selite,
            uuid: uuid(),
            laskutettu: false,
            henkilo_laskuttanut: false
        };
        // sort => uusi päivä oikealle paikalleen
        const expenses = [new_expense, ...this.state.expenses];
        this.setState({expenses});
        this.props.updateRow({
            info: "kopioitu_kulu",
            className: "kulu",
            action: "upsert",
            data: new_expense
        });
        this.props.updateExpense(expenses);
    };

    deleteImg = uuid => {
        if (!window.confirm("Haluatko todella poistaa liitetiedoston?")) return;

        const {updateRow, updateExpense} = this.props;
        const old_expenses = this.state.expenses; // ref to old data; we're NOT going to mutate it
        const expenses = old_expenses.map(e => {
            if (!e.images.map(a => a.uuid).includes(uuid)) {
                return e
            } else {
                return {...e, images: e.images.filter(a => a.uuid !== uuid)}
            }
        }); // create new expence array by filtering out the one with the uuid-to-be-deleted

        updateRow({
            info: 'tuhoakuva',
            className: "images",
            action: "delete",
            data: {uuid}
        });
        this.setState({expenses})
        updateExpense(expenses);
    }
    deleteRow = uuid => {
        if (!window.confirm("Haluatko todella poistaa kulukirjauksen?")) return;
        const {selectedMonth, updateRow, updateExpense} = this.props;
        const old_expenses = this.state.expenses; // ref to old data; we're NOT going to mutate it
        const expenses = old_expenses.filter(e => e.uuid !== uuid); // create new expence array by filtering out the one with the uuid-to-be-deleted
        this.setState({expenses});
        //delete from db
        updateRow({
            info: selectedMonth,
            className: "kulu",
            action: "delete",
            data: {uuid}
        });
        updateExpense(expenses);
    };

    handleUploadedImg = info => {
        const expenses = [...this.state.expenses];
        const i = expenses.findIndex(e => e.uuid === info.ref_key);
        if (!expenses[i].images) expenses[i].images = [];
        expenses[i].images.unshift(info);
        this.setState(expenses);
        this.props.updateExpense(expenses);
    };

    editStateHandler = () => {
        this.setState({editing: !this.state.editing});
    };

    insertExpenses(es) {
        const expenses = [...this.state.expenses];
        this.props.updateExpense([...es, ...expenses]);
    }

    render() {
        const {editing, expenses} = this.state;
        const {dropDownData, lookupData, selectedMonth, person} = this.props;
        const sixmonthsmilliseconds = 15768000000
        const editable = (person && person.shortlisted) || (new Date() - (new Date(selectedMonth) || new Date())) < sixmonthsmilliseconds
        const shortlisted = person.shortlisted

        const elimit = (() => {
            var d = new Date() //nyt
            if (d.getDate() < 6) {
                d.setDate(-1)
                d.setDate(0)
            } else {
                d.setDate(0)
            }
            return date2isodate(d)
        })()


        return (
            <div>
                {editable &&
                <span className="hoursIcon" onClick={this.editStateHandler}>
              <Icon
                  color={editing ? "red" : "black"}
                  size="large"
                  name="edit outline"
                  title={!editing ? "Muokkaa" : "Lopeta muokkaus"}
              />
            </span>
                }

                {editing && (
                    <span className="addIcon" onClick={this.addNewentries}>
            <Icon name="add" size="large" title='lisää'/>
          </span>
                )}
                {editing && (
                    <CSVModal
                        fields={[
                            "pvm",
                            "projekti_uid",
                            "laskutettava",
                            "selite",
                            "verollinensumma",
                            "alvprosentti",
                            "km",
                            "asiakasorganisaatio",
                            "asiakaskumppanit",
                            "lahto",
                            "paluu",
                            "paivaraha",
                            "verotonsumma",
                            "kmkorvaus",
                            "maksutapa",
                            "laskutettu",
                            "verollinenkorvausyhteensa",
                            "verotonkorvausyhteensa"
                        ]}
                        parsedHours={hs => this.insertExpenses(hs)}
                    />
                )}
                {!expenses
                    ? null
                    :(<Fragment>
                          {expenses.map((el, i) => {
                        return (<ExpenseRowNew
                                key={el.uuid}
                                el={el}
                                editing={editing && (shortlisted || el.pvm > elimit)}
                                keyedChange={this.onChangeHandler}
                                dropDownData={dropDownData}
                                lookupData={lookupData}
                                updateImg={this.handleUploadedImg}
                                uploadImg={this.props.uploadImg}
                                deleteRow={this.deleteRow}
                                deleteImg={this.deleteImg}
                                duplicateExp={this.duplicateExp}
                                shortlisted={shortlisted}
                            />
                        );
                    })}
                        <div style={{height:'220px',position:'absolute'}}>&nbsp;</div>
                        </Fragment>
                    )
                }

            </div>
        );
    }
}

export default MonthlyExpenses;
