import React, { Component } from 'react'
import { Modal, Button } from "semantic-ui-react";
import { v4 as uid } from 'uuid';


export default class CSVModal extends Component{

    state ={
      fields: this.props.fields,
      showModal: false
    }
    onCsvInput = (e) => {   this.setState({ csvData: e.target.value })      }
    parseCSV(e){
        const {fields, parsedHours} = this.props
        const hours = []
        this.state.csvData.split(/\r\n|\r|\n/)
          .map( r => 
            r.split(/\t|;/))
          .map( vs => 
            fields.reduce( (o,x,i) => ({...o, [x]:vs[i] }), {uuid:uid()})
          ).forEach( i =>{if( i['tunnit'] > 0) hours.push(i)});
    
        parsedHours( hours )    
        this.setState({showModal:false, csvData:""})
    }
    
    render(){
        const {fields, showModal} = {...this.state, ...this.props}
        return (
          <Modal trigger= {<button title='liitä CSV'  className="csv" onClick={() => this.setState({ showModal: true })} >CSV</button>} open={showModal}>
          <Modal.Content>
            <h2>Paste your CSV</h2>

            <textarea 
              onChange={e=> this.onCsvInput(e)}
              onInput={e => this.onCsvInput(e)}
              value={this.state.csvData}
              className="csv-text-area"
              placeholder={fields.reduce((o,e,i) => o+"\t"+e)}
            />
            <div style={{margin:'10px', padding:'10px'}}>
            <Button  size='mini' floated='left' onClick={e => this.setState({showModal:false}) }>Cancel</Button>
            <Button  size='mini' floated='right' onClick={e => this.parseCSV(e)}>Parse</Button>
            </div>
          </Modal.Content>
        </Modal>
        )
    }
}

