import React, { Component } from 'react'
import { Tab } from "semantic-ui-react"
import Hours from './hours/MonthlyHours'
import Expenses from "./hours/MonthlyExpenses";
import {connect} from 'react-redux'
import * as allActions from '../../store/actions/actionCreators'





class HoursPane extends Component {
  panes = [
    {
      menuItem: "Tuntia",
      render: () => (
        <Tab.Pane attached={false} style={{ height: "100%" }}>
          <Hours
            pseudo_uid={this.props.pseudo_uid}
            hours={this.props.hours}
            updateRow={this.props.updateRow}
            updateHours={this.props.updateHours}
            selectedMonth={this.props.selectedMonth}
            person={this.props.person}
            dropDownData={this.props.dropDownData}
            lookupData={this.props.lookupData}
            initGetMonthAggregated={this.props.initGetMonthAggregated}
            progress={this.props.progress}
          />
        </Tab.Pane>
      )
    },
    {
      menuItem: "Kulut",
      render: () => (
        <Tab.Pane attached={false}>
          <Expenses
            pseudo_uid={this.props.pseudo_uid}
            expenses={this.props.expenses}
            updateRow={this.props.updateRow}
            updateExpense={this.props.updateExpense}
            selectedMonth={this.props.selectedMonth}
            uploadImg={this.props.uploadImg}
            person={this.props.person}
            dropDownData={this.props.dropDownData}
            initGetMonthAggregated={this.props.initGetMonthAggregated}
            progress={this.props.progress}
            lookupData={this.props.lookupData}
          />
        </Tab.Pane>
      )
    }
  ];
  render() {
    return (
      <div className="right">
        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={this.panes}
          style={{ height: "100%" }}
        />
      </div>
    );
  }
}
const mapStateToProps =state=>{
  return  {
    hours:state.loggedInuser.hours,
    expenses:state.loggedInuser.expenses,
    selectedMonth:state.loggedInuser.selectedMonth,
    person: state.loggedInuser.person,
    dropDownData: state.loggedInuser.dropdowndata,
    progress:state.loggedInuser.uploadProgress,
 //   lookupData: state.loggedInuser.lookupData
  }
}

export default  connect(mapStateToProps,allActions)(HoursPane)