import React , {Fragment} from "react";
import Dates from './Dates'
import DropDown from '../DropDown'
import TimeRangePicker from "./TimeRangePicker";
import ImgUploader from './ImgUploader'
import DisplayImgModal from "./DisplayImgModal";
import DuplicateExpense from './DuplicateExp'
import { Icon } from "semantic-ui-react";
import {fix2,fixnumber} from "../../../Helpers"


const name2visible = name =>{
    if(name === 'paivaraha') return 'Päiväraha'
    return name
}
const inputStyle = {
  width:'100%',
  textAlign: 'left',
  padding: '2px'
}
const textInput = (name,value, keyedChange, uuid,style=inputStyle)=>{
      return(
        <input style={style} placeholder={name2visible(name)} 
          type='text' name={name} value={value === 0 ||value === '' ? '' : value} onChange={e=>keyedChange(uuid,name,e.target.value)} /> 
)

}
const numberInput = (name,value, keyedChange, uuid)=>{
    return(
      <input style={inputStyle} placeholder={name2visible(name)} 
        type='text' pattern="[0-9 ]*[.,]?[0-9]*" name={name} value={value === 0 ||value === '' ? '' : value} onChange={e=>{if(e.target.validity.valid){return keyedChange(uuid,name,fixnumber(e.target.value))}} } /> 
    )
}

//TODO tee tästä class componentti joka perii PureComponentista, niin vältytään turhilta renderoinneilta
const ExpenseRowNew = ({el,duplicateExp,editing,keyedChange, dropDownData,updateImg, uploadImg ,progress ,deleteRow, deleteImg,lookupData, shortlisted })=>{
    if(!shortlisted) editing=false;
    if(!shortlisted && lookupData && lookupData["project_completed"][el.projekti_uid] != null ) editing = false
    if(!shortlisted && el.henkilo_laskuttanut ) editing = false;
    const retval = !editing ? (
        <div key={el.uuid} style={{marginBottom:'20px', marginLeft:'20px'}}>
          <div><span style={{width:'150px'}}> {el.pvm} </span><span style={{width:'150px'}} title={ lookupData && lookupData.projects[el.projekti_uid]} > {el.projekti_uid} </span> 
             <span style={{width:'300px'}}> {el.laskutettava} </span>
             <span style={{right:'45px', position:'absolute', left:'auto'}}>
             <span style={{width:'150px'}}>Yhteensä: {fix2(0.0 + (el.paivaraha||0.0) - -  (el.verollinensumma||0.0) - - (el.kmkorvaus||0.0))} € </span>
             <span style={{width:'150px'}}>({ fix2(0.0 - - (el.paivaraha||0.0) - - (el.verotonsumma||0.0) - -  (el.kmkorvaus||0.0) )} € veroton) </span>    
             </span>
          </div>
          <div>
        {el.selite && 
            <span style={{width:'300px',fontWeight:'700'}}> {el.selite || ""} </span>}
            {el.selite && el.asiakaskumppanit &&
              <span> / </span>
            }
        {(el.asiakaskumppanit ||el.asiakasorganisaatio) && 
            <Fragment><span style={{width:'150px',fontWeight:'700'}}> {el.asiakasorganisaatio || ""} </span><span style={{width:'150px',fontWeight:'700'}}> {el.asiakaskumppanit || ""} </span>
            </Fragment>}
            
        </div>            
        <div><span style={{width:'200px', textAlign:'right'}}> {(el.verollinensumma ||0.0)} € </span>
             <span style={{width:'100px'}}> ({ fix2(el.verollinensumma / ((el.alvprosentti ||0.0)+1)) }€ veroton) </span>
             { (el.km)? (<span>,  {(el.km ||0.0) } km </span>):""} 
        <span>
            { (el.paivaraha) ? <span id='praha'>
                { (el.km) ? ", " : ""}
                <span style={{width:'100px'}}> {el.lahto} </span> - <span style={{width:'100px'}}> {el.paluu} </span>
              </span>:""
            }
             
        
            {el.paivaraha ? <Fragment>
              <span>, Päiväraha: { (el.paivaraha)} € </span></Fragment>
                :<span></span>
            } 
              {el.maksutapa ? <Fragment><span>, Maksu: {el.maksutapa || ""} </span> </Fragment>
            :<span></span>}
          </span>
        </div>
        <div> {el.images[0] &&<DisplayImgModal el={el}  />  }            </div>
        </div>
        
    ) : (<div key={el.uuid} style={{ marginBottom: "20px", marginLeft: "20px", border: "1px lightGray solid", borderRadius: "5px", padding: "4px", marginTop: "5px" }}>
             <div style={{textAlign:'left'}}>
               <span className="expenseEditCell" style={{ width: "150px", display: "inline-block" }}>
                 {" "}
                 <Dates showcleardate={false} keyedChangeHandler={keyedChange} value={el.pvm} field="pvm" uuid={el.uuid} style={{ display: "inline-block" }} />{" "}
               </span>
               <span className="expenseEditCell" style={{ width: "250px", display: "inline-block" }}>
                 {" "}
                 <DropDown style={{ display: "inline-block" }} valuemap={dropDownData.active_projects} selected={el.projekti_uid} keyedChange={keyedChange} uuid={el.uuid} name="projekti_uid" placeholder="Projekti" />
               </span> <span className="expenseEditCell" style={{ width: "200px", display: "inline-block" }}>
                 {" "}
                 <DropDown valuemap={dropDownData.laskutettava} selected={el.laskutettava} keyedChange={keyedChange} uuid={el.uuid} name="laskutettava" placeholder="Laskutettava" />{" "}
               </span>
             </div>
             <div>
               <span className="expenseEditCell" style={{ width: "100%" }}>
                 {" "}
                 {textInput("selite", el.selite, keyedChange, el.uuid, {...inputStyle,fontWeight:700})}{" "}
               </span>
             </div>
             <div>
               <span className="expenseEditCell" style={{ width: "300px" }}>
                 {" "}
                 {textInput("asiakasorganisaatio", el.asiakasorganisaatio, keyedChange, el.uuid,{...inputStyle,fontWeight:700})}{" "}
               </span>
               <span className="expenseEditCell" style={{ width: "300px" }}>
                 {" "}
                 {textInput("asiakaskumppanit", el.asiakaskumppanit, keyedChange, el.uuid,{...inputStyle,fontWeight:700})}{" "}
               </span>
             </div>
             <div>
               <span className="expenseEditCell" style={{ width: "200px", textAlign: "right", display: "inline-block" }}>
                 {numberInput("verollinensumma", (el.verollinensumma||0.0), keyedChange, el.uuid)}
               </span> €<span className="expenseEditCell" style={{ width: "100px", textAlign: "right" }}>
                 {" "}
                 <select onChange={(e)=>{
                   keyedChange(el.uuid,'alvprosentti', e.target.value)
                 }} value={el.alvprosentti}><option value={0.0} >0 %</option><option value={0.10}>10 %</option><option value={0.14}>14 %</option><option value={0.24}>24 %</option></select>
                 {/*numberInput("alvprosentti", fix0((el.alvprosentti||0.0) * 100.0), (uuid, name, value) => keyedChange(uuid, name, value / 100.0), el.uuid)*/}
               </span> % ALV
               <span className="expenseEditCell" style={{ width: "100px", textAlign: "right" }}>
                 {" "}
                 <select onChange={(e)=>{
                   keyedChange(el.uuid,'tili', e.target.value)
                 }} value={el.tili}> {
                   Object.keys(dropDownData.tilit).map( k =>{
                     const v = dropDownData.tilit[k]
                     return <option value={k} key={k}> {v}</option>
                    } )
                }</select>
                 {/*numberInput("alvprosentti", fix0((el.alvprosentti||0.0) * 100.0), (uuid, name, value) => keyedChange(uuid, name, value / 100.0), el.uuid)*/}
               </span>
             </div>
             <div>
        <TimeRangePicker el={el} keyedChange={keyedChange} name='lahto' /> - <Dates keyedChangeHandler={keyedChange} value={el.paluupvm} field="paluupvm" uuid={el.uuid} style={{ display: "inline-block" }} />{" "}<TimeRangePicker el={el} keyedChange={keyedChange} name='paluu' />
             </div>

             <div>
               <span className="expenseEditCell" style={{ maxWidth: "60px", textAlign: "right" }}>
                 {numberInput("km", el.km, keyedChange, el.uuid)} 
               </span> km, lisämatkustajia:
               <span className="expenseEditCell" style={{ maxWidth: "60px", textAlign: "right" }}>
                 {numberInput("matkustajia", el.matkustajia, keyedChange, el.uuid)}  
               </span> 
               <span className="expenseEditCell" style={{ maxWidth: "80px", textAlign: "right" }}>
                {fix2(el.kmkorvaus)}
               </span> €<span className="expenseEditCell" style={{ width: "100px" }}>
                 {numberInput("paivaraha", el.paivaraha, keyedChange, el.uuid)}
               </span>
               <span className="expenseEditCell" style={{ width: "200px" }}>
                <DropDown valuemap={dropDownData.maksutapa} selected = {el.maksutapa} keyedChange={keyedChange} uuid={el.uuid} name="maksutapa" placeholder="Maksutapa" />
               </span>
             </div>
             <div>
               <span style={{width:'150px'}}>Yhteensä: { fix2(el.verollinenkorvausyhteensa)} € </span>
               <span style={{width:'150px'}}>({ fix2(el.verotonkorvausyhteensa)} € veroton) </span>
             </div>
              <div style={{ margin: "10px 0 0 15px" }}>
              {el.images[0] && <div style={{display:'inline'}}><DisplayImgModal el={el} deleteImg={deleteImg} style={{display:'inline-block'}} /></div>  } {" "}
              <ImgUploader el={el} progress={progress} ref_key={el.uuid} ref_table="kulu" updateImg={updateImg} uploadImg={uploadImg} />

               <DuplicateExpense uuid={el.uuid} click={e=>duplicateExp(el.uuid)} />
               <span style={{ cursor: "pointer", right:'10px',position:'absolute' }} onClick={e => {
                deleteRow(el.uuid);
              }}>
              <Icon color="red" name="close" title='poista' />
            </span>

             </div>
             
           </div>)
    return retval
}
export default ExpenseRowNew