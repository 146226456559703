import React from 'react'
import ProjektiTyoEnnuste from './ProjektiTyoEnnuste'
import ProjektiTyoToteuma from './ProjektiTyoToteuma'
const Projektityoaika = ({project,updateProjectData,updateRow, dropDownData, lookupData,project_uid, henkilo_uid,shortlisted}) =>{
    if(!project) return <span />;
    return(
        <div style={{marginLeft:'20px'}}>
            <h3>Ennuste</h3>
            <ProjektiTyoEnnuste henkilo_uid={henkilo_uid} lookupData={lookupData} dropDownData={dropDownData} project={project} updateProjectData={updateProjectData} updateRow={updateRow} project_uid={project_uid} shortlisted={shortlisted}/>
            
            <h3>Toteuma</h3>
            <ProjektiTyoToteuma henkilo_uid={henkilo_uid} lookupData={lookupData} project={project} updateProjectData={updateProjectData} updateRow={updateRow} />
        </div>
    )
}
export default Projektityoaika