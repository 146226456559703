import React, { Component } from "react";
import Asiakkaat from "../detailViews/projects/Asiakkaat";
import Tiedot from "../detailViews/projects/Tiedot";
import Laskutus from "../detailViews/projects/Laskutus";
import Projektityoaika from "../detailViews/projects/Projektityoaika";
import Tiedostot from "../detailViews/projects/Tiedostot"
import { connect } from "react-redux";
import { v4 as uid } from 'uuid';
import * as actions from "../../store/actions/actionCreators";
import {ewarnp,lwarnp} from  "../../Warnings.js"
import Referenssitiedot from '../detailViews/projects/Referenssitiedot'
import {fix2} from '../../Helpers'

const date = () => {
  const d = new Date().toISOString().slice(0, 7);

  return d;
};
//const WAIT_INTERVAL = 5000;

class Projects extends Component {
  state = {
    activeIndex: null,
    data: this.props.data,
   
  };
  timer = null;
  static getDerivedStateFromProps(props, state) {
    if(!props.shortlisted){
      state.editing=false;
    }
    if (!state.editing && props.data !== state.data) {
      return {
        data: props.data
      };
    }
    return null;
  }

  onchangeHandler = (e, info) => {
    // this onChange handler is for editing existing hours
    console.log("this should be obsolete!!")
    e.preventDefault();
    clearTimeout(this.timer);

    const name = e.target.name;

    const value = e.target.value;
    if (info.name === "tyoennuste") {
      
      const { data } = this.state;
      data.tyoennuste[info.index][name] = value;
      this.setState({ data });
      this.props.updateProjectData("tyoennuste", data.tyoennuste);
      this.props.updateRow({
        info: data.projekti_uid,
        className: "tyoennuste",
        action: "upsert",
        data: data.tyoennuste[info.index]
      });
       //this.timer = setTimeout(this.refreshChange, WAIT_INTERVAL);
    } else if (info.name === "tyototeuma") {
      
      const { data } = this.state;
      data.tyototeuma[info.index][name] = value;
      this.setState({ data });
      this.props.updateProjectData("tyototeuma", data.tyototeuma);
      this.props.updateRow({
        info: data.projekti_uid,
        className: "Tyototeuma",
        action: "upsert",
        data: data.tyoennuste[info.index]
      });
       //this.timer = setTimeout(this.refreshChange, WAIT_INTERVAL);
    }
   
  };
  refreshChange = () => {
   const { project_uid, initGetMonthAggregated } = this.props;
   console.log(project_uid)
   initGetMonthAggregated({ project_uid });
  };
  addNewentries = info => {
    let { data } = this.state;
    let emptyRow = {
      henkilo_uid: "",
      projekti_uid: data.uid,
      kuukausi: date(),
      tuntimaara: "",
      uuid: uid()
    };
    if (info === "tyototeuma") {
      data.tyototeuma.unshift(emptyRow);
      this.setState({ data });
      this.props.updateProjectData("tyototeuma", data.tyototeuma);
      this.props.updateRow({
        info: emptyRow.projekti_uid,
        className: "Tyototeuma",
        action: "upsert",
        data: emptyRow
      });
    } else if (info === "tyoennuste") {
      data.tyoennuste.unshift(emptyRow);
      this.setState({ data });
      this.props.updateProjectData("tyoennuste", data.tyoennuste);
      this.props.updateRow({
        info: emptyRow.projekti_uid,
        className: "Tyoennuste",
        action: "upsert",
        data: emptyRow
      });
    }
  };
  deletEmptyRow = info => {
    if( ! window.confirm("Haluatko varmasti poistaa?") ) return;
   
    let { data } = this.state;

    if (info.name === "tyototeuma") {
      data.tyototeuma.splice(info.i, 1);
      this.setState({ data });
      this.props.updateProjectData("tyototeuma", data.tyototeuma);
      this.props.updateRow({
        info: data.projekti_uid,
        className: "Tyototeuma",
        action: "delete",
        data: data.tyototeuma[info.i]
      });
    } else if (info.name === "tyoennuste") {
      data.tyoennuste.splice(info.i, 1);
      this.setState({ data });
      this.props.updateProjectData("tyoennuste", data.tyoennuste);
      this.props.updateRow({
        info: data.projekti_uid,
        className: "Tyoennuste",
        action: "delete",
        data: data.tyoennuste[info.i]
      });
    } else if (info.name === "asiakkaat") {
      data.asiakkaat.splice(info.i, 1);
      this.setState({ data });
      this.props.updateProjectData("asiakkaat", data.asiakkaat);
      this.props.updateRow({
        info: data.projekti_uid,
        className: "projektiasiakas",
        action: "delete",
        data: data.asiakkaat[info.i]
      });
    } else if (info.name === "laskutusennusteet") {
      data.laskutusennusteet.splice(info.i, 1);
      this.setState({ data });
      this.props.updateProjectData("laskutusennusteet", data.laskutusennusteet);
      this.props.updateRow({
        info: data.projekti_uid,
        className: "laskutusennuste",
        action: "delete",
        data: data.laskutusennusteet[info.i]
      });
    }
  };

  
  render() {
    const { data } = this.state;
    const {
      updateProjectData,
      updateRow,
      teeKysely,
      dropDownData,
      lookupData,
      project_uid,
      refreshProjects,
      henkilo_uid,
      shortlisted,
      copyProjectRef,
        jwt
    } = this.props;

    const personMap = lookupData.persons.reduce((a, c) => {
      a[c.uid] = c.nimi;
      return a;
    }, {});

    const {warne,warnl} = (()=>{
      const nowarn = {warne:"",warnl:""}
      if( !data || ! data.asiakkaat ) return nowarn
      return {
        warne: ewarnp(data) ,
        warnl: lwarnp(data)
      }
    })()

    const toggleLaskutettava = (kulu_uuid) => {
      let kulut = [...data.kulut]
      const old_kulu = kulut.find(a=>a.uuid === kulu_uuid)
      let kulu = {...old_kulu}
      if(!kulu) return; // bugi
      if( kulu.laskutettava === 'a' || kulu.laskutettava === 'Asiakaslaskutettava'){
        kulu.laskutettava = 'Ei-asiakaslaskutettava'
      }else{
        kulu.laskutettava = 'Asiakaslaskutettava'
      }
      kulut = kulut.map(k=> k.uuid === kulu.uuid ? kulu : k ) 
      const mod_data = {...data, kulut}
      this.setState({ data:mod_data });
      this.props.updateProjectData("kulut", kulut);
      this.props.updateRow({
        info: kulu_uuid,
        className: "kulu",
        action: "upsert",
        data: kulu
      })
    }
    const thstyle = {textAlign:'left',paddingLeft:'0px'}
    return (
      <div>

        <details open>
          <summary>
            <h3>TIEDOT</h3>
          </summary>
          <Tiedot
            lookupData={lookupData}
            project_uid={project_uid}
            dropDownData={dropDownData}
            project={data}
            updateProjectData={updateProjectData}
            updateRow={updateRow}
            henkilo_uid = {henkilo_uid}
            teeKysely={teeKysely}
            shortlisted={shortlisted}
          />
        </details>


        <details open>
          <summary>
            {" "}
            <h3> ASIAKKAAT</h3>
          </summary>{" "}
          <Asiakkaat
            henkilo_uid = {henkilo_uid}
            refreshProjects={refreshProjects}
            project_uid={project_uid}
            style={{ boxSizing: "border-box" }}
            lookupData={lookupData}
            dropDownData={dropDownData}
            project={data}
            updateProjectData={updateProjectData}
            updateRow={updateRow}
            deleteRow={this.deletEmptyRow}
          />
        </details>
        <details open>
          <summary>
            {" "}
            <h3>{warne} {warnl} LASKUTUS </h3>
          </summary>{" "}
          <Laskutus
            project={data}
            project_uid={project_uid}
            updateProjectData={updateProjectData}
            updateRow={updateRow}
            deleteRow={this.deletEmptyRow}
            henkilo_uid = {henkilo_uid}
            displayClientInvoiceModal={this.props.displayClientInvoiceModal}
            shortlisted={shortlisted}
            jwt={jwt}
          />
        </details>
        <details open>
          <summary>
            {" "}
            <h3>TYÖAJAN SEURANTA</h3>
          </summary>{" "}
          <Projektityoaika
            key={3}
            lookupData={lookupData}
            project_uid={project_uid}
            project={data}
            updateProjectData={updateProjectData}
            updateRow={updateRow}
            dropDownData={dropDownData}
            henkilo_uid = {henkilo_uid}
            shortlisted={shortlisted}

          />
        </details>
        <details open>
        <summary>
          <h3>TIEDOSTOT</h3>
        </summary>
          <h4>Tarjousdokumentit</h4>
        <Tiedostot
          project_uid={project_uid}
          uploadFile={this.props.uploadFile}
          project={data}
          updateRow={updateRow}
          henkilo_uid={henkilo_uid}
          tags={'#tarjous'}
          jwt={jwt}
        />
          <h4>Sopimus</h4>
          <Tiedostot
              project_uid={project_uid}
              uploadFile={this.props.uploadFile}
              project={data}
              updateRow={updateRow}
              henkilo_uid={henkilo_uid}
              tags={'#sopimus'}
              jwt={jwt}
          />
          <h4>Loppuraportit</h4>
          <Tiedostot
              project_uid={project_uid}
              uploadFile={this.props.uploadFile}
              project={data}
              updateRow={updateRow}
              henkilo_uid={henkilo_uid}
              tags={'#loppuraportti'}
              jwt={jwt}
          />
          <h4>Muut</h4>
          <Tiedostot
              project_uid={project_uid}
              uploadFile={this.props.uploadFile}
              project={data}
              updateRow={updateRow}
              henkilo_uid={henkilo_uid}
              tags={null}
              notags={'#tarjous#sopimus#loppuraportti'}
              jwt={jwt}
          />

      </details>
      { /*shortlisted &&*/
      <details open>
      <summary>
        <h3>REFERENSSITIEDOT</h3>
      </summary>
      <Referenssitiedot
        lookupData={lookupData}
        project_uid={project_uid}
        dropDownData={dropDownData}
        project={data}
        updateProjectData={updateProjectData}
        updateRow={updateRow}
        henkilo_uid = {henkilo_uid}
        copyProjectRef={copyProjectRef}
        jwt={jwt}
      />
    </details>}

        <details open={false}>
          <summary>
           
            <h3>TUNNIT SELITTEINEEN</h3>
          </summary>
          {data && (
            <table style={{marginLeft:'20px',borderCollapse:'collapse'}}>
              <thead>
                <tr style={{borderTop:'1px solid gray', borderBottom:'1px solid gray'}}>
                  <th style={thstyle}>Päivä</th>
                  <th style={thstyle}>Henkilö</th>
                  <th style={thstyle}>Selite</th>
                  <th style={thstyle}>Tunnit</th>
                </tr>
              </thead>
              <tbody>
                {data.tunnit.map(t => (
                  <tr key={t.uuid}>
                    <td>{t.pvm}</td>
                    <td title={personMap[t.henkilo_uid]}>{t.henkilo_uid}</td>
                    <td style={{ maxWidth: "300px" }}>{t.selite}</td>
                    <td className="numeric">{t.tunnit.toFixed(2)}</td>
                  </tr>
                ))}
                { data.tunnit &&
                  <tr style={{borderTop:'1px solid gray', borderBottom:'1px solid gray'}} key={'yhteensa'}>
                  <td colSpan='3'> Yhteensä </td>
                  <td className="numeric"> { fix2( data.tunnit.reduce((a,c)=>a+c.tunnit, 0))} </td>
                  </tr>
                }
                
              
              </tbody>
            </table>
          )}
        </details>
        <details open={false}>
          <summary>
           
            <h3>KULUT SELITTEINEEN</h3>
          </summary>
          {data && (
            <table style={{marginLeft:'20px', borderCollapse:'collapse'}}>
              <thead>
                <tr style={{borderTop:'1px solid gray', borderBottom:'1px solid gray'}}>
                  <th>Päivä</th>
                  <th>Henkilö</th>
                  <th>Selite</th>
                  <th>Summa</th>
                  <th>A/B</th>
                  <th>Laskutettu</th>
                </tr>
              </thead>
              <tbody>
                {data.kulut.map(t => (
                  <tr key={t.uuid} style={{marginBottom:'4px'}}>
                    <td style={{minWidth:'10em',verticalAlign:'top'}}>{t.pvm}</td>
                    <td title={personMap[t.henkilo_uid]}  style={{minWidth:'10em',verticalAlign:'top'}} >{t.henkilo_uid}</td>
                    <td style={{ maxWidth: "300px" , verticalAlign:'top', paddingBottom:'10px'}}>{t.selite}<br />{t.asiakasorganisaatio}</td>
                    <td className="numeric" style={{ verticalAlign:'top'}} >
                      {t.verotonkorvausyhteensa.toFixed(2)}
                    </td>
                    <td style={{verticalAlign:'top', textAlign:'center',cursor:'pointer'}} title='Tuplaklikkaa vaihtaaksesi' onDoubleClick={e=>toggleLaskutettava(t.uuid)}> {t.laskutettava === 'Asiakaslaskutettava' || t.laskutettava ==='a'  ? 'A' : 'B'}</td>
                    <td style={{verticalAlign:'top', textAlign:'center'}}> {t.laskutettu ? '•' : ''}</td>
                  </tr>
                ))}
                { data.kulut &&
                  <tr style={{borderTop:'1px solid gray', borderBottom:'1px solid gray'}} key={'yhteensa'}>
                  <td colSpan='3'> Yhteensä </td>
                  <td className="numeric"> { fix2( data.kulut.reduce((a,c)=>a+c.verotonkorvausyhteensa, 0))} </td>
                  <td colSpan='2'> &nbsp; </td>
                  </tr>
                }

              </tbody>
            </table>
          )}
        </details>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.loggedInuser.currentProject,
    project_uid:state.loggedInuser.project_uid
  };
};
export default connect(
  mapStateToProps,
  actions
)(Projects);
