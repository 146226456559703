import React from "react";
import lwarnpng from './assets/Lwarn.png'
import ewarnpng from './assets/Ewarn.png'
import rwarnpng from './assets/rwarn.png'
import refwarnpng from './assets/rwarn.png'
import cwarnpng from './assets/cwarn.png'
import dwarnpng from './assets/dwarn.png'



const istyle = {width:'16px', height:'16px', margin:'2px 2px -1px 0 '}
const ewarnimg = erotus=> (<img key={erotus} alt="" src={ewarnpng} style={istyle} title={`Ennusteet ja myynti eivät täsmää (ero ${erotus} €). Korjaa ennusteita siten, että kaikki myynti ennustetaan laskutettavaksi.`} />)
const lwarnimg = erotus=> (<img key={erotus} alt="" src={lwarnpng} style={istyle} title={`Laskutus ei vastaa ennusteita  (ero ${erotus} €). Laskuta tai korjaa ennusteita vastaamaan nykytilaa.`} />)
const cwarnimg = viesti=> (<img key={viesti} alt="" src={cwarnpng} style={istyle} title={viesti} />)
const dwarnimg = viesti=> (<img key={viesti} alt="" src={dwarnpng} style={istyle} title={viesti} />)
export const refwarnimg = <img  key="ref" alt="" src={refwarnpng} style={istyle} title={`Referenssitieto puuttuu`} />

export const rwarn = (title) => (<img  key={title} alt="" src={rwarnpng} style={istyle} title={title} />)

const ewarn = (myynti,kokoennuste,paattynyt=false)=>{
    if( !paattynyt && Math.abs(kokoennuste-myynti) > 1000.0) return ewarnimg(kokoennuste-myynti)
    else return ""
}
export const lwarn = (ennustepernyt, laskutus, paattynyt=false)=>{
  //  return ""; // käännetty toistaiseksi pois käytöstä 2018-12-22
    if( !paattynyt && ennustepernyt - laskutus > 1000) return lwarnimg(ennustepernyt-laskutus)
    else return ""
}
export const ewarnp = (ps)=>{
    if(!ps || !ps.asiakkaat || !ps.laskutusennusteet || ps.paattynyt || !ps.tyyppi==='A') return "";
    const myynti = ps.asiakkaat.reduce((a,c)=>a+c.laskutus, 0)
    const kokoennuste = ps.laskutusennusteet.reduce( (a,c)=>(a+c.tyosumma), 0 )
    return ewarn(myynti,kokoennuste, ps.paattynyt)
}
export const lwarnp = (ps)=>{
    if(!ps || !ps.laskut || !ps.laskutusennusteet || ps.paattynyt || !ps.tyyppi==='A') return "";
    const nyt = new Date()
    const ennustepernyt = ps.laskutusennusteet.reduce( (a,c)=>( new Date(c.pvm) <= nyt ? a+c.tyosumma : a), 0 )
    const laskutus      = ps.laskut.reduce( (a,c)=>(a+c.tyosumma), 0 )
    return lwarn(ennustepernyt,laskutus, ps.paattynyt)
}


export const cwarn = (ps)=>{
    let warnings = []
    //paivahinta
    if( (!ps.poikkeava_paivahinta) && !ps.paattynyt && ps.uid[0]==='A' &&  (ps.paivahinta < 800.0 || ps.paivahinta > 1500.0) ){
        warnings.push( dwarnimg("Poikkeukselinen päivähinta") )}
    // roikkuva?
    if( !ps.paattynyt && ps.ennustetut_paivat < 1 && ps.uid[0]==='A'){
        warnings.push( cwarnimg("Ei ennustettua työtä – tulisiko päättää?") )
    }
    return warnings
}

export const has_warnings = (ps)=>{
    return ps.uid[0] !== 'X'
        && ps.tuotevaiprojekti==='projekti'
        &&(
            ewarnp(ps) !== ""
        || lwarnp(ps) !== ""
        || cwarn(ps).length > 0
        )
}