import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
//import registerServiceWorker from "./registerServiceWorker";
import storeConfig from "./store/storeCofig";
import {Provider} from "react-redux";
import "./semantic.min.css";
import {BrowserRouter as Router} from "react-router-dom";
import {unregister} from './registerServiceWorker';
import {PublicClientApplication} from "@azure/msal-browser";
import {MsalProvider} from "@azure/msal-react";
import {initializeData} from "./store/sagas/dashBoardSaga";
import { createRoot } from 'react-dom/client';

// Configuration object constructed.

const config = {
    auth: {
        clientId: 'eecd838c-3cf3-49ce-83f5-4bbe6cc4d496',
        authority: 'https://login.microsoftonline.com/aad52afe-e8c8-4099-989c-fdf985e09472',
        scopes:['eecd838c-3cf3-49ce-83f5-4bbe6cc4d496']
    }
};

// create PublicClientApplication instance
const publicClientApplication = new PublicClientApplication(config);

const store = storeConfig();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <MsalProvider instance={publicClientApplication}>
        <Provider store={store}>
            <Router>
                <App/>
            </Router>
        </Provider>
    </MsalProvider>
);
//registerServiceWorker();
unregister();