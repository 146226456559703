export const bearer_url = jwt=>{
  if( 'production' === process.env.NODE_ENV){
    return `bearer://${jwt}@/NHG/`
  }else{
    return `bearer://${jwt}@localhost:8080/`
//    return "http://127.0.0.1:9999/";
  }
}


const back_end_url = (()=>{
  console.log(process.env.NODE_ENV)
  if( 'production' === process.env.NODE_ENV){
//    return (window.location.protocol)+"//"+(window.location.host)+"/NHG/";
    return "/NHG/";
  }else{
    return "http://localhost:8080/";
//    return "http://127.0.0.1:9999/";
  }
})() 

export default back_end_url
