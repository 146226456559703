import React, {useEffect} from "react";
import {connect, useDispatch,useSelector} from "react-redux";
import { useMsal, useAccount } from "@azure/msal-react";
import {createUserUuid, initData,storeToken} from "../../store/actions/actionCreators";

export default function MsalWrap(props){
    const dispatch = useDispatch()
    const { instance, accounts, inProgress } = useMsal();
    let account = accounts.find(a => a.username.includes("nhg.fi") || a.username.includes("kuntamaisema.fi") )

    useEffect(() => {
        if (account) {
            instance.acquireTokenSilent({
                scopes: ['eecd838c-3cf3-49ce-83f5-4bbe6cc4d496/.default'],
                account: account
            }).then((t) => {
                if(t) {
                    dispatch(initData({account, jwt:t.accessToken}));
                }
            });
        }
    }, [account, instance]);
    return <span>Odotetaan kirjautumistietoa</span>
}

export function MsalRefresh(props){
    const dispatch = useDispatch()
    const { instance, accounts, inProgress } = useMsal();
    let account = accounts.find(a => a.username.includes("nhg.fi") || a.username.includes("kuntamaisema.fi") )

    useEffect(() => {
        if (account) {
            instance.acquireTokenSilent({
                scopes: ['eecd838c-3cf3-49ce-83f5-4bbe6cc4d496/.default'],
                account: account
            }).then((t) => {
                if(t) {
                    dispatch(storeToken({jwt:t.accessToken}));
                }
            });
        }
    }, [account, instance]);

    return <React.Fragment />
}
