import React, { Fragment } from "react";
import IikkaLogo from '../IikkaLogo'
import DashBoard from "../components/layout/DashBoard";
import * as actionType from "../store/actions/actionTypes";
import {initializeData} from "../store/sagas/dashBoardSaga";
//import {useMsal } from "@azure/msal-react";



export default function Main(){
//    var uids = useMsal()

    return (
        <Fragment>
          <DashBoard/>
          <IikkaLogo />
        </Fragment>)
}
