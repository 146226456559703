import pdficon from './assets/pdficon.png'
import docicon from './assets/docicon.png'
import xlsicon from './assets/xlsicon.png'
import genicon from './assets/genicon.png'

export const fileIcon = name => {
        if(!name) return genicon;
        const last = name.slice(-3)
        if( last === 'pdf') return pdficon
        if( last === 'doc' ||
            last === 'ocx') return docicon;
        if( last === 'xls' ||
            last === 'lsx') return xlsicon;
        return genicon; 
    }
    
