import React from "react";
import majakka from './assets/majakka-logo_390.png'

const IikkaLogo = ()=>{
    return( 
        <div style={{position:'absolute', left:'30px', bottom:'30px', color:'#ffffffaa'}}>
            <img style={{width:'195px',height:'110px', opacity:'0.8' }} src={majakka} alt="Majakka" />
{/*}            <div style={{display:'inline-block', marginRight:'20px'}}>
                <div style={{fontFamily:'Questrial,Arial,Helvetica,sans-serif', fontWeight:'bold', fontSize:'38px'}}>Kanta</div> 
            </div>

            <div style={{display:'inline-block'}}>
                <div style={{fontSize:'8pt', fontFamily:'Questrial,Arial,Helvetica,sans-serif', letterSpacing:'1.4px'}}>powered by:</div>
                <div style={{fontFamily:'Questrial,Arial,Helvetica,sans-serif', fontWeight:'bold'}}> Iikka-Tuote</div> 
    </div> */}
        </div>
    )
}
export default IikkaLogo