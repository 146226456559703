import React, { Component } from 'react';
import { Icon } from "semantic-ui-react";
//import Auth from "../components/layout/Auth";
import backEnd from '../BackEnd'
import { Slider, Handles, Tracks } from 'react-compound-slider'
import {fix0} from "../Helpers"
import DropDown from './detailViews/DropDown';
import {TokenView} from './TokenView'
import {refwarnimg} from '../Warnings'


//import { Checkbox,Button } from "semantic-ui-react";
//import {fix2} from '../Helpers'
//import Dropdown from './detailViews/DropDown'
//import uuidgen from "uuid/v4";
//import * as actions from "../store/actions/actionCreators";

const WAIT_INTERVAL = 800;
/* TODO lisää tooltippiin cvref => täytyy hakea projectsummaryyn*/

const ProjectSummaryRow = ({project,deactivated, clickProject})=>{
    const cn = deactivated ? 'deactivatedMonthRow' : 'normalMonthRow'
    return (
        <div
         className = {cn}
         onClick = {(e)=>{return clickProject(e,project,deactivated)}}
        > 
        <div className='projectHeader' title ='' >{project.uid} {project.nimi}</div>
        <span className="projectDetail"> 
        {project.laajuus > 0 &&
            <span title='Laskutus / Laajuus'>{Math.floor(100*(project.laskutustoteuma || 0)/project.laajuus)} % / {fix0(project.laajuus)} €</span>
        }
        {/*{project.laskutustoteuma || 0} / {project.laajuus ||0} € */}
            {/*
            {project.liiketoimintaAlue ? <span title='Liiketoiminta-alue'> {project.liiketoimintaAlue} </span> : <span/> }
        {project.liiketoimintaAlue2 ? <span title='Toissijainen liiketoiminta-alue'> {project.liiketoimintaAlue2} </span> : <span/> }
        */}
        {project.tarjousHyvaksytty  ? <span title='Tarjous hyväksytty'> { new Date(project.tarjousHyvaksytty).toISOString().slice(0,10) } </span> : <span />}
        {project.paattynyt  ? <span title='Päättynyt'> – { new Date(project.paattynyt).toISOString().slice(0,10) } </span> : <span />}
        {!project.reftietue && refwarnimg}
        </span>
        </div>
    )
}




export function Handle({ // your handle component
    handle: { id, value, percent }, 
    getHandleProps
  }) {
    return (
      <div
        style={{
          left: `${percent}%`,
          position: 'absolute',
          marginLeft: -15,
          marginTop: 25,
          zIndex: 2,
          width: 30,
          height: 30,
          border: 0,
          textAlign: 'center',
          cursor: 'pointer',
          borderRadius: '50%',
          backgroundColor: '#2C4870',
          color: '#333',
        }}
        {...getHandleProps(id)}
      >
        <div style={{ fontFamily: 'Roboto', fontSize: 11, marginTop: -35 }}>
          {fix0(value)}
        </div>
      </div>
    )
  }

  function Track({ source, target, getTrackProps }) { // your own track component
    return (
      <div
        style={{
          position: 'absolute',
          height: 10,
          zIndex: 1,
          marginTop: 35,
          backgroundColor: '#546C91',
          borderRadius: 5,
          cursor: 'pointer',
          left: `${source.percent}%`,
          width: `${target.percent - source.percent}%`,
        }}
        {...getTrackProps()} // this will set up events if you want it to be clickeable (optional)
      />
    )
  }
  

export class RefSearch extends Component {
    state={
        ...this.props,
        'vapaateksti': '',
        'vapaarefteksti': '',
        'pp': null,
        'laajuusEUR': ["0","1e9"],
        'laajuusHTP': [0,200],
        'aika':[0,3],
        'liiketoimintaalue':null,
        'avainsanat':[],
        'deactivated':[],
        vaikuttavuusperusteinen:null,
        kansainvalinen:null,
        asiakashaku: ''
    }
    
    
    componentDidMount(){
        this.setState({
            ...this.props,
            ...this.state
        })
    }

    keyedRefChange = (kname,value)=>{
        console.log(kname)
    }
    addKeyword = (word)=>{
        const avainsanat = [...new Set([...this.state.avainsanat, word])]
        this.setState({avainsanat})
        this.searchHandler()
    }

    downloadpptref = (e)=>{
      const {projects,jwt} = this.props
      const {deactivated} =this.state
      const projstring = projects.filter(p=>(!deactivated.includes(p.uuid))).map(a=>a.uid).join(',')
      const uriprojstr = encodeURIComponent(projstring)
      const url =`${backEnd}refpptx?jwt=${jwt}&project_uids=${uriprojstr}`
      const link = document.getElementById('refpptdownloadlink')
      link.href = url
      link.click()
    }

    downloadref = (e)=>{
        const {projects,jwt} = this.props
        const {deactivated} =this.state
        const projstring = projects.filter(p=>(!deactivated.includes(p.uuid))).map(a=>a.uid).join(',')
        const uriprojstr = encodeURIComponent(projstring)
        const url =`${backEnd}refdocx?jwt=${jwt}&project_uids=${uriprojstr}`
        const link = document.getElementById('refdownloadlink')
        link.href = url
        link.click()
      }

      timer = null;

     dropdownHandler = (label, e,s) =>{
       const new_state = {[label]: s.value} 
        this.setState(new_state)
        this.searchHandler(e)
     }
     searchHandler = (e) => {
        e && e.preventDefault();
        clearTimeout(this.timer);
        this.timer = setTimeout(this.refreshChange, WAIT_INTERVAL);
        // set state to match values
        this.setState({
          'vapaateksti': this.refs['vapaateksti'].value,
          'vapaarefteksti': this.refs['vapaarefteksti'].value,
          'asiakashaku': this.refs['asiakashaku'].value
        })
      }
    
    array2range = (vs)=>{
      return vs[0] + "-" + vs[1]
    }


    refreshChange = () => {
        // kerätään search-string
        let searchValue = this.state.vapaateksti + " " 
        searchValue += this.state.vapaarefteksti !== '' ? ('#*:'+this.state.vapaarefteksti +" ") : ''      
        searchValue += '#laajuusEUR:' + this.array2range(this.state.laajuusEUR) + " "
        searchValue += '#laajuusHTP:' + this.array2range(this.state.laajuusHTP) + " "
        searchValue += '#aika:' + this.array2range(this.state.aika) + " "
        if(this.state.pp) searchValue += '#pp:' + this.state.pp + ' '
        if(this.state.liiketoimintaalue) searchValue += '#liiketoimintaalue:'+this.state.liiketoimintaalue + ' '
        if(this.state.asiakashaku && this.state.asiakashaku !== '') searchValue += '#asiakashaku:' + this.state.asiakashaku + ' '
        if(this.state.vaikuttavuusperusteinen != null) searchValue += '#vaikuttavuusperusteinen:' + this.state.asiakashaku + ' ' 
        if(this.state.kansainvalinen != null) searchValue += '#kansainvalinen:' + this.state.kansainvalinen + ' ' 

        searchValue += this.state.avainsanat.map( a=>`#${a}` ).join(" ")


        // hae tässä klikatut arvot
        //searchValue += this.refs['avainsanat'].value != '' ? ( this.refs['avainsanat'].value ) :''
        this.props.searchPerson(searchValue)
    }    

    sliderChanged = (label,values) =>{

        this.setState({
          [label]: values
        })

        clearTimeout(this.timer);
        this.timer = setTimeout(this.refreshChange, WAIT_INTERVAL);

    }
    poistaAvainsana = (sana)=>{
      const avainsanat = this.state.avainsanat.filter( (i)=>(sana!==i) )
      this.setState({avainsanat})
      this.searchHandler()

    }
    clickProject = (e,project,deactive)=>{
      e.preventDefault()
      const deactivated = deactive ?
          this.state.deactivated.filter(u=>project.uuid!==u)
        : [...this.state.deactivated, project.uuid]
      this.setState({deactivated})
    }
    kvclick = (e,s)=>{
      e.preventDefault()
      if(s.value == null){
        this.setState({'kansainvalinen':null})
      }else{
        const kansainvalinen = s.value === 'Kansainvälinen' 
        this.setState({kansainvalinen})
      }       
    }
    vpclick = (e,s)=>{
      e.preventDefault()
      if(s.value == null){
        this.setState({'vaikuttavuusperusteinen':null})  
      }else{
        const vaikuttavuusperusteinen = s.value === 'Vaikuttavuusperusteinen'
        this.setState({vaikuttavuusperusteinen})  
      }
    }

    alarajaChanged = (e)=>{
      const limits = this.state.laajuusEUR
      const alaraja = e.currentTarget.value
      this.setState({'laajuusEUR': [alaraja, limits[1] ]})
      this.searchHandler(e)
    }
    ylarajaChanged = (e)=>{
      const limits = this.state.laajuusEUR
      const ylaraja = e.currentTarget.value
      this.setState({'laajuusEUR': [limits[0], ylaraja ]})
      this.searchHandler(e)
    }
    render() {
        const {projects, dropDownData} = this.props
        const h4style = {align:'left',marginBottom:'5px',marginTop:'5px'}
        const tstyle = {verticalAlign:'text-top', textAlign:'left'}
        const {laajuusEUR}  = this.state

/*        const sliderStyle = {  // Give the slider some width
            position: 'relative',
            width: '100%',
            height: 80,
            border: '1px solid steelblue',
          }*/
          
          const railStyle = { 
            position: 'absolute',
            width: '100%',
            height: 10,
            marginTop: 35,
            borderRadius: 5,
            backgroundColor: '#8B9CB6',
          }
          
        const dropdowns = (()=>{
          if(!this.props.dropDownData) return {}
          let ds = {}
          let last = ""
          this.props.dropDownData.ref_avainsanat.map(a=>{
            const m = a.match(/^==(.*)$/)
             if( m ){
              last = m[1]
              ds[last] = []               
              }else{
                ds[last].push(a)
              }
              return null
            })
          return ds
        })()

        const kv = (v)=>{
          if(null == v) return null;
          return v ? 'Kansainvälinen':'Ei-kansainvälinen'
        }
        const vp = v=>{
          if(null == v) return null;
          return v ? 'Vaikuttavuusperusteinen':'Ei-vaikuttavuusperusteinen'
        }
                    
        return <div className="backdrop" >
            <div style={{display:'block', textAlign:'center', marginLeft:'auto', marginRight:'auto'}}>
            <div className="ref-modal">
            <Icon color="grey" name="close" title='Sulje' onClick={this.props.cancel} style={{position:'absolute', right:'10px', top:'10px'}}/>
            <div style={{display:'inline-block', marginLeft:'auto',marginRight:'auto', overflowY:'auto', top:'10px',bottomm:'10px'}}>
              <h2>Projektihaku</h2>
              <div style={{display:'inline-grid', gridTemplateColumns:'500px auto', width:'90vw'}}>
              <div>
              <table style={{display:'inline-block', maxWidth:'500px'}}><tbody>
              <tr>
                <td style={tstyle}><h4 style={{...h4style, display:'inline-block'}}>Vapaa teksti: </h4>&nbsp;</td>
                <td style={tstyle}><input ref='vapaateksti' type="text" placeholder="" style={{width:'340px'}} onChange={this.searchHandler}  value={this.state.vapaateksti} /> 
                </td>
              </tr>
              <tr>
                    <td  style={tstyle}><h4 style={{...h4style, display:'inline-block'}}>Referenssit:</h4></td>
                    <td style={tstyle}> <input style={{width:'340px'}}type="text" placeholder='' ref='vapaarefteksti' onChange={this.searchHandler} value={this.state.vapaarefteksti}></input></td>
              </tr>
              
              <tr>
                    <td  style={tstyle}><h4 style={{...h4style, display:'inline-block'}}>Asiakas:</h4></td>
                    <td style={tstyle}> <input style={{width:'340px'}} type="text" placeholder='' ref='asiakashaku' onChange={this.searchHandler} value={this.state.asiakashaku}></input></td>
              </tr>
 <tr>
                    <td style={tstyle} colSpan={2}>                       
                    <DropDown ref='pp' onChange={(e,s)=>{ this.dropdownHandler('pp', e, s) } } 
                        style={{ display: "inline-block" }} selected={this.state.pp} valuemap={dropDownData.pps} name="pp" placeholder="Projektipäällikkö:"/> &nbsp; 
                      <DropDown ref='liiketoimintaalue' onChange={ (e,s)=>{this.dropdownHandler('liiketoimintaalue', e, s) }} style={{ display: "inline-block" }} selected={this.state.liiketoimintaalue} valuemap={dropDownData.liiketoimintaalue} name="liiketoimintaalue" placeholder="Liiketoiminta-alue:"/> 
                    </td>
              </tr>
              <tr>
                    <td style={tstyle} colSpan={2}>                       
                    
                      <DropDown ref='kv' onChange={this.kvclick } selected={kv(this.state.kansainvalinen)} valuemap={['Kansainvälinen', 'Ei-kansainvälinen']} name="pp" placeholder="Kansainvälisyys"/> &nbsp; 
                      <DropDown ref='vp' onChange={this.vpclick } selected={vp(this.state.vaikuttavuusperusteinen)}valuemap={['Vaikuttavuusperusteinen', 'Ei-vaikuttavuusperusteinen']} name="pp" placeholder="Vaikuttavuusperusteisuus"/> &nbsp; 
                    </td>
              </tr>              
              <tr>
                    <td  style={{...tstyle, verticalAlign:null}}><h4 style={{...h4style, display:'inline-block'}}> Laajuus (t€):</h4></td>
                    <td style={{...tstyle,height:'50px', verticalAlign:'bottom'}}> 
                    <div style={{width:'340px',position:'relative', margin:'10px', height:'20px'}}>
                    {/* tähän kaksi pudotusvalikkoa, joissa on laajuus alaraja yläraja */}
                    <select name="alaraja" onChange={this.alarajaChanged} value={laajuusEUR[0]}>
                      <option value="0" >0</option>
                      <option value="10e3">10 k€</option>
                      <option value="20e3">20 k€</option>
                      <option value="30e3">30 k€</option>
                      <option value="40e3">40 k€</option>
                      <option value="50e3">50 k€</option>
                      <option value="75e3">75 k€</option>
                      <option value="100e3">100 k€</option>
                      <option value="125e3">125 k€</option>
                      <option value="150e3">150 k€</option>
                      <option value="200e3">200 k€</option>
                      <option value="250e3">250 k€</option>
                      <option value="1e9">max</option>
                    </select> – <select name="yläraja" onChange={this.ylarajaChanged} value = {laajuusEUR[1]}>
                    <option value="0">0</option>
                    <option value="10e3">10 k€</option>
                    <option value="20e3">20 k€</option>
                    <option value="30e3">30 k€</option>
                    <option value="40e3">40 k€</option>
                    <option value="50e3">50 k€</option>
                    <option value="75e3">75 k€</option>
                    <option value="100e3">100 k€</option>
                    <option value="125e3">125 k€</option>
                    <option value="150e3">150 k€</option>
                    <option value="200e3">200 k€</option>
                    <option value="250e3">250 k€</option>
                    <option value="1e9">max</option>                  
                    </select>
                    

                  </div>
                  </td>
              </tr>
              
              <tr>
                    <td  style={{...tstyle, verticalAlign:null}}><h4 style={{...h4style, display:'inline-block', marginTop:'40px'}}> Laajuus (htp):</h4></td>
                    <td style={{...tstyle,height:'50px'}}> 
                    <div style={{width:'330px',position:'relative', margin:'10px', height:'50px'}}>
                    <Slider domain={[0, 1000]} values={this.state.laajuusHTP} onChange={(vs)=>this.sliderChanged('laajuusHTP', vs)}>
                      <div style={railStyle} /> 
                      <Handles>
                            {({ handles, getHandleProps }) => (
                                <div className="slider-handles">
                                {handles.map(handle => (
                                    <Handle
                                        key={handle.id}
                                        handle={handle}
                                        getHandleProps={getHandleProps}
                                    />
                                ))}
                                </div>
                            )}
                        </Handles>
                        <Tracks right={false} left={false}>
                          {({ tracks, getTrackProps }) => (
                          <div className="slider-tracks">
                            {tracks.map(({ id, source, target }) => (
                            <Track key={id} source={source} target={target} getTrackProps={getTrackProps}
                            />
                            ))}
                            </div>
                          )}
                        </Tracks>
                  </Slider>
                  </div>
                  </td>
              </tr>
              <tr>
              <td  style={{...tstyle, verticalAlign:null}}><h4 style={{...h4style, display:'inline-block', marginTop:'40px'}}> Aikaväli (vuotta sitten):</h4></td>
              <td style={{...tstyle,height:'50px'}}> 
              <div style={{width:'330px',position:'relative', margin:'10px', height:'50px'}}>
              <Slider domain={[0, 10]} values={this.state.aika} onChange={(vs)=>this.sliderChanged('aika', vs)}>
                <div style={railStyle} /> 
                <Handles>
                      {({ handles, getHandleProps }) => (
                          <div className="slider-handles">
                          {handles.map(handle => (
                              <Handle
                                  key={handle.id}
                                  handle={handle}
                                  getHandleProps={getHandleProps}
                              />
                          ))}
                          </div>
                      )}
                  </Handles>
                  <Tracks right={false} left={false}>
                    {({ tracks, getTrackProps }) => (
                    <div className="slider-tracks">
                      {tracks.map(({ id, source, target }) => (
                      <Track key={id} source={source} target={target} getTrackProps={getTrackProps}
                      />
                      ))}
                      </div>
                    )}
                  </Tracks>
            </Slider>
            </div>
            </td>
            </tr>
              <tr>
                <td style={{...tstyle, verticalAlign:'top'}}><h4 style={{...h4style, display:'inline-block'}}>Avainsanat: </h4>&nbsp;</td>
                <td style={{...tstyle,maxWidth:'340px'}}>
                        <TokenView items={this.state.avainsanat} onKill={this.poistaAvainsana} />
                        
                </td>
              </tr>
              <tr>
                        <td style={tstyle} colSpan={2}>
                        <div style={{ marginTop:'10px', textAlign:'right', marginRight:'10px'}}>
                        {Object.keys(dropdowns).map((k)=>{
                          return <select key={k} onChange={e=>{this.addKeyword(e.target.value)}} 
                          style={{width:'190px', maxWidth:'190px', margin:'2px'}} value={k}>
                            <option key={`${k}_otsake`} value={k} disabled="disabled">{k}</option>                             
                            {dropdowns[k].map((i)=>(<option key={i}>{i}</option>))}
                          </select>
                        })}
                        </div>     
                        </td>
              </tr>

               <tr>
                            <td colSpan={2} style={{...tstyle, maxWidth:'500px'}}>
                            <div style={{width:'490px', position:'relative'}}>
                            <span style={{position:'absolute',left:'5px'}} >
                            {projects.length} projektia. ({projects.filter(p=>p.reftietue).length} referenssiä)
                            </span>
                            &nbsp;
                            <span style={{position:'absolute',right:'5px'}} >
                              <button onClick={this.downloadref}> lataa referenssit </button>
                              <button onClick={this.downloadpptref}> PPTX referenssit </button>
                            </span>                            
                            </div>
                            </td>
              </tr>
            
              </tbody>
              </table>
              </div>
              <div className="display-refmonth"  tabIndex={0} onKeyDown={e => this.keyUpProject(e) }>
              {projects && projects.map(p => {
                return <ProjectSummaryRow clickProject={this.clickProject} key={`${p.uuid}:${p.uid}`} deactivated={this.state.deactivated.includes(p.uuid)} project={{ ...p}} />;
              })}
              </div>
        
              </div>
            </div>
            </div>
            </div>
          </div>
      }

}