import React from 'react'
import LaskutusEnnuste from './LaskutusEnnuste'
import LaskutusToteuma from './LaskutusToteuma'

const Laskutus = ({project,updateProjectData,updateRow,deleteRow,project_uid, henkilo_uid, displayClientInvoiceModal,shortlisted,jwt}) =>{
    if(!project) return <span />;
    return(
        <div style={{marginLeft:'20px'}}>
            <h3>Ennuste</h3>
            <LaskutusEnnuste shortlisted={shortlisted} henkilo_uid={henkilo_uid} project={project} updateProjectData={updateProjectData} updateRow={updateRow}  deleteRow ={deleteRow} project_uid={project_uid} displayClientInvoiceModal={displayClientInvoiceModal}/>
            
            <h3>Toteuma (työ + kulu)</h3>
            <LaskutusToteuma shortlisted={shortlisted} henkilo_uid={henkilo_uid} project={project} updateProjectData={updateProjectData} updateRow={updateRow} jwt={jwt} />
        </div>
    )
}

export default Laskutus