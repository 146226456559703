import React, { Fragment } from 'react'
import {Icon, Image, Modal } from "semantic-ui-react";
import back_end from '../../../BackEnd'
import {fileIcon} from "../../../FileIconHelpers"


//const tokenid = localStorage.getItem("token")
const DisplayImgModal = ({el,deleteImg,jwt}) => {
  const istyle = {width:'16px', height:'16px', margin:'2px 2px -1px 0 '}

  return <Fragment>
      <Modal trigger={<span className="img__upload-btn">
            <Icon name="images outline" />
          </span>} closeIcon>
        <Modal.Header> kuvat </Modal.Header>
        <Modal.Content image scrolling>
          <Modal.Description>
            {el.images && el.images.map((img,i)=>{
              if(img.mimetype && img.mimetype.includes("image") ){
                return <div key={img.uuid} style={{position:'relative', padding:'10px'}}>
                <Image
                key={img.uuid}
                  size="huge"
                  src={
                    `${back_end}image?image_uuid=${img.uuid}`
                  }
                  alt={img.filename}
                />    
                {deleteImg && 
                  <span style={{ cursor: "pointer", right:'10px',position:'absolute', top:'15px'}} onClick={e => {
                    deleteImg(img.uuid);
                  }}><Icon color="red" name="close" title='poista' /></span>
                }
                </div>
              }else{
                return (
                  <div key={img.uuid} style={{position:'relative', padding:'10px'}} > 
                  <a href={`${back_end}/image?jwt=${jwt}&image_uuid=${img.uuid}`} download >
                  <img alt="" src = {fileIcon(img.filename)} style={istyle} /> 
                   {img.filename} </a> 
                   <span style={{ cursor: "pointer", right:'10px',position:'absolute' }} onClick={e => {
                    deleteImg( img.uuid );
                  }}><Icon color="red" name="close" title='poista' /></span>
                  </div>
                )
              }
            })
          
          
          }
          <span></span>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </Fragment>
}

export default DisplayImgModal
