import React, { Fragment } from 'react';
import { fix1,fix2 } from '../../../Helpers';


/*const el = (e,t)=>{
    return e ? <span title = {t || '' }> • {e}</span> : <span />
}*/

const fix = (value, digits=0)=>{
  try{
    return value.toFixed(digits)
  }catch(e){
    return value
  }
  
}
const ProjektiMittarit = ({data}) => {

  return (
    <div >
      {data &&  
        <Fragment>
        <h3>{data.uid}: {data.nimi}</h3> 
        {data.mittarit &&
        <table style={{textAlign:'left', border:'1px #999999 solid', borderRadius:'5px', margin:'5px', marginBottom:'20px', padding:'10px', display:'inline-block'}}>
          <thead style={{verticalAlign:'bottom'}}>
          <tr><th>Toteuma<br />&nbsp;</th><th>Myyty suunnitelma</th><th>Nykyinen suunnitelma</th><th>Toteuma</th></tr>
          </thead><tbody>
          <tr><th>Päivähinta</th><td className='numeric'>{fix(data.mittarit.myyty_paivahinta)} € </td><td className='numeric'>{fix(data.mittarit.suunniteltu_paivahinta)} € </td><td className='numeric'>{data.mittarit.toteutunut_paivahinta.toFixed(0)} € </td></tr>
          <tr><th>Tunnit</th><td className='numeric'>{ fix(data.mittarit.myyty_tunnit)} h </td><td className='numeric'>{fix(data.mittarit.suunniteltu_tunnit)} h </td><td className='numeric'>{data.mittarit.toteutunut_tunnit.toFixed(0)} h </td></tr>
          <tr><th>Päivät</th><td className='numeric'>{ fix(data.mittarit.myyty_paivat)} pv</td><td className='numeric'>{fix(data.mittarit.suunniteltu_paivat)} pv</td><td className='numeric'>{data.mittarit.toteutunut_paivat.toFixed(0)} pv</td></tr>
          <tr><th>Yhteensä</th><td className='numeric'>{ fix(data.mittarit.laskutus)} € </td><td className='numeric'>{fix(data.mittarit.laskutus)} € </td><td className='numeric'>{fix(data.mittarit.toteutunut_laskutus)} € </td></tr>
          </tbody></table>
        }
        {data.mittarit &&
        <table style={{textAlign:'left', border:'1px #999999 solid', borderRadius:'5px', margin:'5px', marginBottom:'20px', padding:'10px', display:'inline-block'}}>
          <thead>
          <tr><th colSpan='2'>Kannattavuus</th></tr>
          </thead>
          <tbody><tr><th>Laskutus</th><td className='numeric'>{fix(data.mittarit.laskutus)} €</td></tr>
          {data.mittarit.palkkiot && <tr><th>Palkkiot</th><td className='numeric'>{fix(data.mittarit.palkkiot)} €</td></tr> }
          <tr><th>Tuntipalkkiot</th><td className='numeric'>{fix(data.mittarit.tuntipalkkiot)} €</td></tr>
          <tr><th>Laskennallinen tuntihinta</th><td className='numeric'>{fix2(data.mittarit.tuntipalkkiot / data.mittarit.suunniteltu_tunnit)} €</td></tr>
          <tr><th>Ei-laskutettavat kulut</th><td className='numeric'>{fix(data.mittarit.eilaskutettavatkulut)} €</td></tr>
          <tr><th>Kate</th><td>{fix(data.mittarit.kate,0)} €</td><td>{Number( data.mittarit.kate/data.mittarit.laskutus * 100.0).toFixed(1)} %</td></tr>
          <tr><th>Tavoitekannattavuus</th><td>&nbsp;</td><td>{fix1( data.tavoitekannattavuus* 100.0)} %</td></tr>
          { data.kiintea === 'Toteumapohjainen' &&
            <tr><th>Laskuttamaton työ / kulut</th><td>{fix(data.mittarit.suunniteltu_paivahinta*data.mittarit.toteutunut_paivat-data.mittarit.laskutus)} €</td>
            <td>{fix2(data.mittarit.laskuttamattomatkulut || 0.0)} €</td></tr>
          }
          </tbody></table>
        }
        <div><a href='https://nhgbi.azurewebsites.net/home/run?reportid=917845e3-5354-4f5c-b59c-e4c3a98399fd&groupid=a88c83cb-c239-41c6-b2c1-e78c35093528'>Power BI mittaristo</a></div>
        </Fragment>
      }
    </div>
  )
}

/*
Tuotteen seuranta 
                                    Suunnitelma               Toteuma
Päivähinta
Tunnit
Päivät 
Tunnit per asiakas (lasketaan käytetyt tunnit/asiakkaiden määrä(huomoioidaan montako kuukautta ollut mukana)

Tuotteen kannattavuus
Kokonais LV
Tuntipalkkiot
Ei-laskutettavat kulut
Kate € ja %

*/ 

const TuoteMittarit = ({data}) => {
  return (
    <div >
      {data &&  
        <Fragment>
        <h3>{data.uid}: {data.nimi}</h3> 

        <table style={{textAlign:'left', border:'1px #999999 solid', borderRadius:'5px', margin:'5px', marginBottom:'20px', padding:'10px', display:'inline-block'}}>
          <thead style={{verticalAlign:'bottom'}}>
          <tr><th>{" "}</th><th>Suunnitelma</th><th>Toteuma</th></tr>
          </thead><tbody>
          <tr><th>Päivähinta</th><td className='numeric'>{fix(data.mittarit.bmmyynti/data.mittarit.myyty_paivat)} € </td><td className='numeric'>{data.mittarit.toteutunut_paivahinta.toFixed(0)} € </td></tr>
          <tr><th>Tunnit</th><td className='numeric'>{fix(data.mittarit.myyty_tunnit)} h </td><td className='numeric'>{data.mittarit.toteutunut_tunnit.toFixed(0)} h </td></tr>
          <tr><th>Päivät</th><td className='numeric'>{fix(data.mittarit.myyty_paivat)} pv</td><td className='numeric'>{data.mittarit.toteutunut_paivat.toFixed(0)} pv</td></tr>
          <tr><th>Tunnit per asiakas</th><td className='numeric'>{fix(data.mittarit.myytty_tunnit/data.asiakkaat.length)} h/asiakas  </td><td className='numeric'>{fix(data.mittarit.toteutunut_tunnit / data.asiakkaat.length)} h/asiakas </td></tr>
          </tbody></table>
        <table style={{textAlign:'left', border:'1px #999999 solid', borderRadius:'5px', margin:'5px', marginBottom:'20px', padding:'10px', display:'inline-block'}}>
          <thead>
          <tr><th colSpan='2'>Kannattavuus</th></tr>
          </thead><tbody>
          <tr><th>Laskutus</th><td className='numeric'>{fix(data.mittarit.laskutus)} €</td></tr>
          <tr><th>Tuntipalkkiot</th><td className='numeric'>{fix(data.mittarit.tuntipalkkiot)} €</td></tr>
          <tr><th>Laskennallinen tuntihinta</th><td className='numeric'>{fix2(data.mittarit.tuntipalkkiot / data.mittarit.toteutunut_tunnit)} €</td></tr>
          <tr><th>Ei-laskutettavat kulut</th><td className='numeric'>{fix(data.mittarit.eilaskutettavatkulut)} €</td></tr>
          <tr><th>Kate</th><td>{fix(data.mittarit.kate,0)} €</td><td>{Number( data.mittarit.kate/data.mittarit.laskutus * 100.0).toFixed(1)} %</td></tr>          
          </tbody></table>
        <div><a href='https://nhgbi.azurewebsites.net/home/run?reportid=917845e3-5354-4f5c-b59c-e4c3a98399fd&groupid=a88c83cb-c239-41c6-b2c1-e78c35093528'>Power BI mittaristo</a></div>
        </Fragment>
      }
    </div>
  )
}

const Mittarit = (data) =>{
  if(true) return ProjektiMittarit(data) // not ready yet
  if(null == data.data) return "";
  return data.data.uid[0] === 'B' ?
    TuoteMittarit(data):
    ProjektiMittarit(data)
}


export default Mittarit
