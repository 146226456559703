
/*
const KOKOPAIVARAHA = 42
const OSAPAIVARAHA = 19
const KILOMETRIKORVAUS  =0.43

 */
// 2021
/*const KOKOPAIVARAHA = 44
const OSAPAIVARAHA = 20
const KILOMETRIKORVAUS  =0.44
 */

const KOKOPAIVARAHA = 45
const OSAPAIVARAHA = 20
const KILOMETRIKORVAUS  =0.46


export const kms2eur = ({km,matkustajia}) => ( (- - km) * (KILOMETRIKORVAUS + 0.03 * (- - matkustajia) )).toFixed(2)
export const time2decimalhours = strtime => strtime.slice(0,-3) - - strtime.slice(-2)/60.0


export const laskePaivaraha = (alkupaiva, alkuaika,loppupaiva,loppuaika)=>{
    // lasketaan tuntimäärä
    const vrkhs = (()=>{
        if(alkupaiva === loppupaiva) return 0
        if( !alkupaiva || !loppupaiva) return 0
        return (new Date(loppupaiva) - new Date(alkupaiva))/3600000
    })()
    
    const kesto = vrkhs + (()=>{
        const decimalAlku = time2decimalhours(alkuaika)
        let   decimalLoppu = time2decimalhours(loppuaika)
        /*if(decimalAlku > decimalLoppu){
            decimalLoppu += 24.0
        }*/
        return decimalLoppu - decimalAlku
    })()
    let summa = 0
    if( kesto <= 24 ){ // alle yhden päivän matka
        if( kesto < 6 ) return summa;
        else if (kesto < 10){ return summa + OSAPAIVARAHA }
        else{ return summa + KOKOPAIVARAHA }    
    }
    let remaining = kesto
    while(remaining >= 24){
        summa += KOKOPAIVARAHA
        remaining -= 24
    }

    if( remaining < 2 ) return summa;
    else if (remaining < 6){ return summa + OSAPAIVARAHA }
    else{ return summa + KOKOPAIVARAHA }
}