import React, {Component,Fragment} from 'react';
import { fix1,fix2} from '../../Helpers'


const breakdownrow = ({project_uid, hours, ennuste, percentage, lookupData}) =>{
  const cstyle  ={ minWidth:"80px"}
  return ( <tr key={project_uid} >
    <td style={cstyle}> {project_uid} {lookupData.projects[project_uid]}:</td> 
    <td style={cstyle}>{fix2(hours)} h </td>
    <td style={cstyle}>{Math.floor(percentage*100)} %</td>
    <td style={{...cstyle, color:'#999999', textAlign:'left'}}>{fix1(ennuste)} h </td>
    </tr> )
}
const daybreadownrow = ({day,hours,cumulative})=>{
  return ( <div key={day} >
    <div style={{display:'inline-block', minWidth:"120px"}}>{day}:</div> 
    <div style={{display:'inline-block', minWidth:"80px",textAlign:'right'}}>{fix2(hours)} h </div>  
    <div style={{display:'inline-block', minWidth:"80px",textAlign:'right'}}>{fix2(cumulative)} h </div>  
    </div> )
}
class Statistics extends Component{
  render(){
    const { person, month,lookupData} = this.props
    let cumulative = 0
    const daybreakdown = month && month.dayBreakdown ? month.dayBreakdown.reduceRight((a,c)=>{
      cumulative += c.hours
      a.unshift( {...c, cumulative:cumulative} )
      return a
    }, [])
    :[]

    return (
      <div 
      id="statistics"
      style={{
        width:'99%', 
        height:'50vh',
        top:0,
        left:0,
        padding:"10px",
        "borderRadius":"8px",
        border:"2px solid #1895D655",
        overflowY:'auto',
        fontWeight:300
      }} >
      
      {false && person && 
        <div> Työnantaja: {person.tyonantaja}<br />
              Lomia pitämättä: {person.lomiaPitamatta} </div>
      }
      {month && month.projectBreakdown && (month.projectBreakdown.length > 0) &&
        <Fragment>
        <h2> Projektikohtaiset tunnit </h2>
        <table >
        <tbody>
        <tr>
        <td style={{ minWidth:"80px"}}> &nbsp;</td> 
        <td style={{ minWidth:"80px"}}> Tunnit</td> 
        <td style={{ minWidth:"80px",textAlign:'left'}}> Osuus </td>  
        <td style={{ minWidth:"80px",textAlign:'left'}}> Ennuste </td>  
        </tr>
        {month.projectBreakdown
          .filter( ({project_uid})=> !project_uid.startsWith('X') )
          .sort( (a,b) =>  b.percentage-a.percentage )
          .map( a =>{return  {...a, lookupData} }) 
          .map( breakdownrow )}
        </tbody>
        </table>
        </Fragment>
      }      
        
      {month && month.dayBreakdown && month.dayBreakdown.length > 0 &&
        <Fragment>
        <h2> Päiväkohtaiset tunnit</h2>
        <div >
        <div style={{display:'inline-block', minWidth:"120px"}}> &nbsp;</div> 
        <div style={{display:'inline-block', minWidth:"80px",textAlign:'right'}}>Tunnit </div>  
        <div style={{display:'inline-block', minWidth:"80px",textAlign:'right'}}>Kumul. </div>  
        </div></Fragment>
          
      }
      {month && month.dayBreakdown && month.dayBreakdown.length > 0 &&        
        daybreakdown.map(daybreadownrow)
    }
    </div>
    )
  }
}

/*
const Statistics = ({el}) => {
  return (
    <div 
    id="statistics"
    style={{
      width:'99%', 
      height:'50vh',
      top:0,
      left:0,
      padding:"10px",
      "borderRadius":"8px",
      border:"2px solid #1895D655"
    }} >
    {el && <div className="rowone">
      <div> <h4> Hours </h4></div>
      <div> <h4> Expenses</h4></div>
      <div> <h4> Sickdays</h4></div>
      <div> <h4> Vacations</h4> </div>
      <div><p> {el.hours} </p></div>
      <div><p> {el.expences}</p></div>
      <div><p> {el.sickdays}</p></div>
      <div><p>{el.vacations}</p></div>

    </div>}
    </div>
  )
}*/

export default Statistics; 
