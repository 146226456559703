import React, { Component,Fragment } from 'react';
import { Icon } from "semantic-ui-react";
import {fix0} from "../Helpers"
import backEnd from "../BackEnd";

const WAIT_INTERVAL = 400;
const FileSummaryRow = ({file, clickFile})=>{
    /*            <span>{file.date}</span> */
    return (
        <Fragment>
            <span onClick = {(e)=>{return clickFile(e,file)}}
                  style = {{fontWeight:600,color:'#6666ff', textAlign:'left', cursor: 'pointer',}}
            >{file.nimi}</span>
            <span style={{textAlign:'left'}}>{file.projekti_uid}</span>
            <span style={{textAlign:'left'}}>{file.henkilo_uid}</span>
            <span style={{textAlign:'left'}}>{file.tags}</span>
        </Fragment>
    )
}

export function Handle({   handle: { id, value, percent }, getHandleProps }) {
    return (
        <div
            style={{
                left: `${percent}%`, position: 'absolute',
                marginLeft: -15, marginTop: 25,
                zIndex: 2,
                width: 30, height: 30, border: 0,
                textAlign: 'center',
                cursor: 'pointer',
                borderRadius: '50%',
                backgroundColor: '#2C4870', color: '#333',
            }}
            {...getHandleProps(id)}
        >
            <div style={{ fontFamily: 'Roboto', fontSize: 11, marginTop: -35 }}>
                {fix0(value)}
            </div>
        </div>
    )
}




export class FileSearch extends Component {
    state={
        ...this.props,
        'vapaateksti': '',
    }

    componentDidMount(){
        this.setState({...this.props, ...this.state })
    }

    download = (f)=>{
        const{jwt} = this.props
        const url =`${backEnd}refdocx?jwt=${jwt}&project_uids=${uriprojstr}`
        const link = document.getElementById('downloadlink')
        link.href = url
        link.click()

    }
    timer = null;

    searchHandler = (e) => {
        e && e.preventDefault();
        clearTimeout(this.timer);
        this.timer = setTimeout(this.refreshChange, WAIT_INTERVAL);
        // set state to match values
        this.setState({
            'vapaateksti': this.refs['vapaateksti'].value,
        })
    }

    prevSearch = ""
    refreshChange = () => {
        // kerätään search-string
        let searchValue = this.state.vapaateksti
        if( searchValue === this.prevSearch ){
            return
        }
        this.prevSearch = searchValue
        this.props.searchFile(searchValue)
    }

    sliderChanged = (label,values) =>{
        this.setState({
            [label]: values
        })

        clearTimeout(this.timer);
        this.timer = setTimeout(this.refreshChange, WAIT_INTERVAL);

    }
    downloadfile = async (e,file)=>{
        const{jwt} = this.props
        const url =`${backEnd}file?jwt=${jwt}&uuid=${file.uuid}`
        const link = document.getElementById('downloadlink')
        link.href = url
        link.click()
    }

    render() {
        const {files} = this.props
        return <div className="backdrop" >
            <div style={{display:'block', textAlign:'center', marginLeft:'auto', marginRight:'auto'}}>
                <div className="ref-modal">
                    <Icon color="grey" name="close" title='Sulje' onClick={this.props.cancel} style={{position:'absolute', right:'10px', top:'10px'}}/>
                    <div style={{display:'block', marginLeft:'auto',marginRight:'auto', overflowY:'auto', top:'10px',bottomm:'10px'}}>
                        <h2>Tiedostohaku: <input ref='vapaateksti' type="text" placeholder="" style={{width:'340px'}} onChange={this.searchHandler}  value={this.state.vapaateksti} /> </h2>
                        <div>{files && `${files.length} tiedostoa.`}</div>
                        <div className="filelist"
                                 style={{minHeight: '40vh',maxHeight: '80vh',
                                     display: 'grid', gridTemplateColumns: 'auto 80px 180px 120px',
                                     columnGap: '10px',rowGap:'10px', alignContent:'start',
                                     overflow: 'scroll',gridAutoRows: 'max-content',
                                     left:'20px', right:'20px'
                                 }}>
                                {files && files.map(p => {
                                    return <FileSummaryRow clickFile={this.downloadfile} key={`${p.uuid}:${p.uid}`} file={{ ...p}} />;
                                })}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    }

}