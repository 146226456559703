import * as actionTypes from "../actions/actionTypes";
import { v4 as uuid } from 'uuid';

const initialState = {
  data: null,
  jwt: null,
  selectedMonth: null, // string
  project_uid: null, // string
  currentProject: null, // the  object
  pseudo_uid: null,
  pseudo_person: null,
  hours: [],
  expenses: [],
  updatedRow: null,
  projects: null,
  files:null,
  monthlyHours: null,
  showHours: false,
  showProjects: false,
  uploadProgress:0,
  user_uuid:null,
  counter:0,
  expenseModal:false,
  clientInvoiceModal:false
};

export const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    // initial storing
    case actionTypes.STORE_TOKEN:
      return {...state, ...action.payload };
    case actionTypes.STORE_USER:
      return { ...state, ...action.payload };

    case actionTypes.SELECTED_MONTH:
      return { ...state, selectedMonth: action.month, currentMonth: action.statistic };

    case actionTypes.STORE_HOURS:
      return { ...state, hours: action.payload, showHours: true };

    case actionTypes.STORE_EXPENSES:
      return { ...state, expenses: action.payload };
    case actionTypes.STORE_PERSON:
      return { ...state, person: action.payload };
    case actionTypes.STORE_PROJECT_LIST:
      return { ...state, projects: action.projects };
    case actionTypes.STORE_FILE_LIST:
      return { ...state, files: action.files };
    case actionTypes.STORE_CURRENT_PROJETCS:
      return { ...state, currentProject: action.project, project_uid: action.project.uid };
    case actionTypes.STORE_DROPDOWN:
      return { ...state, dropdowndata: action.payload };
    case actionTypes.STORE_LOOKUP:
      return { ...state, lookupdata: action.payload };
    case actionTypes.STORE_SEARCH:
      return {...state, search:action.payload}
    case actionTypes.STORE_FILE_SEARCH:
      return {...state, search:action.payload}
    case actionTypes.STORE_MONTHLY_HOURS:
      return { ...state, monthlyHours: action.payload };
    case actionTypes.DISPLAY_HOURS:
      return { ...state, showHours: true, showProjects: false };
    case actionTypes.INIT_STORE_SHORTLISTED_MONTHLYHOURS:
      return { ...state, pseudo_uid: action.pseudo_uid, pseudo_person: action.pseudo_person };
    case actionTypes.CREATE_USER_UUID:
      return { ...state, user_uuid:action.uuid};
    case actionTypes.ADD_COUNTER:
   
      return { ...state, counte: state.counter++ };


    case actionTypes.DISPLAY_PROJECT:
      return { ...state, showHours: false, showProjects: true };
    case actionTypes.SHOW_EXPENSE_MODAL:
      return { ...state, expenseModal: true };
    case actionTypes.CANCEL_EXPENSE_MODAL:
      return { ...state, expenseModal:false }

    case actionTypes.SHOW_CLIENT_INVOICE_MODAL:
      return { ...state, clientInvoiceModal: true, clientInvoice:action.invoice };
    case actionTypes.CANCEL_CLIENT_INVOICE_MODAL:
      return { ...state, clientInvoiceModal:false }

      case actionTypes.SHOW_REF_SEARCH_MODAL:
      return { ...state, refSearchModal: true };
    case actionTypes.CANCEL_REF_SEARCH_MODAL:
      return { ...state, refSearchModal:false }
    case actionTypes.COPY_REF:
      return {...state, project_uid: action.projekti_uid}

    case actionTypes.SHOW_FILE_SEARCH_MODAL:
      return { ...state, fileSearchModal: true };
    case actionTypes.CANCEL_FILE_SEARCH_MODAL:
      return { ...state, fileSearchModal: false }

      // updating state
    case actionTypes.UPDATE_UPLOAD_PROGRESS:
      return { ...state, uploadProgress: action.progress };

    case actionTypes.UPDATE_HOURS:
      return { ...state, hours: action.hours };
    case actionTypes.UPDATE_EXPENSES:
      return { ...state, expenses: action.expenses };
    case actionTypes.UPDATE_PROJECT_DATA:
      return { ...state, currentProject: { ...state.currentProject, [action.name]: action.projectData } };
    case actionTypes.UPDATE_ROW:
      return { ...state, updatedRow: action.row };

    case actionTypes.DUPLICATE_EXPENSES:
      const oldRow = state.expenses.find(el => el.uuid === action.uuid);
      return { ...state, expenses: [{ ...oldRow, uuid: uuid() }, ...state.expenses] };

    default:
      return state;
  }
};
