import React, { Component } from 'react'
import { Icon } from "semantic-ui-react";
import { v4 as uid } from 'uuid';


 class ImgUploader extends Component {

     state = {
         file: null,
         
     };
     // onchnage handler
     inputChangeHandler = e => {
         const { ref_key, ref_table, uploadImg, updateImg, tags } = this.props
         
         e.target.files && Array.from(e.target.files).forEach(file => {
            const uuid = uid()
            this.setState({ file });
            updateImg({ ref_key, uuid, filename:file.name, mimetype:file.type  }) //file.
            uploadImg({
                uuid: uuid,
                ref_table: ref_table,
                ref_key: ref_key,             
                image_data: file,
                tags:tags
            })}
        )
         
         
     };
    



  render() {
    const{el,progress}=this.props
    return <span>
        <input accept="image/*, application/pdf" style={{ display: "none" }} multiple="multiple" onChange={this.inputChangeHandler} ref={fileInput => (this.fileInput = fileInput)} type="file" />
        <span onClick={() => {
            this.fileInput.click();
          }} className="img__upload-btn">
          <Icon name="cloud upload" color={el.images[0] ? "green" : "black"} title="Lisää kuva" />
        </span>
        {100> progress > 0 && <progress value={progress} max="100" />}
      </span>;
  }
}
export default ImgUploader;