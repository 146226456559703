import React, { Component, Fragment } from "react";
import SplitPane from "react-split-pane";
import Pane from "react-split-pane/lib/Pane";
import Hours from "../detailViews/Hours";
import Projects from "../detailViews/Projects";
import MonthCard from "./MonthCard";
import Statistics from "./Statistics";
import ProjektiMittarit from '../detailViews/projects/ProjektiMittarit'
import ProjectSummaryRow from "../detailViews/projects/ProjectSummaryRow"
//import Auth from "./Auth";
import { Button,Input} from "semantic-ui-react";
import UserInfo from "./UserInfo";
import ShortListedDropDown from './ShortListedDropdown'
import { connect } from "react-redux";
import * as allActions from "../../store/actions/actionCreators";
import XLSUploader from "./XLSUploader";
import ReportList from "./ReportList";
import { v4 as uuid } from 'uuid';
import ExpensesInvoices from './ExpensesInvoices'
import ClientInvoices from './ClientInvoices'
import {RefSearch} from '../RefSearch'
import {FileSearch} from "../FileSearch";
import {zeroPad, date2isomonth} from '../../Helpers'
import backEnd from '../../BackEnd'
import {has_warnings} from '../../Warnings'
import {initializeData} from "../../store/sagas/dashBoardSaga";
import {createUserUuid, displayHours, initData} from "../../store/actions/actionCreators";
import MsalWrap, {MsalRefresh} from "./MSAuth";

const WAIT_INTERVAL = 400;
class DashBoard extends Component {
  state={searchValue:'', rightWidth:"40%"}
  timer = null;

  keyUpHours = e => {
    const { selectedMonth, getSelectedMonth, monthlyHours, pseudo_uid } = this.props;
    const monthMillisecs = 1000 * 3600 * 24 * 31;

    //compute next month
    const nextMonth = (() => {
      if (e.key === "ArrowUp") {
        return date2isomonth( new Date(new Date(selectedMonth) - -monthMillisecs) )
      } else if (e.key === "ArrowDown") {
        return date2isomonth( new Date(new Date(selectedMonth) - monthMillisecs) )
      } else 
        return null
    })();

    if (nextMonth) {
      const m = monthlyHours.find(m => m.month === nextMonth);
      if (m) {
        e.preventDefault();
        getSelectedMonth(nextMonth, m, pseudo_uid);
      }
    }
  };

  keyUpProject = e => {
    const { currentProject, projects, initGetCurrentProject } = this.props;
    if (!(currentProject && projects && initGetCurrentProject)) return;
    
    const currentIndex = projects.findIndex(p => currentProject.uid === p.uid);
    const newProject = (() => {
      try {
        if (e.key === "ArrowDown") {
          return projects[currentIndex + 1];
        } else if (e.key === "ArrowUp") {
          return projects[currentIndex - 1];
        }
        return null;
      } catch (e) {
        // might be out of array indexing
        return null;
      }
    })();
    if (newProject) {
      e.preventDefault();
      initGetCurrentProject(newProject);
    }
  };

  getProjects = () => {
    // nollataan pseudo_uid
    this.props.initStoreShortlisted(null,null);
    this.props.initGetProjects();
    const proj = this.props.currentProject || this.props.projects[0]
    if(proj){ 
      this.props.initGetCurrentProject(proj)
    }
  }

  searchHandler = e => {
    e.preventDefault();
    clearTimeout(this.timer);
    const searchValue = e.target.value;
    this.setState({searchValue})
    this.timer = setTimeout(this.refreshChange, WAIT_INTERVAL);
  }

  refreshChange = () => {
    const{searchValue}=this.state
    this.props.searchPerson(searchValue)
  }

  copyCurrentProject = ()=>{
    const {currentProject} = this.props
    if( currentProject.tuotevaiprojekti === 'tuote') { 
      const uusi_vuosi = Number( currentProject.uid.slice(1,3) ) - - 1
      const uusi_uid = currentProject.uid.slice(0,1) + zeroPad(uusi_vuosi,2) + currentProject.uid.slice(3) 
      const { kulut,laskut,tunnit, asiakkaat, tyoennuste, tyototeuma, mittarit, laskutusennusteet, 
              ...newProject} = {...currentProject, "uuid":uuid(), "uid":uusi_uid }
      
      this.props.updateRow({
          info: 'projekti.uusi',
          className:'projekti',
          action:'upsert',
          data: newProject
      })

      currentProject.asiakkaat.forEach(a=>{
        const newAsiakas = {...a, uuid:uuid(), projekti_uid: uusi_uid, projekti_uuid:newProject.uuid}
        this.props.updateRow({
          info:'uusi_asiakas',
          className: 'projektiasiakas',
          action: 'upsert',
          data: newAsiakas
        })      
      })

    }else { // on projekti 
      const dummy_uid = 'Z' + parseInt(1000000 * Math.random(),10)
      const { kulut,laskut,tunnit, asiakkaat, tyoennuste, tyototeuma, mittarit, laskutusennusteet, 
              ...newProject} = {...currentProject, "uuid":uuid(), "uid":dummy_uid, }
  
       this.props.updateRow({
        info: 'projekti.uusi',
        className:'projekti',
        action:'upsert',
        data: newProject
      })
    }                  
  }

  addNewProject = () => {
    // tehdään dummy projekti_uid Z + random number
    const dummy_uid = 'Z99' + parseInt(1000000 * Math.random(),10)
    const { person } = this.props
    
    const newProject = {
      "uuid": uuid(),
      "uid": dummy_uid,
      "nimi": "Uusi nimetön projekti",
      "liiketoimintaalue": "0.0",
      "liiketoimintaaluetoissijainen": null,
      "paallikko": person.uid,
      "tyyppi": null,
      "yhtio": person.tyonantaja,
      "asiakasvastuullinenmyyja": null,
      "suunnitellutbkulut": null,
      "jatkuva": "0.0",
      "kiintea": "0.0",
      "asiakkaat": [],
      "kansainvalisyysaste": 0,
      "vaikuttavuusperusteinen": null,
      "loppuraportintarkastaja": null,
      "paattynyt": null,
      "referenssitoimitettu": null,
      "tyytyvaisyyskyselylahetetty": null,
      "datatuhottava": null,
      "datatuhottu": null,
      "loppudokumentittoimitettu": null,
      "palautteetannettu": null,
      "laskut": [],
      "laskutusennusteet": [],
      "tyoennuste": [],
      "tyototeuma": [],
      "mittarit": {
        "myyty_paivahinta": 0,
        "suunniteltu_paivahinta": 0,
        "toteutunut_paivahinta": 0,
        "myyty_tunnit": 0,
        "suunniteltu_tunnit": 0,
        "toteutunut_tunnit": 0,
        "myyty_paivat": 0,
        "suunniteltu_paivat": 0,
        "toteutunut_paivat": 0,
        "laskutus": 0,
        "palkkiot": 0,
        "tuntipalkkiot": 0,
        "eilaskutettavatkulut": 0,
        "kate": 0
      },
      "kulut": [],
      "tunnit": [],
      "tuotevaiprojekti": person.yhtio === ' NHG Benchmarking Oy' ? 'tuote' : 'projekti',
      "raportointihierarkia":[],
      "tavoitekannattavuus": 0.69
    }

    this.props.addNewProject({
      project: newProject,
      projects: [newProject, ...this.props.projects]
    })
    this.props.updateRow({
      info: 'projekti.uusi',
      className:'projekti',
      action:'upsert',
      data: newProject
    })
  }

  splitChanged = (size)=>{
    this.setState({rightWidth:size[2]})
  }

  downloadref = (jwt)=>{
    const {projects} = this.props
    const projstring = projects.map(a=>a.uid).join(',')
    const uriprojstr = encodeURIComponent(projstring)
    const url =`${backEnd}refdocx?project_uids=${uriprojstr}&jwt=${jwt}`
    const link = document.getElementById('refdownloadlink')
    link.href = url
    link.click()
  }

  showRefSearch = ()=>{
    // avataan modal dialog
    this.props.displayRefSearchModal()
  }
  showFileSearch = ()=>{
    // avataan modal dialog
    this.props.displayFileSearchModal()
  }

  // hour elements
  hourbuttons = ({initialValue})=>{
    const {dropDownData,initStoreShortlisted,person} = this.props
    const shortlisted = person && person.shortlisted
    const plist = shortlisted ? dropDownData.persons : dropDownData.subordinates
    if( plist && Object.keys(plist).length > 1 ){
      return <div style={{ width: "80%", margin: "0 auto" }} >
        {dropDownData &&
        <ShortListedDropDown initialValue={initialValue} className="leftButton" valuemap={plist}
                             keyedChange={ (e,o) =>{ initStoreShortlisted(o.value, e.target.textContent) } }
        />
        }
      </div>
    }else{
      return <div style={{ width: "80%", margin: "0 auto" }} ></div>
    }
  }
  hour2pane = ()=>{  
    const {lookupData, person,stat, monthlyHours,selectedMonth,getSelectedMonth,pseudo_uid} = this.props
    return <Fragment>
      <Statistics lookupData={lookupData} person={person} month={stat} />
      <div className="display-month" tabIndex={0} onKeyDown={e => this.keyUpHours(e)}>
        {monthlyHours.map((el, i) => {
          return <MonthCard selectedMonth={selectedMonth} key={el.month} handleSelection={getSelectedMonth} el={el} pseudo_uid={pseudo_uid} />;
        })}
      </div>
    </Fragment>
  }

  // project elements
  projectbuttons = ({shortlisted})=>{
    return <div style={{marginLeft:'26px', display:'block'}}>
      <Button className="leftButton" color="blue" onClick={e => this.addNewProject()} >Uusi projekti</Button>
      <Button className="leftButton" color="blue" onClick={e => this.copyCurrentProject()}>Kopioi uudeksi</Button>
      <div className="search-btn">
        <Input  title='Vapaa tekstihaku. Hae projektipäällikön projekteja "PP:HLOID" tai asiakasvastuullisen myyjän "AM:HLOID" ' 
                onChange={e => this.searchHandler(e)} icon="search" placeholder="Haku..." />
        {/*<a id="refdownloadlink" style={{display:'none'}} href='/' download> </a>*/}
        {/*<a id="refpptdownloadlink" style={{display:'none'}} href='/' download> </a>*/}
      </div>
      {/*shortlisted && <Button className="leftButton" style={{right:'0'}} color="blue" onClick={e => this.downloadref()} content="Lataa referenssit" />*/}
    </div>
  }

  project2pane = ()=>{
    const {currentProject,projects,initGetCurrentProject} = this.props
    const {searchValue} = this.state
    const showedProjects = searchValue.startsWith("!") ?
      projects.filter( (p)=>has_warnings(p) )
      :projects;

    return <Fragment>
      <div className="project-card" style={{ width: "99%", height: "50vh", top: 0, left: 0, padding: "10px", margin: "0 5px 5px 5px", borderRadius: "8px", border: "2px solid #1895D655" }}>
        <ProjektiMittarit data={currentProject} />
      </div>
      <div className="display-month" tabIndex={0} onKeyDown={e => this.keyUpProject(e) }>
      {projects && showedProjects.map(p => {
        return <ProjectSummaryRow ref={p.uuid} 
             key={ `${p.uuid}:${p.uid}` } 
             onClick={() => initGetCurrentProject(p)} 
             project={{ ...p, active: currentProject && currentProject.uid === p.uid }} />;
      })}
      </div>
    </Fragment>
  }


  render() {
    const { loggedInuser, showHours, showProjects, displayHours, currentProject,
      person, dropDownData, lookupData, pseudo_uid,
      expenseModal, clientInvoiceModal, refSearchModal, fileSearchModal, jwt} = this.props;
   
    const {rightWidth} = this.state

    const initialValue = this.props.pseudo_person || (this.props.person? `${this.props.person.uid}:${this.props.person.nimi} ` : " ")
    const shortlisted = person && person.shortlisted 
    if( showProjects){
      // alustetaan joku projekti, jos ei ole valittu
 //     if(!currentProject && projects ){ setTimeout(()=>(initGetCurrentProject(projects[0])),0) }
      if( currentProject ){
        const el = this.refs[currentProject.uuid]
        if(el){  el.refs.rowdiv.scrollIntoViewIfNeeded() }
      }  
    } 

    return <div className="container">
      { clientInvoiceModal ? <ClientInvoices invoice={this.props.clientInvoice} cancel={this.props.cancelClientInvoiceModal}
                                             sendInvoice ={this.props.sendClientInvoice} lookupData = {lookupData} dropDown = {dropDownData}
        />
        : expenseModal ? <ExpensesInvoices expenses={person.openExpenses} cancel={this.props.cancelExpenseModal}
                                            sendInvoice ={this.props.sendInvoice} lookupData = {lookupData} dropDown = {dropDownData}
        />
        : refSearchModal ? <RefSearch searchPerson={this.props.searchPerson} projects={this.props.projects} dropDownData={this.props.dropDownData}  jwt={this.props.jwt} cancel={this.props.cancelRefSearchModal} />
        : fileSearchModal ? <FileSearch searchFile={this.props.searchFile} files={this.props.files} cancel={this.props.cancelFileSearchModal} jwt={this.props.jwt} />

        : null
      }

      <SplitPane split="vertical" onChange={ this.splitChanged } defaultSize={10} >      
        <Pane minSize="260px" maxSize="260px" >
          <div className="pane first">
            <div className="layer">
              <UserInfo jwt={jwt} user={loggedInuser} person={person} displayExpenseModal={this.props.displayExpenseModal}/>
              {loggedInuser && <Fragment>
                <Button onClick={displayHours} className="leftButton" color="blue" content="Tunnit" icon="hourglass outline" />
                  { showHours && this.hourbuttons({initialValue}) }
                <Button onClick={this.getProjects} className="leftButton" color="blue" content="Projektit" icon="sitemap" />
                  {showProjects && this.projectbuttons({shortlisted}) }
                <a id="refdownloadlink"    style={{display:'none'}} href='/' download> </a>
                <a id="refpptdownloadlink" style={{display:'none'}} href='/' download> </a>

                {/*shortlisted &&*/ <Button className="leftButton" style={{right:'0'}} color="blue" onClick={e => this.showRefSearch()} content="Projektihaku" />}
                {/*shortlisted &&*/ <Button className="leftButton" style={{right:'0'}} color="blue" onClick={e => this.showFileSearch()} content="Tiedostohaku" />}
                <a id="downloadlink" style={{display:'none'}} href='/' download> </a>

                <div>
                  <hr style={{ width: "50%" }} />
                  <h2 style={{ marginLeft: "35px" }}>XLS raportit</h2>
                  <ReportList person={this.props.person} project={currentProject} jwt={jwt}/>
                  <XLSUploader style={{ maxHeight: "200px" }} uploadXLS={this.props.uploadXLS} />
                  <hr style={{ width: "50%" }} />
                </div>
              </Fragment>}
              {/*<Auth />*/}
              <div>{}</div>
            </div>
          </div>
        </Pane>

        <Pane minSize={"100px"}>
          { (showHours||showProjects) && <MsalRefresh />}
          <div className="pane second">
            {showHours ?     this.hour2pane()
            : showProjects ? this.project2pane()
            : <MsalWrap user={loggedInuser}/>}
          </div>
        </Pane>

        <Pane initialSize={rightWidth} minSize="200px" onChange={this.rightPaneChanged}>
          <div className="pane third">
            <div className=" content">
              { showHours ? <Hours pseudo_uid={pseudo_uid} person={person} lookupData={lookupData} dropDownData={dropDownData} /> 
              : showProjects ? <Projects shortlisted={person&&person.shortlisted} henkilo_uid={person.uid} lookupData={lookupData} dropDownData={dropDownData} 
                  data={currentProject} refreshProjects={this.getProjects} displayClientInvoiceModal={this.props.displayClientInvoiceModal} jwt={jwt}/>
              : null }
            </div>
          </div>
        </Pane>
      </SplitPane>
    </div>;
  }
}
const mapStateToProps = state => {
  return {
    loggedInuser: state.loggedInuser.account,
    jwt: state.loggedInuser.jwt,
    selectedMonth: state.loggedInuser.selectedMonth,
    hours: state.loggedInuser.hours,
    projects: state.loggedInuser.projects,
    files: state.loggedInuser.files,
    expenses: state.loggedInuser.expenses,
    person: state.loggedInuser.person,
    monthlyHours: state.loggedInuser.monthlyHours,
    showHours: state.loggedInuser.showHours,
    showProjects: state.loggedInuser.showProjects,
    loading: state.loggedInuser.loading,
    stat: state.loggedInuser.currentMonth,
    currentProject: state.loggedInuser.currentProject,
    dropDownData: state.loggedInuser.dropdowndata,
    lookupData: state.loggedInuser.lookupdata,
    pseudo_uid: state.loggedInuser.pseudo_uid, 
    pseudo_person: state.loggedInuser.pseudo_person,
    expenseModal: state.loggedInuser.expenseModal,
    clientInvoiceModal: state.loggedInuser.clientInvoiceModal,
    clientInvoice:state.loggedInuser.clientInvoice,
    refSearchModal:state.loggedInuser.refSearchModal,
    fileSearchModal:state.loggedInuser.fileSearchModal
  };
};

export default connect(
  mapStateToProps,
  allActions
)(DashBoard);

