import React, { PureComponent } from "react";
import { Dropdown } from "semantic-ui-react";

let cache_options = null;
let cache_valuemap = null;

class ShortListedDropdown extends PureComponent {
  static cache_options = null;
  static cache_valuemap = null;

  constructor(props) {
    super(props);
    const { valuemap } = this.props;
    if (valuemap !== cache_valuemap) {
      cache_valuemap = valuemap;
      const keys = Object.keys(valuemap).sort();
      const options = (() => {
        // for arrays we map names and values to array items, for objects we do key-value mapping to object keys and values
        return Array.isArray(valuemap)
          ? valuemap.map(v => {
              return { text: v, value: v, key: v };
            })
          : keys.map(k => {
              return { text: valuemap[k], value: k, key: k };
            });
      })();
      cache_options = options;
      this.options = options;
    } else {
      this.options = [...cache_options];
    }
  }
  

  render() {
    const {  keyedChange,initialValue } = this.props;
   
    return <Dropdown fluid   placeholder={initialValue} style={{ maxWidth: "250px", display: "inline-block" }} 
            onChange={keyedChange} search selection lazyLoad options={this.options} />;
  }
}

    
export default ShortListedDropdown;
