import React from 'react'
import { timestamp2month , fix1, fix0} from '../../../Helpers';



const ProjektiTyoToteuma = ({project, lookupData}) =>{
    if(!project || !project.tyototeuma || project.tyototeuma.length === 0 ) {
        return <span> Ei toteutuneita työtunteja </span>;
    }

    // compute matrix to show hours in a table
    // show last 6 months of actual data and lupm older to final row
    const last6 = (()=>{
        let d = new Date( timestamp2month(+ new Date()))
        let a = [ timestamp2month(+ d)]
        for( let i=0;i<11; i++){
            d = new Date( timestamp2month( + new Date( d - (1000*3600*24*15) )  ))  
            a.push( timestamp2month(+ d) )
        }
        return a
    })()
    const rowMatrix = (()=>{
        let tot = [...project.tyototeuma]
        let m = {}
        for(let i in last6 ){
            const kk = last6[i]
            let r = tot.filter( e => e.kuukausi === kk )
            tot = tot.filter(e => !(e.kuukausi === kk) )
            let sum = 0
            m[kk] = r.reduce( (a,c)=>{
                const tunnit = (isNaN(c.tuntimaara)?0.0:c.tuntimaara).toFixed(0)
                a[c['henkilo_uid']] = tunnit
                sum -= -tunnit
                return a
            },{})
            m[kk]['Yhteensä'] = (sum === 0.0)? "":sum
        }
        m['Aiemmat'] = tot.reduce((o,i)=>{
           o[i.henkilo_uid] = Number((o[i.henkilo_uid] || 0) - - i.tuntimaara).toFixed(0)
           return o
        },{})

        m['Yhteensä'] = project.tyototeuma.reduce((o,e)=>{
            o[e.henkilo_uid] = Number((o[e.henkilo_uid] || 0) - - e.tuntimaara).toFixed(0)
            return o
        }, {}) 
        return m
    })()
    last6.push('Aiemmat')
    last6.push('Yhteensä')
    const totals = rowMatrix['Yhteensä']    
    let people = Object.keys(totals)
    people.sort((a,b)=>{return -(totals[a]-totals[b])})
    const lookupMap = lookupData.persons.reduce( (a,c)=>{
        a[c.uid] = c.nimi
        return a
    } ,{})
    const fullTotal =   project.tyototeuma.reduce( (a,c)=> a+c.tuntimaara, 0)
                        //project.tyototeuma.reduce( (a,c)=> last6.includes(c.kuukausi)?a+c.tuntimaara:a, 0)
    rowMatrix['Yhteensä']['Yhteensä'] = fullTotal
                
    people.push('Yhteensä')
    return(
        <div style={{marginLeft:'20px'}}>
            <table style={{border:'1px solid lightgray', padding:'5px',borderRadius:'5px'}}><thead>
                <tr><td>&nbsp;</td>{ last6.map(h=>
                    <th key={h} style={{ margin:'3px 5px', padding:'3px 5px'}} ><span style={{fontWeight:'400'}}
                             >{ h[0]!=="2" ? h : h.slice(-2) } </span></th>)}</tr>
                </thead><tbody>
                {people.map(h=>{ return(
                    <tr key={h} title={lookupMap[h]} ><th> { h } </th>
                        {last6.map(k=><td key={k} className='numeric' style={{margin:'3px 5px 3px 5px', padding:'3px 5px'}} ><span style={{fontWeight:300, textAlign:'right', display:'block'}}>{ rowMatrix[k][h] }&nbsp;</span>
                            </td>)}</tr>                    
                )})}
            </tbody></table>
            <div style={{marginLeft:'10px', fontWeight:'600'}}>Yhteensä: {fix0(fullTotal)} h ({fix1(fullTotal/7.5)} pv)</div>
         </div>
    )
}
export default ProjektiTyoToteuma