import React, { Component } from 'react'
import { Icon } from "semantic-ui-react";
import { v4 as uid } from 'uuid';
import DropDown from '../DropDown'
import {currentDate, date2isodate, fixnumber } from '../../../Helpers';
import Dates from '../hours/Dates'


const propMap ={
    pvm: 'Päiväys',
    asiakas: 'Asiakas',
    tyosumma: 'Työsumma',
    kulusumma : 'Kulusumma',    
  }
  const propTransform ={
/*    asiakas:{
        forward: a=>a.nimi,
        back: a=>a
    }*/
  }
  
  let rowstart = false
  const newRow=()=>{
    rowstart = false
    return ''
  }
  
  const el = ({data, editing, key, delimiter, post , onChange} )=>{       
    let e = data[key]
    if((!e || e === '')  && !editing) return <span key={key}/> // do not render null or empty items unless editing
    if( !e ) e = '' // avoid null values in react components https://reactjs.org/docs/forms.html#controlled-input-null-value
    if(!rowstart){ 
        delimiter = ''; 
        rowstart = true 
    }        
    const v = propTransform[key] ? propTransform[key].forward(e) : e
    const iw = ( Math.max( propMap[key].length,  (v + "").length) *1.1 ) + 'ch' // input width
    return editing ? 
          <span> <input style={{width:iw}} type='text' name={key} title={propMap[key]} className="tightProjectInput" value ={v} 
                        onChange={onChange} placeholder={propMap[key]} /> {post}&nbsp;</span>
        : <span title = {propMap[key] || '' }>{delimiter}{v} {post}</span>
  }
  const datel = ({data, editing, key, delimiter, post , keyedChange})=>{
    let e = data[key]
    if((!e || e === '')  && !editing) return <span key={key}/> // do not render null or empty items unless editing
    if(!rowstart){ delimiter = ''; rowstart = true }   
    return editing?
      <Dates keyedChangeHandler={keyedChange} value={e} field={key} uuid={data.uuid} />
      :<span>{delimiter}{e} {post}</span>
  }

  const asiakasDropDown = ({data, editing, key, delimiter, post , keyedChange, project} )=>{
    let e = data[key]
    if((!e || e === '')  && !editing) return <span key={key}/> // do not render null or empty items unless editing
    if( !e ) e = '' // avoid null values in react components https://reactjs.org/docs/forms.html#controlled-input-null-value
    if(!rowstart){ delimiter = ''; rowstart = true  } 
    const valuemap = [...new Set(project.asiakkaat.map(a => a.nimi)) ]
    //valuemap, selected, keyedChange, uuid, name, placeholder})=>{
    
    return editing ? 
          <span> <DropDown valuemap={valuemap} selected={e} keyedChange={keyedChange} uuid = {data['uuid']} name='asiakas' placeholder='Asiakas' /></span>
        : <span title = {propMap[key] || '' }>{delimiter}{e} {post}</span>

  }
const Ennusterow = ({ennuste,editing, onChange, keyedChange, project, createClientInvoice, shortlisted})=>{
    const def = {data:ennuste, editing, delimiter:', ', post:'', key:'', onChange, keyedChange}
    return(
      <div className='clientRow' key={ennuste.uuid} style={{position:'relative',marginRight:'30px'}}>
          <div className='clientHeader' >{newRow()}
            {datel({ ...def , key: 'pvm' })} {el({...def , key:'tyosumma'})} {el({...def , key:'kulusumma'})}            
          </div>
          <div className='clientDetail' style={{boxSizing:'border-box'}}>{newRow()}
              {asiakasDropDown( {...def, key:'asiakas', keyedChange:keyedChange, project:project} ) }
          </div>
          <div style={{position:'absolute',right:'50px', top:'5px'}}>{/*shortlisted &&*/ !editing && !ennuste.lasku_uuid && ennuste.asiakas && <button disabled onClick={()=>createClientInvoice(ennuste.uuid)}>Laskuta</button>}</div>
          
        </div>
    )
  
  }
  const EDIT_INTERVAL = 1000;
class LaskutusEnnuste extends Component{
    state = {
        editing: false,
        project: this.props.project,
        updateProjectData: this.props.updateProjectData,
        updateRow: this.props.updateRow,
        project_uid:this.props.project_uid
      }
  
     static getDerivedStateFromProps(props, state) {
         if (!props.shortlisted) {
             return {...state, ...props, editing: false}
         }
       if (props.project_uid !== state.project_uid) {
         return { ...state, ...props, editing: false };
       }

      if (!state.editing || props.project.uuid !== state.project.uuid) {
        return { ...state, ...props};
      }
      return null;
    }
 
    toggleEditing(){
      if( this.state.editing){
        this.setState({editing:false})
      }else{
        this.setState({editing:true})
      }
    }
 
    addNewEntry = ()=>{
      const {project} = this.state
      // ennustamaton laskutus:
      const myynti = project.asiakkaat.reduce((a,c)=>a+c.laskutus, 0)
      const ennustekoko   = project.laskutusennusteet.reduce( (a,c)=>(a+c.tyosumma), 0 )
      const ennustamatta = myynti - ennustekoko

      let newEnnuste = {
        pvm: date2isodate(new Date()),
        asiakas: '',
        tyosumma: ennustamatta,
        kulusumma: 0,
        projekti_uid: project.uid,
        uuid: uid()
      }
      project.laskutusennusteet.unshift(newEnnuste)
      this.setState({project})      
    }
    ennusteChanged = (ennuste, event)=>{
      event.preventDefault()
      let {name,value} = event.target
      value = fixnumber(value)
      this.keyedChange(ennuste.uuid, name, value)
    }
    
    editTimers = {}
    sendEdit = (ennuste)=>{
      const {updateRow} = this.props
      updateRow({
        info: 'projekti.laskutusennuste',
        className: 'laskutusennuste',
        action: 'upsert',
        data: ennuste
      })
    }
    keyedChange = (uuid,key,value)=>{
      const {updateProjectData, project} = this.state
      const old_ennuste = project.laskutusennusteet.find(a=>a.uuid===uuid)
      const new_ennuste = {...old_ennuste , [key]:value}
      const new_laskutusennusteet = project.laskutusennusteet.map(e=> e.uuid === uuid ? new_ennuste : e)
      const new_project = {...project, laskutusennusteet: new_laskutusennusteet}
      updateProjectData('laskutusennusteet',new_laskutusennusteet)
      clearTimeout(this.editTimers[uuid])
      this.editTimers[uuid] = setTimeout(()=>this.sendEdit(new_ennuste), EDIT_INTERVAL )
      this.setState({project:new_project})
    }

    deleteUUID = (uuid) =>{
      if( ! window.confirm("Haluatko varmasti poistaa laskutusennusteen?") ) return;
      const {project} = this.state
      const { updateProjectData, updateRow } = this.props
      let new_ennusteet = project.laskutusennusteet.filter(a=>a.uuid !== uuid)
      updateRow({
        info:'projekti.ennusteet',
        className:'laskutusennuste',
        action:'delete',
        data:{uuid, projekti_uid:project.uid}
      })
      updateProjectData('laskutusennusteet', new_ennusteet)
      this.setState({project:{...project, laskutusennusteet:new_ennusteet}})
    
    }
  
    createClientInvoice = (ennuste_uuid)=>{
      const {displayClientInvoiceModal} = this.props
      const {project} = this.state
      // tehdään invoice-datakenttä
      const ennuste = {...project.laskutusennusteet.find(a=>(a.uuid === ennuste_uuid)), pvm:currentDate}
      const asiakas = project.asiakkaat.find(a=>a.nimi === ennuste.asiakas)
      const expenses = project.kulut.filter(a=>(a.laskutettu === false  && a.laskutettava==="Asiakaslaskutettava"))
      const selite = `"${project.nimi}" -lasku`
      const pyynnontekija = project.paallikko
      const invoice = { ennuste,asiakas,expenses,selite, yhtio:project.yhtio, pyynnontekija, project}
      // asetetaan se propsiin
      // kutsutaan modal
      displayClientInvoiceModal(invoice);
    }
    
   render(){
      const{project, editing}=this.state
      const {shortlisted} = this.props
      const [totaltyo, totalkulu] = (()=>{
        return project && project.laskutusennusteet ? 
          project.laskutusennusteet.reduce( (o,a,i)=>{ return [o[0] - -(a.tyosumma || 0.0), o[1] - -(a.kulusumma ||0.0) ]} ,[0,0]) // - - and not + to force numeric
          : [0,0];
      })();
  
      return (
        <div style={{boxSizing:'border-box'}}>
          <span className="hoursIcon" style={{textAlign:'left',marginLeft:'20px'}} onClick={() => this.toggleEditing() }>
            <Icon  title={!editing ? "Muokkaa" : "Lopeta muokkaus"} color={editing ? "red" : "black"} size="large" name="edit" />
          </span>
          {editing && <span className="addIcon" onClick={this.addNewEntry}>
            <Icon  title='lisää' name="add" size="large" />
          </span>}
  
          { project && 
            project.laskutusennusteet &&
            <div >
            {project.laskutusennusteet.map( (ennuste,i)=>{ 
                return <div style={ editing ? {position:'relative'} : {}} key={ennuste.uuid} > 
                {editing && <span style={{position:'absolute', right:'50px', zIndex:'10'}} onClick={() => {
                  this.deleteUUID( ennuste.uuid ); }}>
                    <Icon id={ennuste.uuid} color="red" name="close" title='poista rivi' />
                </span>}
                <Ennusterow shortlisted={shortlisted} keyedChange={this.keyedChange} project={project} key={ennuste.uuid} ennuste={ennuste} editing={editing} 
                  onChange={e => this.ennusteChanged(ennuste, e)} createClientInvoice={this.createClientInvoice}/>  </div>  })}
            </div>
          }
            
            { project && 
              project.laskutusennusteet &&
              project.laskutusennusteet.length  > 1 &&
            <div className='clientTotal'>
            Yhteensä: {(+ totaltyo).toFixed(2)} + {(+ totalkulu).toFixed(2)} €. 
            </div>
    
          }
        </div>)
    }
}

export default LaskutusEnnuste