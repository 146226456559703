import React, { Component } from 'react'
import { Icon } from "semantic-ui-react";
import { v4 as uid } from 'uuid';

import pdficon from '../../../assets/pdficon.png'
import docicon from '../../../assets/docicon.png'
import xlsicon from '../../../assets/xlsicon.png'
import genicon from '../../../assets/genicon.png'
import url from '../../../BackEnd'
//import Auth from '../../layout/Auth'

 class Tiedostot extends Component {

     state = {
         'project' : this.props.project,
         'file': null,         
     }
     static getDerivedStateFromProps(props, state) {
         if(state.project == null ||state.project.uuid !== props.project.uuid){
             return {...state, ...props}
         }
         if(state.project && state.project.tiedostot && state.project.tiedostot.some(t=>t.nimi.includes("siirtyy..."))){
             // tarkistetaan onko siihen tiedostoon tullut muutoksia
             const file_uuids = state.project.tiedostot.filter(t=>t.nimi.includes("siirtyy...")).map(t=>t.uuid)
             if(props.project && props.project.tiedostot.some(t=> file_uuids.includes(t.uuid))){

                 return {...state, ...props}
             }
         }
         return null
      }

     // onchnage handler
     inputChangeHandler = e => {
         const { uploadFile, tags } = this.props
         
         e.target.files && Array.from(e.target.files).forEach(file => {
            const uuid = uid()
            this.setState({ file });   
            
            const tiedostot = [...this.state.project.tiedostot, {uuid,'nimi':file.name + ' (siirtyy...)','mimetype':'' ,tags}]
            const project = {...this.state.project, tiedostot}
            this.setState({project})
            
            uploadFile({
                uuid: uuid,
                ref_table: 'projekti',
                ref_key: project.uuid,             
                file_data: file,
                name:file.name,
                tags: tags
            })}
        )
         
     };
    


    fileIcon = name=>{
        const last = name.slice(-3)
        if( last === 'pdf') return pdficon
        if( last === 'doc' ||
            last === 'ocx') return docicon;
        if( last === 'xls' ||
            last === 'lsx') return xlsicon;
        return genicon; 
    }
  poista = (uuid)=>{
      if( ! window.confirm('Haluatko varmasti poistaa tiedoston?' ) ) return;
      this.props.updateRow({
        info: 'tuhoakuva',
        className: "images",
        action: "delete",
        data: { uuid }
      });      
      const tiedostot = this.state.project.tiedostot.filter(a=>a.uuid!==uuid)
      const project = {...this.state.project, tiedostot}
      this.setState({project})
  }
  render() {
    const{progress, tags, notags,jwt}=this.props
    const{project} = this.state
    const istyle = {width:'16px', height:'16px', margin:'2px 2px -1px 0 '}
    let myfiles = project && project.tiedostot
    if( myfiles && tags ){
        myfiles = myfiles.filter(    a=>(a.tags && a.tags.includes(tags))  )
    }
    if( myfiles && notags){
        const notaglist = notags.split(/(?=#)/)
        myfiles = myfiles.filter( a=>( !a.tags || ! notaglist.some(t=>a.tags.includes(t)) ))
    }

    return <div style={{ textAlign: "left", marginLeft: "20px" }} >
        {myfiles && myfiles.map(t=>{
            return(
            <div key={t.uuid}>
                <a href={`${url}file?jwt=${jwt}&uuid=${t.uuid}`} download >
                <img alt="" src = {this.fileIcon(t.nimi)} style={istyle} /> 
                {t.nimi} </a> 
            <Icon name="close" color='teal' title='poista' onClick={()=>this.poista(t.uuid)} />    
            </div>
            )
        })}
        <input style={{ display: "none" }} multiple="multiple" onChange={this.inputChangeHandler} ref={fileInput => (this.fileInput = fileInput)} type="file" />
        <button onClick={() => {
            this.fileInput.click();
          }} className="img__upload-btn">
          <Icon name="cloud upload" color={ "black"} title="Lisää kuva" />
        </button>
        {100 > progress && progress > 0 && <progress value={progress} max="100" />}
      </div>;
  }
}
export default Tiedostot;