import React, {Component, Fragment} from 'react';
import {Icon} from "semantic-ui-react";
import { v4 as uuidgen } from 'uuid';
//import {timestamp2iso, iso2timestamp} from '../../../Helpers'
//import Dropdown from '../DropDown'
//import Dates from '../hours/Dates'

const EDIT_INTERVAL = 1000;

class Referenssitiedot extends Component {
    propMap = {
        tausta: 'Tausta',
        tavoitteet: 'Tavoitteet',
        toteutus: 'Toteutus',
        menetelmat: 'Menetelmät',
        tulokset: 'Tulokset',
        lisatiedot: 'Lisätiedot',
        cvref: 'CV-tiivistelmä',
        laajuus: 'Laajuus',
        yhteyshenkilo: 'Yhteyshenkilö',
        kesto: 'Kesto',
        avainsanat: 'Avainsanat'
    }

    placeHolders = {
        tausta: 'Johdanto. Esim. "SAKARI-hanke oli sosiaali- ja terveysministeriön (STM) rahoittama ja Espoon, Porvoon ja Etelä-Karjalan sosiaali- ja terveyspiirin (Eksote) kotihoitoyksikköjen yhteinen kehityshanke, jossa tuettiin uuden vanhuspalvelulain toimeenpanoa."',
        tavoitteet: 'Niin konkreettinen kuin mahdollista. Esim. "Päätavoitteena oli kehittää ja jalkauttaa kotihoitoon toimintamalli, joka mahdollistaisi henkilöstöresurssien joustavan, asiakaslähtöisen ja täysimääräisen hyödyntämisen (20 § ja 21 §)"',
        toteutus: 'Selkä kuvaus projektin rakenteesta. Projektin vaiheistus, osakokonaisuudet, esim. Tilanneanalyysi & kehitystoimenpiteiden määrittäminen/implementointi/arviointi ja miten nämä toteutettiin.',
        menetelmat: 'Tämän  voi kuvata osana toteutusta. KAIKKI käytetyt menetelmät listattava.',
        tulokset: 'Millaisiin tuloksiin projektissa päädyttiin ja millaisia konkreettisia vaikutuksia projektilla oli, mahdollisesti eri tasoilla/osatuloksina jaoteltuna. Esim. implementoinnissa pisimmälle edenneessä Porvoon kotihoidossa:  välittömän työajan osuus nousi 9%-yks. reilussa vuodessa ja 6%-yks. kymmenessä kuukaudessa, ja samalla sijaistyövoiman käyttö väheni 37% jne. Toimintamallin tueksi laadittiin käsikirja "XX" ja se on ladattavissa osoitteessaa https//xxxxx.',
        lisatiedot: '',
        cvref: 'Tähän lyhyempi CV:issä käytettävä versio referenssistä. Ei bulletteja, vaan yhtenäistä tekstiä, josta saa helposti kuvan projektista, sen tavoitteista ja tuloksista. Tässä ei pitäisi olla mitään, mitä referenssi-versiossa ei ole. Muista käyttää otsikkona täsmälleen samaa kuin referenssissä. Yhteyshenkilö(t): nimi (Asiakas), nimi (Asiakas) '
    }

    state = {
        editing: false,
        project: this.props.project,
        updateProjectData: this.props.updateProjectData,
        updateRow: this.props.updateRow,
        ...this.props
    }

    static getDerivedStateFromProps(props, state) {
        if( !props.shortlisted ){ return {...state,...props, editing:false}; }
        if (props.project_uid !== state.project_uid) {
            return {...state, ...props, editing: false};
        }
        if (!state.editing || props.project.uuid !== state.project.uuid) {
            return {...state, ...props};
        }
        return state;
    }

    rowstart = false
    newRow = () => {
        this.rowstart = false
    }
    textArea = (name, value) => {
        const {editing, project} = this.state
        const h4style = {marginBottom: '5px', marginTop: '25px'}
        return editing ?
            <div key={name}>
                <h4 style={h4style}>{this.propMap[name]}</h4>
                <textarea placeholder={this.placeHolders[name]}
                          style={{margiLeft: '20px', width: '90%', height: '200px'}}
                          onChange={e => (this.keyedRefChange(project.uuid, name, e.target.value))}
                          value={value || ''}/>
            </div>
            : <div key={name}>
                <h4 style={h4style}>{this.propMap[name]}</h4>
                <div style={{
                    whiteSpace: 'pre-wrap',
                    marginBottom: '40px',
                    marginLeft: '20px',
                    marginRight: '40px'
                }}>{value}</div>
            </div>
    }
    textField = (name, value) => {
        const {editing, project} = this.state
        const h4style = {marginBottom: '5px', marginTop: '25px'}
        return editing ?
            <div key={name}>
                <h4 style={{...h4style, display: 'inline-block'}}>{this.propMap[name]} </h4>&nbsp;
                <input type="text" placeholder={this.placeHolders[name]} style={{width: '100px'}}
                       onChange={e => (this.keyedRefChange(project.uuid, name, e.target.value))} value={value || ''}/>
            </div>
            : <div key={name}>
                <h4 style={{...h4style, display: 'inline-block'}}>{this.propMap[name]}:</h4>&nbsp; {value}</div>

    }
    keyField = (name, value) => {
        const {editing, project} = this.state
        const h4style = {marginBottom: '5px', marginTop: '25px'}
        return editing ?
            <div key={name}>
                <h4 style={{...h4style, display: 'inline-block'}}>{this.propMap[name]} </h4>&nbsp;
                <input type="text" placeholder={this.placeHolders[name]} style={{width: '200px'}}
                       onChange={e => (this.keyedRefChange(project.uuid, name, e.target.value))} value={value || ''}/>
                &nbsp; <select onChange={e => {
                this.keyedRefChange(project.uuid, name, `${value} ${e.target.value}`)
            }}>
                {this.props.dropDownData && this.props.dropDownData.ref_avainsanat.map(a => {
                    const m = a.match(/^==(.*)$/)
                    if (m) {
                        return <Fragment>
                            <option value="" disabled="disabled">––––––––––</option>
                            <option value="" disabled="disabled">{m[1]}</option>
                            <option value="" disabled="disabled">––––––––––</option>
                        </Fragment>
                    } else {
                        return <option key={a} value={a}>{a}</option>
                    }
                })}
            </select>
            </div>
            : <div key={name}>
                <h4 style={{...h4style, display: 'inline-block'}}>{this.propMap[name]}:</h4>&nbsp; {value}</div>
    }

    editTimers = {}
    sendEdit = (project) => {
        //      const proj = this.filterRefs( project ) // tuhotaan turhat
        this.props.updateRow({
            info: 'projekti.tiedot',
            className: 'projekti',
            action: 'upsert',
            data: project
        })
    }
    sendRefEdit = (referenssi) => {
        this.props.updateRow({
            info: 'projekti.referenssi',
            className: 'projekti_referenssi',
            action: 'upsert',
            data: referenssi
        })
    }

    keyedChange = (project_uuid, key, value) => {
        const {project, updateProjectData} = this.state
        const newProject = {...project, [key]: value}
        updateProjectData(key, value)
        clearTimeout(this.editTimers[project_uuid])
        this.editTimers[project_uuid] = setTimeout(() => this.sendEdit(newProject), EDIT_INTERVAL)
        this.setState({project: newProject})
    }
    keyedRefChange = (project_uuid, key, value) => {
        const {project, updateProjectData} = this.state
        const uusiReferenssi = {...project.referenssi, [key]: value}
        if (!uusiReferenssi['uuid']) {
            uusiReferenssi['uuid'] = uuidgen()
        }
        const newProject = {...project, referenssi: uusiReferenssi}
        updateProjectData("referenssi", uusiReferenssi)
        clearTimeout(this.editTimers["ref-" + project_uuid])
        this.editTimers["ref-" + project_uuid] = setTimeout(() => this.sendRefEdit(uusiReferenssi), EDIT_INTERVAL)
        this.setState({project: newProject})
    }


    onChangeHandler = (e) => {
        const {project, updateProjectData} = this.state
        e.preventDefault()
        let {name, value} = e.target
        if (this.propTransform[name]) value = this.propTransform[name].back(value)
        project[name] = value
        updateProjectData(name, value)
        clearTimeout(this.editTimers[project.uuid])
        this.editTimers[project.uuid] = setTimeout(() => this.sendEdit(project), EDIT_INTERVAL)
        this.setState({project})
    }

    toggleEditing = () => {
        if (this.state.editing) {
            this.setState({editing: false})
        } else {
            this.setState({editing: true})
        }
    }
    kopioiProjektilta = () => {
        // kysytään projekti
        const projekti_uid = prompt("Projektinumero, jolta kopioidaan:","")
        // pyydetään axiolta muutosta
        this.props.copyProjectRef(projekti_uid, this.props.project.uid)
    }
    ref_keys = ['tausta', 'tavoitteet', 'toteutus', 'menetelmat', 'tulokset', 'lisatiedot', 'yhteyshenkilo', 'cvref'];

    render() {
        const {editing, project} = this.state
        //     const {henkilo_uid} = this.props
        //const {dropDownData} = this.props
        if (!project) return null;
        if (!project.referenssi) project.referenssi = {projekti_uid: project.uid}

        return <div style={{boxSizing: 'border-box'}}>
            {project && <div style={{textAlign: "left", marginLeft: "20px"}}>
                <span className="hoursIcon" onClick={() => this.toggleEditing()}>
                  <Icon color={editing ? "red" : "black"} size="large" name="edit"
                        title={!editing ? "Muokkaa" : "Lopeta muokkaus"}/>
                </span>
                <div style={{textAlign: "left", marginTop: "20px"}}>
                    {['avainsanat'].map(a => this.keyField(a, project.referenssi[a] || ""))}
                    {this.ref_keys.map(a => this.textArea(a, project.referenssi[a]))}
                    {['laajuus', 'kesto'].map(a => this.textField(a, project.referenssi[a]))}
                    <button onClick={this.kopioiProjektilta} value={"Kopioi projektilta…"} >Kopioi projektilta…</button>
                </div>
            </div>}
        </div>
    }

}


export default Referenssitiedot
