import React, { Component } from "react";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";

import Main from "./containers/Main";

class App extends Component {
  render() {
    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <div className="App">
            <Main />
          </div>
        </MsalAuthenticationTemplate>
    );
  }
}

export default App;
