import * as actionType from "./actionTypes";


// actions responsble for updating the redux store starts here 
export const storeUser = data => {
  return {
    type: actionType.STORE_USER,
    payload: data
  };
};

export const getSelectedMonth = (month, statistic,pseudo_uid) => {
  return { type: actionType.SELECTED_MONTH, month, statistic, pseudo_uid }; //month string
       };

export const storeToken= jwt=>{
  return {type:actionType.STORE_TOKEN,
          payload:jwt}
}

export const storeHours = hours => {
  return {
    type: actionType.STORE_HOURS,
    payload: hours
  };
};
export const storePerson = person =>{
  return{
    type: actionType.STORE_PERSON,
    payload: person
  }
}
export const storeDropDown = dropdowndata =>{
  return{
    type: actionType.STORE_DROPDOWN,
    payload: dropdowndata
  }
}
export const storeLookup = lookupdata =>{
  return{
    type: actionType.STORE_LOOKUP,
    payload: lookupdata
  }
}
export const storeSearch = searchdata =>{
  return{
    type: actionType.STORE_SEARCH,
    payload: searchdata
  }
}

export const storeFileSearch = searchdata =>{
  return{
    type: actionType.STORE_FILE_SEARCH,
    payload: searchdata
  }
}

export const storeFiles = files => {
  return { type: actionType.STORE_FILE_LIST,  files };
};

export const storeProjects = projects => {
  return { type: actionType.STORE_PROJECT_LIST,  projects };
};
export const storeExpenses = expenses => {
  return {
    type: actionType.STORE_EXPENSES,
    payload: expenses
  };
};
export const storeMonthLyHours = monthyHours => {
  //stores month aggregated hours
  return { type: actionType.STORE_MONTHLY_HOURS, payload: monthyHours };
};
export const storeCurrentProject = project => {
  return { type: actionType.STORE_CURRENT_PROJETCS, project };
};

export const displayProject = () => {
        return { type: actionType.DISPLAY_PROJECT };
};
export const displayHours = () => {
        return { type: actionType.DISPLAY_HOURS };
};

export const updateHours = (updatedHours) => {
  return { type: actionType.UPDATE_HOURS, hours:updatedHours };
};
export const updateExpense = (updated) => {

  return { type: actionType.UPDATE_EXPENSES, expenses: updated };
};
export const updateProjectData = (name,updated) => {
  return { type: actionType.UPDATE_PROJECT_DATA,name, projectData: updated };
};
export const updateRow = (row) => {
  return { type: actionType.UPDATE_ROW, row};
};
export const teeKysely = data => {
  return {type:actionType.TEE_KYSELY, data}
}
export const uploadImg= (img) => {
  return { type: actionType.UPLOAD_IMAGE, img };
}
export const uploadXLS= (payload) => {
  return { type: actionType.UPLOAD_XLS, payload };

}
export const uploadFile= (file) => {
  return { type: actionType.UPLOAD_FILE, file };

}
export const uploadProgress= (progress) => {
  console.log(progress)
  return { type: actionType.UPDATE_UPLOAD_PROGRESS, progress };

}
export const sendInvoice = (invoices) => {
  return { type: actionType.SEND_INVOICE, invoices };
}
export const sendClientInvoice = (invoice)=>{
  return {type: actionType.SEND_CLIENT_INVOICE, invoice}
}


export const searchPerson =(search) => {
  return { type: actionType.SEARCH_PERSON, search };
}
export const searchFile =(search) => {
  return { type: actionType.SEARCH_FILE, search };
}

export const addNewProject = project => {
  return { type: actionType.ADD_PROJECT, project };
};

export const createUserUuid = uuid => {
  return { type: actionType.CREATE_USER_UUID, uuid }
}

export const addCounter = () => {
  return { type: actionType.ADD_COUNTER}
}

export const displayExpenseModal = () => {
  return { type: actionType.SHOW_EXPENSE_MODAL }
}

export const cancelExpenseModal = () => {
  return { type: actionType.CANCEL_EXPENSE_MODAL }
}

export const displayClientInvoiceModal = (invoice) => {
  return { type: actionType.SHOW_CLIENT_INVOICE_MODAL,invoice }
}

export const cancelClientInvoiceModal = () => {
  return { type: actionType.CANCEL_CLIENT_INVOICE_MODAL }
}

export const displayRefSearchModal = () => {
  return { type: actionType.SHOW_REF_SEARCH_MODAL }
}
export const displayFileSearchModal = () => {
  return { type: actionType.SHOW_FILE_SEARCH_MODAL }
}

export const cancelRefSearchModal = () => {
  return { type: actionType.CANCEL_REF_SEARCH_MODAL }
}

export const cancelFileSearchModal = () => {
  return { type: actionType.CANCEL_FILE_SEARCH_MODAL }
}

export const copyProjectRef = (project_uid, target_uid) => {
  return { type: actionType.COPY_REF, project_uid, target_uid};
};


// actions responsible for updating the redux store ends here 


// actions responsible for initializing  saga Functions starts here 

export const initstoreHours = month => {
        return {
                type: actionType.INIT_STORE_HOURS,
                month: month
        };
};

export const initGetExpenses = month => {
        return { type: actionType.INIT_STORE_EXPENSES, month: month };
};
export const initGetMonthAggregated = (data)=> {
  
        return { type: actionType.INIT_GET_ALLMONTHS, data:data };
};
export const initGetProjects = ()=> {
        return { type: actionType.INIT_STORE_PROJECT_LIST};
};
export const initGetCurrentProject = (project) => {
  
  return { type: actionType.INIT_HANDLE_PROJECT_SECTECTION, project };
};
export const initStoreShortlisted =( pseudo_uid,pseudo_person) => {
  return { type: actionType.INIT_STORE_SHORTLISTED_MONTHLYHOURS, pseudo_uid, pseudo_person};
};

export const initData = (data)=>{
  return {type: actionType.INIT_DATA, data:data}
}
