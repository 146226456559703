import React, { Component } from 'react'
import { Checkbox,Button } from "semantic-ui-react";
import {fix2} from '../../Helpers'

export class ExpensesInvoices extends Component {
state={
    expenses:[]

}
componentDidMount(){
    this.setState({
        expenses: this.props.expenses.filter(el => el.henkilo_laskuttanut === false).map(item => ({...item, shouldInvoice:true}))
        })
}

toggleCheckbox=(uuid)=>{
  const index = this.state.expenses.findIndex(item=>item.uuid===uuid)
  const expenses = [...this.state.expenses]
  expenses[index].shouldInvoice = !this.state.expenses[index].shouldInvoice;  
  this.setState({expenses:expenses})

}


render() {
  const {expenses}=this.state
  const {lookupData} = this.props
  const invoice=expenses.filter(el=>el.shouldInvoice)
     const sum = expenses.reduce((a,c)=>a+(c.shouldInvoice?c.verollinenkorvausyhteensa:0), 0)
    return <div className="backdrop">
        <div style={{display:'block', textAlign:'center', marginLeft:'auto', marginRight:'auto'}}>
        <div className="expense-modal">
        <div style={{display:'inline-block', marginLeft:'auto',marginRight:'auto'}}>
          <h2>Tee kululasku</h2>
          <p>Valitse laskutettavat kulut alla olevasta listasta</p>
          <div className="exp-invoice">
            <div style={{textAlign:'left', overflowY:'auto', maxHeight:'300px'}}>
            {expenses.map(el => {
              return <div key={el.uuid}>                  
                  <Checkbox checked={el.shouldInvoice} onChange={() => this.toggleCheckbox(el.uuid)} style={{display:'inline-block', width:'30px'}}/> 
                  <span  style={{display:'inline-block',width:'80px'}}>{el.pvm}</span>{" "}
                  <span  style={{display:'inline-block',width:'220px'}}>{el.selite} </span>{" "}
                  <span  style={{display:'inline-block',width:'150px'}}>{el.asiakasorganisaatio} </span>{" "}
                  <span  style={{display:'inline-block',width:'40px'}} title={lookupData.projects[el.projekti_uid]}>{el.projekti_uid} </span>{" "}
                  <span  style={{display:'inline-block',width:'80px', textAlign:'right'}}>{fix2(el.verollinenkorvausyhteensa)} </span>
                  <br/>
                </div>;
            })}
            </div>
            <div style={{fontWeight:600, textAlign:'left'}}>
              <span style={{display:'inline-block', width:'530px'}}>Yhteensä</span> 
              <span  style={{display:'inline-block',width:'80px',textAlign:'right'}}>{fix2(sum)}</span>
            </div>
            
            <div style={{marginTop:'30px'}}>
              <Button content="Peruuta" onClick={this.props.cancel} negative/>
              <Button content="Tee lasku" positive onClick={(e)=>{
                const t = e.currentTarget
                t.disabled = true                
                this.props.sendInvoice(invoice)
                setTimeout(()=>{t.disabled = false}, 15000)
              }} />
            </div>

          </div>
        </div>
        </div>
        </div>
      </div>
  }
}

export default ExpensesInvoices
