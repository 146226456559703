import React from 'react'
import MonthlyRow  from "../detailViews/hours/MonthlyRow.js"


const MonthCard = ({ el, handleSelection, selectedMonth, pseudo_uid }) => {
  return (
      <div className="monthCard2">
  
      <MonthlyRow 
        onClick={() => { handleSelection(el.month, el, pseudo_uid) }}
          monthInfo={ {...el , active: selectedMonth===el.month} }
          
      />
    </div>
  )
}

export default MonthCard
