import React, { Component } from 'react'
import { Checkbox,Button } from "semantic-ui-react";
import {fix2, currentDate} from '../../Helpers'
import Dropdown from '../detailViews/DropDown'
import { Icon } from "semantic-ui-react";
import { v4 as uuidgen } from 'uuid';
import * as actions from "../../store/actions/actionCreators";
import { connect } from "react-redux";




export class ClientInvoices extends Component {
state={
    liitenimi: [],
    liite: [],
    ...this.props.invoice
}

componentDidMount(){
    this.setState({
        ...this.props.invoice,
        ennuste: {...this.props.invoice.ennuste, pvm:currentDate}
    })
}
checkAllExpenses = ()=>{
  const {expenses} = this.state
  const modified = expenses.map(item =>( {...item, shouldInvoice:true} ) )
  this.setState({...this.state, expenses:modified})
}
toggleCheckbox=(uuid)=>{
    const {expenses} = this.state
    const modified = expenses.map(item => item.uuid===uuid ? {...item, shouldInvoice:!item.shouldInvoice} : item)
    this.setState({...this.state, expenses:modified})
}
paivitaSelite=(event)=>{
    const selite = event.target.value
    this.setState( {...this.state, selite})
}
keyedChange=(uuid,key,value)=>{
    this.setState({...this.state, [key]:value})
}

inputChangeHandler = e => {
    const { uploadFile } = this.props
    const {project} = this.state

    e.target.files && Array.from(e.target.files).forEach(file => {
       const uuid = uuidgen()
       this.setState({ liite:[...this.state.liite, uuid],liitenimi:[...this.state.liitenimi,file.name] });
     
       uploadFile({
           uuid: uuid,
           ref_table: 'projekti',
           ref_key: project.uuid,             
           file_data: file,
           name:file.name
       })}
   )
};



render() {
    const {asiakas,ennuste,expenses,selite,yhtio,pyynnontekija} = this.state
    const {lookupData, dropDown} = this.props
    const sum = expenses.reduce((a,c)=>a+(c.shouldInvoice?c.verotonkorvausyhteensa:0), 0)


    return <div className="backdrop">
        <div style={{display:'block', textAlign:'center', marginLeft:'auto', marginRight:'auto'}}>
        <div className="expense-modal">
        <div style={{display:'inline-block', marginLeft:'auto',marginRight:'auto', overflowY:'auto', maxHeight:'90vh'}}>
          <h2>Tee asiakaslasku</h2>
          <h3 style={{textAlign:'left'}}>Asiakas</h3>
            <div style={{textAlign:'left',paddingLeft:'30px'}}>{asiakas.nimi}</div>
            <div style={{textAlign:'left',paddingLeft:'30px'}}>{asiakas.yhteyshenkilo}</div>
          <h3 style={{textAlign:'left'}}>Laskun tiedot</h3>
          <div style={{textAlign:'left',paddingLeft:'30px'}}>
          <span style={{display:'inline-block', width:'130px'}}> Projekti:</span>
          <span style={{display:'inline-block', left:'20px'}}> {lookupData.projects[ennuste.projekti_uid]} ({ennuste.projekti_uid})</span>   
          </div>
          <div style={{textAlign:'left',paddingLeft:'30px'}}>
          <span style={{display:'inline-block', width:'130px'}}> Päivämäärä:</span>
          <span style={{display:'inline-block', left:'20px'}}> {ennuste.pvm}</span>   
          </div>
          <div style={{textAlign:'left',paddingLeft:'30px'}}>
          <span style={{display:'inline-block', width:'130px'}}> Maksuehto:</span>
          <span style={{display:'inline-block', left:'20px'}}> {asiakas.maksuehto} pv.</span>   
          </div>
          <div style={{textAlign:'left',paddingLeft:'30px'}}>
          <span style={{display:'inline-block', width:'130px'}}> Viite:</span>
          <span style={{display:'inline-block', left:'20px'}}> {asiakas.viite}</span>   
          </div>

          <div style={{textAlign:'left',paddingLeft:'30px'}}>
          <span style={{display:'inline-block', width:'130px'}}> Laskuttava yritys:</span>
          <span style={{display:'inline-block', left:'20px'}}> <Dropdown  
          valuemap = {dropDown.yhtio}  
          selected = {yhtio} 
          keyedChange={this.keyedChange} 
          uuid="-" 
          name={yhtio} 
          placeholder={"Yhtiö"} /> </span>   
          </div>
          
          <div style={{textAlign:'left',paddingLeft:'30px'}}>
          <span style={{display:'inline-block', width:'130px'}}> Pyynnön tekijä:</span>
          <span style={{display:'inline-block', left:'20px'}}> <Dropdown  
          valuemap = {dropDown.persons}  
          selected = {pyynnontekija} 
          keyedChange={this.keyedChange} 
          uuid="-" 
          name="pyynnontekija" 
           /> </span>   
          </div>
          
          <div style={{textAlign:'left',paddingLeft:'30px'}}>
          <span style={{display:'inline-block', width:'130px'}}> Liite:</span>
          <span style={{display:'inline-block', left:'20px'}}> 
              <input accept='.pdf' style={{ display: "none" }} multiple="multiple" onChange={this.inputChangeHandler} ref={fileInput => (this.fileInput = fileInput)} type="file" />
              <button onClick={() => {
                  this.fileInput.click();
                }} className="img__upload-btn">
                <Icon name="cloud upload" color={ "black"} title="Lisää kuva" />
            </button> {(this.state.liitenimi || []).join(", ")}
        </span>
          </div>
  

          <div style={{textAlign:'left',paddingLeft:'30px'}}>
            <span style={{display:'inline-block', width:'130px'}}> Viesti asiakkaalle:</span>
            <span > <input style={{display:'inline-block',width:'480px',right:'10px'}} type="text" name="Selite" value={selite} onChange={this.paivitaSelite}></input> </span>   
            </div>
            <div style={{textAlign:'left',paddingLeft:'30px'}}>
              <span style={{display:'inline-block', width:'530px'}}>Työsumma:</span> 
              <span  style={{display:'inline-block',width:'80px',textAlign:'right'}}>{fix2(ennuste.tyosumma)}</span>
            </div>
          <h3 style={{textAlign:'left'}}>Kululaskut</h3>
          <div className="exp-invoice">
            <div style={{textAlign:'left'}}>
            <div><button onClick={()=>this.checkAllExpenses()}>Valitse kaikki</button></div>
            {expenses.map(el => {
              return <div key={el.uuid}>                  
                  <Checkbox checked={el.shouldInvoice} onChange={() => this.toggleCheckbox(el.uuid)} style={{display:'inline-block', width:'30px'}}/> 
                  <span  style={{display:'inline-block',width:'80px'}}>{el.pvm}</span>{" "}
                  <span  style={{display:'inline-block',width:'220px'}}>{el.selite} </span>{" "}
                  <span  style={{display:'inline-block',width:'150px'}}>{el.asiakasorganisaatio} </span>{" "}
                  <span  style={{display:'inline-block',width:'40px'}} title={lookupData.projects[el.projekti_uid]}>{el.projekti_uid} </span>{" "}
                  <span  style={{display:'inline-block',width:'80px', textAlign:'right'}}>{fix2(el.verotonkorvausyhteensa)} </span>
                  <br/>
                </div>;
            })}
            </div>
            <div style={{fontWeight:600, textAlign:'left'}}>
              <span style={{display:'inline-block', width:'530px'}}>Kulut yhteensä</span> 
              <span  style={{display:'inline-block',width:'80px',textAlign:'right'}}>{fix2(sum)}</span>
            </div>
            <div style={{fontWeight:600, textAlign:'left', borderTop:'1px double gray', marginTop:'10px'}}>
              <span style={{display:'inline-block', width:'530px'}}>Yhteensä</span> 
              <span  style={{display:'inline-block',width:'80px',textAlign:'right'}}>{fix2(sum+ennuste.tyosumma)}</span>
            </div>
            
            <div style={{marginTop:'30px'}}>
              <Button content="Peruuta" onClick={this.props.cancel} negative/>
              <Button content="Tee lasku" positive onClick={(e)=>{
                const t = e.currentTarget
                t.disabled = true                
                this.props.sendClientInvoice({...this.state}) 
                setTimeout(()=>{t.disabled = false}, 15000)
              }} />

            </div>

          </div>
        </div>
        </div>
        </div>
      </div>
  }
}

const mapStateToProps = state => {
    return {
      data: state.loggedInuser.currentProject,
      project_uid:state.loggedInuser.project_uid
    };
  };
  export default connect(
    mapStateToProps,
    actions
  )(ClientInvoices);
  