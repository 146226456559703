import React from 'react'
import {  Icon } from "semantic-ui-react";

const DuplicateExp = ({click,uuid}) => {
  return (
    <span  style={{cursor:'pointer'}} onClick={()=>{click(uuid)}} >
      <Icon name='copy' title='Kopioi' />
    </span>
  )
}

export default DuplicateExp
