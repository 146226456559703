import React, { Component } from "react";

const WAIT_INTERVAL = 1200;
const ENTER_KEY = 13;

const zeroPad = (val,len=2)=>{
  const finalLen = Math.max(len,(val+"").length)
  return (Array(len+1).join(0) + val).slice(-finalLen)
}
const computeMinutes = (h,m) => {
  let hrs = Number(h)
  let mins = Number(m)
  while(mins > 60){
    hrs += 1
    mins -= 60
  }
  return fromComponents(hrs,mins)
}
const fromComponents = (hrs,mins) => {
  return `${zeroPad(hrs)}:${zeroPad(mins)}`
}

export const parseTime = (value)=>{
  if (value.includes(":")) {
    let newVal = value
      .split(":")
      .map( el => el.replace(/[^0-9]/g, ""))
    if( Number(newVal[1]) < 60) {
      return fromComponents(...newVal)
    }else if (Number(newVal[1]) > 60) {
      return computeMinutes(...newVal)
    }else{
      return null
    }
  } else {
    let newVal = value.replace(/[^0-9.]/g, "");
    if (newVal.length === 0) {
      return null
    }else if (newVal.length < 3) {
      return fromComponents(newVal, 0)
    } else{
      return computeMinutes( newVal.slice(0,-2) , newVal.slice(-2) )
    }     
  }
}

class TimePicker extends Component {
  constructor(props){
    super(props)
    this.state = {...this.props, valid:true, value:this.props.el[this.props.name]}
  }

  timer = null;

  handleChange = e => {
    clearTimeout(this.timer);

    this.setState({ value: e.target.value, valid: false });

    this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
  };

  handleKeyDown = e => {
    if (e.keyCode === ENTER_KEY) {
      this.triggerChange();
    }
  };

  triggerChange = () => {
    const { value } = this.state;
    const {el, name, keyedChange} = this.props
    const timeValue = parseTime(value)
    if(timeValue == null){
      this.setState({value:timeValue, valid:false})
    }else{
      this.setState({value:timeValue, valid:true})
      keyedChange(el.uuid, name, timeValue)
    }
  };

  render() {
    const { value, valid } = this.state;
    const {style} = this.props
    const myStyle = { color: `${valid ? 'black' : 'red'}`,marginLeft:'3px' ,padding:'2px', textAlign:'right'}
    return (
      <input
        style={{...myStyle, ...style}}
        type={"search"}
        placeholder={"hh:mm"}
        value={value}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
      />
    );
  }
}

export default TimePicker;
