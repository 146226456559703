import React, { Component } from "react";
import {cwarn} from '../../../Warnings.js'
import {score,fix1} from '../../../Helpers'

class ProjectSummaryRow extends Component{

    className=(e)=>{
        return e.active ? "activeMonthRow":"normalMonthRow"
    }
    headerClassName=(e)=>{
        return e.paattynyt ? "endedProjectHeader":"projectHeader"
    }    
    render(){
        const {project,onClick} = this.props
        
        
        return (
            <div
             ref='rowdiv'
             onClick = { onClick }
             className = {this.className(project)}
            >
            {/* project.tuotevaiprojekti === 'projekti' && ewarnp(project)  Näissä tarvitaan asiakastietoja, joita ei ole summaryissä*/}
            {/* project.tuotevaiprojekti === 'projekti' && lwarnp(project)  Näissä tarvitaan asiakastietoja, joita ei ole summaryissä*/}
            {project.tuotevaiprojekti === 'projekti' && cwarn(project)}
            <div className={this.headerClassName(project)}>{project.uid} {project.nimi}</div>
            <span className="projectDetail"> 
            {project.laajuus > 0 &&
                <span title='Laskutus / Laajuus'>{Math.floor(100*(project.laskutustoteuma || 0)/project.laajuus)} % / {project.laajuus} €</span>
            }
            {/*{project.laskutustoteuma || 0} / {project.laajuus ||0} € */}
            {project.liiketoimintaAlue ? <span title='Liiketoiminta-alue'> {project.liiketoimintaAlue} </span> : <span/> }
            {project.liiketoimintaAlue2 ? <span title='Toissijainen liiketoiminta-alue'> {project.liiketoimintaAlue2} </span> : <span/> }
            {project.tarjousHyvaksytty  ? <span title='Tarjous hyväksytty'> { new Date(project.tarjousHyvaksytty).toISOString().slice(0,10) } </span> : <span />}          
            {project.paattynyt  ? <span title='Päättynyt'> – { new Date(project.paattynyt).toISOString().slice(0,10) } </span> : <span />}
            {project.kysely && 
                <span title={project.kysely.avoin}>
                    <span title='Suosittelu' style={{margin:'1px'}}>{score(fix1(project.kysely.q0))}</span>
                    <span title='Korkeatasoisuus'  style={{margin:'1px'}}>{score(fix1(project.kysely.q1))}</span>
                    <span title='Jatkoyhteistyö'  style={{margin:'1px'}}>{score(fix1(project.kysely.q2))}</span>                    
                    &nbsp; &nbsp; 
                </span>
            }
            </span>
            </div>
        )
    }
}

export default ProjectSummaryRow