import React, {Component} from 'react';
import {Icon} from "semantic-ui-react";
import {timestamp2iso, iso2timestamp, fixnumber, fix0} from '../../../Helpers'
import Dropdown from '../DropDown'
import Dates from '../hours/Dates'
import {score} from '../../../Helpers'

const $POISTA_VALINTA = "Poista valinta"
const EDIT_INTERVAL = 1000;

class Tiedot extends Component {
    propMap = {
        uid: 'Projektinumero',
        nimi: 'Nimi',
        /*
                liiketoimintaalue:'Liiketoiminta-alue',
                liiketoimintaaluetoissijainen: 'Toissijainen liiketoiminta-alue',
                */
        liiketoimintaaluepainotus: 'Painotus',
        paallikko: 'Projektipäällikkö',
        asiakasvastuullinenmyyja: 'Asiakasvastuullinen myyjä',
        suunnitellutbkulut: 'B-kulut',
        jatkuva: 'Jatkuva',
        kiintea: 'Kiinteä',
        yhtio: 'Yhtiö',
        kansainvalisyysaste: 'Kansainvälisyysaste',
        vaikuttavuusperusteinen: 'Vaikuttavuusperusteinen',
        loppuraportintarkastaja: 'Loppuraportintarkastaja',
        paattynyt: 'Päättynyt',
        referenssitoimitettu: 'Referenssi toimitettu',
        tyytyvaisyyskyselylahetetty: 'Tyytyväisyyskysely lähetetty',
        datatuhottava: 'Data tuhottava',
        datatuhottu: 'Data tuhottu',
        loppudokumentittoimitettu: 'Loppudokumentti toimitettu',
        palautteetannettu: 'Palautteet annettu',
        tyyppi: 'Tyyppi',
        raportointihierarkia: 'Raportointialue',
        tavoitekannattavuus: 'Tavoitekannattavuus',
        lapilaskutus: 'Läpilaskutus',
        kickoff: "Kick-off",
        pvkoulutus:'PV koulutus'
    }
    hlouid2namedhlo = (henkilo_uid) => {
        const {lookupData} = this.props
        const {persons} = lookupData
        const p = persons.find(a => a.uid === henkilo_uid)
        if (null == p) return ""
        const {uid, nimi} = p
        return `${uid}: ${nimi}`
    }
    painotus2suhde = (pros) => {
        return `${100 - pros}:${pros}`
    }
    viewTansform = {
        asiakasvastuullinenmyyja: this.hlouid2namedhlo,
        paallikko: this.hlouid2namedhlo,
        liiketoimintaaluepainotus: this.painotus2suhde
    }
    propTransform = {
        kiintea: {
            forward: (v) => {
                return v === 'Ei' ? 'Ei-kiinteä' : 'kiinteä'
            },
            back: (v) => {
                return v === 'Ei-kiinteä' ? 'Ei' : 'Kyllä'
            }
        },
        paattynyt: {
            forward: timestamp2iso,
            back: iso2timestamp
        },
        referenssitoimitettu: {
            forward: timestamp2iso,
            back: iso2timestamp
        },
        tyytyvaisyyskyselylahetetty: {
            forward: timestamp2iso,
            back: iso2timestamp
        },
        tavoitekannattavuus: {
            forward:(v)=>{ return fix0(v * 100.0)},
            back:   (v)=>{ return v / 100.0 }
        }
    }

    state = {
        editing: false,
        tyytyvaisyyskysely_tyonalla: false,
        project: this.props.project,
        updateProjectData: this.props.updateProjectData,
        updateRow: this.props.updateRow,
        ...this.props
    }

    static getDerivedStateFromProps(props, state) {
        if (!props.shortlisted) {
            return {...state, ...props, editing: false}
        }

        if (props.project_uid !== state.project_uid) {
            return {...state, ...props, editing: false};
        }
        if (!state.editing || props.project.uuid !== state.project.uuid) {
            return {...state, ...props};
        }
        return state;
    }

    rowstart = false
    newRow = () => {
        this.rowstart = false
    }
    label4title = {
        "salainen": "Salainen",
        "poikkeava_paivahinta": "Poikkeava päivähinta",
    }
    checkbox = title =>{
        const {editing, project} = this.state
        if (!editing) {
            if (project && project[title]) {
                return <span>{this.label4title[title] }</span>
            } else {
                return ""
            }
        } else {
            const ch = this.keyedChange
            return <span><input type="checkbox" checked={project && project[title]}
                                name={title}
                                onChange={e => {
                                    if (e.target.checked) {
                                        ch(project.uuid, title, 1)
                                    } else {
                                        ch(project.uuid, title, 0)
                                    }
                                }}/> { this.label4title[title] }</span>
        }
    }
    checkbox_old = title => {
        const {editing, project} = this.state
        if (!editing) {
            if (project && project.salainen) {
                return <span>Salainen</span>
            } else {
                return ""
            }
        } else {
            const ch = this.keyedChange

            return <span><input type="checkbox" checked={project && project.salainen}
                                name='salainen'
                                onChange={e => {
                                    if (e.target.checked) {
                                        ch(project.uuid, 'salainen', 1)
                                    } else {
                                        ch(project.uuid, 'salainen', 0)
                                    }
                                }}/> salainen</span>
        }
    }
    raportointielementti = () => {
        const {editing, project} = this.state
        const rhvs = project['raportointihierarkia'] // list of object kohde => osuus
        if (!editing) {
            const vmap = this.props.dropDownData.raportointihierarkia
            return <div>{rhvs.map(o => <div key={o.kohde}
                style={{paddingLeft: '10px'}}>{vmap[o.kohde]} ({Math.round(o.osuus * 100)} %)</div>)}</div>
        }
        //lisätään muut
        const muut_osuus = 1.0 - rhvs.reduce((a, c) => (a + c.osuus), 0)
        const frhvs = [...rhvs, {'kohde': 'Muut', 'osuus': muut_osuus}]
        return <span>{
            Object.entries(this.props.dropDownData.raportointihierarkia)
                .filter(([key, value]) => key.startsWith(project.yhtio))
                .map(([key, value]) => {
                    const v = Math.floor(100 * (frhvs.find(k => k.kohde === key) || {osuus: 0.0}).osuus)
                    return <div><span style={{width: '40px', display: 'inline-block', align: 'right'}}>{v} %</span>
                        <input type='range' min={0} max={100} step={10}
                               id={'slider:' + key}
                               value={v}
                               onChange={(e) => this.raportointiChange(project.uuid, key, e.target.value / 100.0)}
                        ></input> &nbsp; {value} </div>
                })
        }
            <div><span
                style={{width: '40px', display: 'inline-block', align: 'right'}}>{Math.round(muut_osuus * 100)} %</span> Muut</div>
        </span>

    }
    dropdown = values => (k, delimiter = ' ', post = '', pre = '') => {
        const {editing, project} = this.state
        let e = "" + project[k]
        if ((!e || e === '') && !editing) return <span key={k}/> // do not render null or empty items unless editing
        if (!e) e = '' // avoid null values in react components https://reactjs.org/docs/forms.html#controlled-input-null-value
        if (!this.rowstart) {
            delimiter = '';
            this.rowstart = true
        }
        const v = ((k, v) => {
            return this.viewTansform[k] ? this.viewTansform[k](v) : (v === null || v === 'null' ? 'ei asetettu' : v)
        })(k, e)
        return editing ?
            <span key={k}>{pre} <Dropdown
                valuemap={values}
                selected={e}
                keyedChange={this.keyedChange}
                uuid={project.uuid}
                name={k}
                placeholder={this.propMap[k]}/>   </span>
            : <span key={k} title={this.propMap[k] || ''}>{delimiter} {pre} {v} {post}</span>
    }

    hloDropdown = this.dropdown({$POISTA_VALINTA, ...this.props.dropDownData.persons})


    genel = (k, numeric = false, delimiter = ', ', post = '', pre = '') => {
        const {editing, project} = this.state
        let e = project[k]
        if ((!e || e === '') && !editing) return <span key={k}/> // do not render null or empty items unless editing
        if (!e) e = '' // avoid null values in react components https://reactjs.org/docs/forms.html#controlled-input-null-value
        if (!this.rowstart) {
            delimiter = '';
            this.rowstart = true
        }
        const v = (() => {
            try {
                return this.propTransform[k] ? this.propTransform[k].forward(e) : e
            } catch (err) {
                return e
            }
        })()
        const iw = (Math.max(this.propMap[k].length, (v + "").length) * 1.1) + 'ch' // input width
        const e2keyed = (e) => {
            if (e.target.validity.valid === false) return;
            e.preventDefault()
            let {name, value} = e.target
            if (numeric) value = fixnumber(value)
            if(this.propTransform[k] && this.propTransform[k].back) value = this.propTransform[k].back(value)
            this.keyedChange(this.state.project.uuid, name, value)
            if (this.propTransform[name]) value = this.propTransform[name].back(value)
            project[name] = value
        }
        return editing ?
            <span key={k}> {pre} <input style={{width: iw}} type='text'
                                        pattern={numeric ? "[0-9 ]*[.,]?[0-9]*" : null} name={k} title={this.propMap[k]}
                                        className="tightProjectInput" value={v}
                                        onChange={e2keyed} placeholder={this.propMap[k]}/> {post}&nbsp;</span>
            : <span key={k} title={this.propMap[k] || ''}>{delimiter} {pre} {v} {post}</span>

    }
    el = (k, delimiter = ', ', post = '', pre = '') => this.genel(k, false, delimiter, post, pre)
    numel = (k, delimiter = ', ', post = '', pre = '') => this.genel(k, true, delimiter, post, pre)
    editTimers = {}
    sendEdit = (project) => {
        const {kulut, laskut, tunnit, asiakkaat, tyoennuste, tyototeuma, mittarit, laskutusennusteet, ...proj} = project // tuhotaan turhat
        this.props.updateRow({
            info: 'projekti.tiedot',
            className: 'projekti',
            action: 'upsert',
            data: proj
        })
    }
    keyedChange = (project_uuid, key, value) => {
        const {project, updateProjectData} = this.state
        if (value === $POISTA_VALINTA) value = null
        if (value === '$POISTA_VALINTA') value = null
        const newProject = {...project, [key]: value}
        updateProjectData(key, value)
        clearTimeout(this.editTimers[project_uuid])
        this.editTimers[project_uuid] = setTimeout(() => this.sendEdit(newProject), EDIT_INTERVAL)
        this.setState({project: newProject})
    }

    onChangeHandler = (e) => {
        const {project, updateProjectData} = this.state
        e.preventDefault()
        let {name, value} = e.target
        if (this.propTransform[name]) value = this.propTransform[name].back(value)
        project[name] = value
        updateProjectData(name, value)
        clearTimeout(this.editTimers[project.uuid])
        this.editTimers[project.uuid] = setTimeout(() => this.sendEdit(project), EDIT_INTERVAL)
        this.setState({project})
    }
    sendRaportointiEdit = (projekti_uid, kohde, osuus) => {
        this.props.updateRow({
            info: 'projekti.raportointi',
            className: 'projekti_raportointi',
            action: 'keyed_upsert',
            keys: ['projekti_uid', 'kohde'],
            data: {projekti_uid, kohde, osuus}
        })
    }

    raportointiChange = (project_uuid, key, value) => {
        const {project, updateProjectData} = this.state
        let oldValues = project.raportointihierarkia.filter(a => a.kohde !== key)
        //skaalataan
        const oldsum = oldValues.reduce((a, c) => a + c.osuus, 0)
        if (oldsum + value > 1) {
            const factor = (1 - value) / oldsum
            oldValues = oldValues.map(a => ({'kohde': a.kohde, 'osuus': factor * a.osuus}))
        }
        const newRaportointi = [...oldValues, {'kohde': key, 'osuus': value}]
        const newProject = {...project, 'raportointihierarkia': newRaportointi}
        updateProjectData('raportointihierarkia', newRaportointi)
        clearTimeout(this.editTimers[project_uuid + '_raportointihierarkia'])
        this.editTimers[project_uuid + '_raportointihierarkia'] = setTimeout(() => {
            newRaportointi.forEach((v) => this.sendRaportointiEdit(project.uid, v.kohde, v.osuus))
        }, EDIT_INTERVAL)
        this.setState({project: newProject})
    }

    toggleEditing = () => {
        if (this.state.editing) {
            this.setState({editing: false})
        } else {
            this.setState({editing: true})
        }
    }
    tyytyvaisyyskysely = () => {
        const {project} = this.state
        //TODO tässä pitäisi poimia osoitteet asiakastiedoista, mutta koska niissä ei ole nyt sähköpostiosoitteita, kysytään debuggausta varten alertilla
        const osoitteet = prompt("Anna sähköpostiosoitteet pilkulla tai puolipisteellä erotettuna");
        if (!osoitteet) return;
        // pyydetään serveriä lähettämään kyselyt.
        this.props.teeKysely({osoitteet, project})
        this.setState(
            {tyytyvaisyyskysely_tyonalla: true}
        )
    }

    render() {
        const {editing, project, shortlisted, tyytyvaisyyskysely_tyonalla} = this.state
        //     const {henkilo_uid} = this.props
        //const {dropDownData} = this.props
        const kyselymahdollinen = project && project.yhtio
            && (project.yhtio === 'NHG Consulting Oy'
            || project.yhtio === 'Kaufmann Agency Helsinki Oy'
            || project.yhtio === 'Nordic Healthcare Group Oy'
            || project.yhtio === 'Kuntamaisema Oy'
            || project.yhtio === 'Advisory'
            || project.yhtio === 'Solutions')

        return <div style={{boxSizing: 'border-box'}}>
            {project && <div style={{textAlign: "left", marginLeft: "20px"}}>
                <span className="hoursIcon" onClick={() => this.toggleEditing()}>
                  <Icon color={editing ? "red" : "black"} size="large" name="edit"
                        title={!editing ? "Muokkaa" : "Lopeta muokkaus"}/>
                </span>
                <div style={{textAlign: "left", marginTop: "20px"}}>
                    <div>
                        <span> {project.uid}</span> {this.el("nimi", " ")}{" "}{this.checkbox("salainen")}
                    </div>
                    <div className="lightText">
                        {this.newRow()}
                        {this.dropdown(this.props.dropDownData.yhtio)("yhtio")}
                        {this.dropdown(this.props.dropDownData.projektityyppi)("tyyppi")}

                    </div>
                    <div className="lightText">

                        {this.newRow()}
                        {["paallikko", "asiakasvastuullinenmyyja"].map(e =>
                            this.hloDropdown(e)
                        )}
                    </div>
                    {/*
                    <div className="lightText">
                        {" "}
                        {this.newRow()}
                        {false & ["liiketoimintaalue", "liiketoimintaaluetoissijainen"].map(
                            e =>
                                this.dropdown(
                                    this.props.dropDownData.liiketoimintaalue
                                )(e)
                        )}
                        {project['liiketoimintaaluetoissijainen'] &&
                        this.dropdown({
                            '50': '50:50',
                            '40': '60:40',
                            '30': '70:30',
                            '20': '80:20',
                            '10': '90:10'
                        })("liiketoimintaaluepainotus")
                        }
                    </div> */}
                    <div className="lightText">
                        {" "}
                        {this.newRow()}
                        Raportointialue: <br/>
                        {this.raportointielementti()}
                    </div>
                    <div className="lightText">
                        {" "}
                        {this.newRow()}
                        {["jatkuva", "kiintea"].map(e =>
                            this.dropdown(this.props.dropDownData[e])(e)
                        )}
                    </div>
                    <div className="lightText">
                        {" "}
                        {this.newRow()}
                        <div>
                            {["tuotevaiprojekti"].map(e =>
                                this.dropdown(this.props.dropDownData[e])(e)
                            )}

                        </div>
                        {this.newRow()}
                        <div>
                            Vaikuttavuusperusteisuus:
                            {["vaikuttavuusperusteinen"].map(e =>
                                this.dropdown(this.props.dropDownData[e])(e)
                            )}

                        </div>
                        <div>
                            {this.numel(
                                "kansainvalisyysaste",
                                "",
                                "% ",
                                "Kansainvälisyysaste: "
                            )}
                        </div>
                        <div>
                            {this.numel(
                                "suunnitellutbkulut",
                                "",
                                "€ suunniteltuja b-kuluja"
                            )}
                        </div>
                        <div>
                            {this.numel(
                                "lapilaskutus",
                                "",
                                "€ läpilaskutettavaa"
                            )}
                        </div>
                    </div>

                    <div className="lightText">
                        {" "}
                        {this.newRow()}
                        <div>
                            {/*TODO/KOLMIOVIIDAKKO: tähän tarkistus dokumenteista => mitkä dokumentit tarvitaan*/
                                editing  ?
                                <span> Päättymispäivämäärä: <Dates
                                    keyedChangeHandler={this.keyedChange}
                                    value={project.paattynyt}
                                    field="paattynyt"
                                    uuid={project.uuid}/></span>
                                : project.paattynyt ? <span>Päättynyt: {project.paattynyt}</span> :
                                    <span>Projekti avoin.</span>
                            }


                        </div>
                        <div>
                            {editing ?
                                <span> Referenssi toimitettu: <Dates
                                    keyedChangeHandler={this.keyedChange}
                                    value={project.referenssitoimitettu}
                                    field="referenssitoimitettu"
                                    uuid={project.uuid}/></span>
                                : project.referenssitoimitettu ?
                                    <span>Referenssi toimitettu: {project.referenssitoimitettu}</span> :
                                    <span> Referenssi toimittamatta.</span>
                            }
                        </div>
                        <div>
                            {/* editing ?
                      <Fragment> Tyytyväisyyskysely: <Dropdown valuemap={dropDownData.tyytyvaisyyskysely} 
                      selected={project.tyytyvaisyyskyselylahetetty} keyedChange={this.keyedChange}
                      uuid={project.uuid} name='tyytyvaisyyskyselylahetetty' placeholder='lähetetty?' />
                      </Fragment>
                      : <Fragment>Tyytyväisyyskysely {project.tyytyvaisyyskyselylahetetty}</Fragment>
                    */

                            }
                            {editing ?
                                <span> Tyytyväisyyskysely lähetetty: <Dates
                                    keyedChangeHandler={this.keyedChange}
                                    value={project.tyytyvaisyyskyselylahetetty}
                                    field="tyytyvaisyyskyselylahetetty"
                                    uuid={project.uuid}/></span>
                                : (project.tyytyvaisyyskyselylahetetty ?
                                        (
                                            <span>Tyytyväisyyskysely lähetetty : {project.tyytyvaisyyskyselylahetetty} { kyselymahdollinen && !tyytyvaisyyskysely_tyonalla &&
                                            <button onClick={this.tyytyvaisyyskysely}>Lähetä...</button>}</span>)
                                        : (
                                            <span>Tyytyväisyyskysely lähettämättä. { kyselymahdollinen && !tyytyvaisyyskysely_tyonalla &&
                                            <button onClick={this.tyytyvaisyyskysely}>Lähetä...</button>} </span>)
                                )
                            }
                            {!editing && project.kyselyt && project.kyselyt.length > 0 &&
                            project.kyselyt.map(k => {
                                return k.vastattu ?
                                    <div key={k.email + "-" + k.created} style={{paddingLeft: '10px'}}
                                         title={k.avoin || ''}>{k.email}:
                                        <div style={{paddingLeft: '10px'}}>Suosittelu: {score(k.q0)}</div>
                                        <div style={{paddingLeft: '10px'}}>Korkealuokkaisuus: {score(k.q1)}</div>
                                        <div style={{paddingLeft: '10px'}}>Jatkoyhteistyö: {score(k.q2)}</div>
                                        <div style={{paddingLeft: '10px', maxWidth: '400px'}}>Avoin
                                            palaute: {k.avoin}</div>
                                    </div>
                                    : <div key={k.email} style={{paddingLeft: '10px'}}>{k.email}: ei vastattu</div>
                            })

                            }
                        </div>
                    </div>

                    <div className="lightText">
                        {" "}
                        {this.newRow()}
                        <div>
                            {this.hloDropdown(
                                "loppuraportintarkastaja",
                                ", ",
                                "",
                                "Loppuraportin tarkastaja: "
                            )}{" "}
                        </div>
                        <div>
                            {" "}{this.newRow()}

                            {editing ?
                                <span> Loppudokumentti toimitettu: <Dates
                                    keyedChangeHandler={this.keyedChange}
                                    value={project.loppudokumentittoimitettu}
                                    field="loppudokumentittoimitettu"
                                    uuid={project.uuid}/></span>
                                : project.loppudokumentittoimitettu ?
                                    <span>Loppudokumentti toimitettu: {project.loppudokumentittoimitettu}</span> :
                                    <span> Loppudokumentti toimittamatta.</span>
                            }
                        </div>
                        <div>{this.newRow()}
                            {editing ?
                                <span> Palautteet annettu: <Dates
                                    keyedChangeHandler={this.keyedChange}
                                    value={project.palautteetannettu}
                                    field="palautteetannettu"
                                    uuid={project.uuid}/></span>
                                : project.palautteetannettu ?
                                    <span>Palautteet annettu: {project.palautteetannettu}</span> :
                                    <span> Palautteet antamatta.</span>
                            }
                        </div>
                        <div>{this.newRow()}
                            { (editing && shortlisted) ?
                                  <span> Tavoitekannattavuus:  {this.numel("tavoitekannattavuus", " ")} </span>
                                : <span>Tavoitekannattavuus: {fix0( project.tavoitekannattavuus * 100.0) } %</span>
                            }
                        </div>
                        <div>{this.newRow()}
                            {this.checkbox("poikkeava_paivahinta")}
                        </div>
                    </div>
                    <div>
                        {editing ?
                            <span> Kick-off: <Dates
                                keyedChangeHandler={this.keyedChange}
                                value={project.kickoff}
                                field="kickoff"
                                uuid={project.uuid}/></span>
                            : project.referenssitoimitettu ?
                                <span>Kick-off: {project.kickoff}</span> :
                                <span>Kick-off pitämättä</span>
                        }
                        {this.el("pvkoulutus", " ")}
                    </div>

                </div>
            </div>}
        </div>;
    }

}


export default Tiedot
