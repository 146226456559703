import React, { PureComponent } from 'react'
import { Icon } from "semantic-ui-react";
import { v4 as uid } from 'uuid';
import DropDown from '../DropDown'
import Dates from '../hours/Dates'
import {currentYear, date2isodate, fixnumber} from '../../../Helpers'


const propMap ={
  ytunnus:'Y-tunnus',
  yhteyshenkilo: 'Yhteyshenkilö',
/*  laskutustiedot: 'Laskutusosoite',*/
  vanhavaiuusi: 'Vanha vai uusi',
  asiakastyyppi: 'Tyyppi',
  kilpailutettu: 'Kilpailutettu',
  laskutus: 'Vuosilaskutus',
  paivahinta: 'Päivähinta',
  nimi: 'Nimi',
  kklaskutusera: 'Kuukausilaskutuserä',
  sopimusalkanut: 'Sopimus alkanut',
  laskutettaviakuukausia: 'Laskutettavia kuukausia',
  laskutuskuukaudet: 'Laskutuskuukaudet',
  viite: 'Viite',
  laskutuseria: 'Laskutuseriä',
  maksuehto: 'Maksuehto',
  sopimuspaattyy: 'Sopimus päättyy',
  
  katuosoite: 'Katuosoite',
  kaupunki: 'Kaupunki',
  postinumero: 'Postinumero',
  sahkopostilaskuosoite: 'Sähköposti laskutusta varten', 
  finvoiceaddress: 'OVT-tunnus',
  finvoiceroutercode: 'OVT välittäjäkoodi'
}
const propTransform ={
  kklaskutusera:{
    forward: v=>isNaN(v) ? v : (v-0||0.0).toFixed(2),
    back:    v=>v
  }
/*  kilpailutettu: {
    forward: v => {return v=== 'Ei' ? 'Ei-kilpailutettu' : 'Kilpailutettu'},
    back:    v => {return v=== 'Ei-kilpailutettu'? 'Ei' : 'Kyllä'}

  },*/
/*  laskutus:{
    forward: v => isNaN(v) ? v : (v-0||0.0).toFixed(2),
    back:    v => v
  }*/
}

let rowstart = false
const newRow=()=>{
  rowstart = false
  return ''
}
const datel = ({data, editing, key, delimiter, post , keyedChange})=>{
  let e = data[key]
  if((!e || e === '')  && !editing) return <span key={key}/> // do not render null or empty items unless editing
  if(!rowstart){ 
    delimiter = ''; 
    rowstart = true 
  }
  return editing ? 
        <span> <Dates 
         keyedChangeHandler={keyedChange} 
         value={e} 
         field={key} 
         uuid={data.uuid} 
         style={{ display: "inline-block" }} 
         placeholder={propMap[key]} 
         title={propMap[key]} />
         {post}&nbsp;</span>
      : <span title = {propMap[key] || '' }>{delimiter}{e} {post}</span>
}
const el = ({data, editing, key, delimiter, post , onChange} )=>{
    //todo tämä pitäisi overridata shortlistalaisilta
    if(editing && key ==='paivahinta' && data.luotu){
        const raja = new Date(data.luotu) - - (3600000*24*10) // luotu
        const nyt = new Date()
        if( raja < nyt){
            editing = false; // ei voi enää editoida
        }
    }
  let e = data[key]
  if((!e || e === '')  && !editing) return <span key={key}/> // do not render null or empty items unless editing
  if( !e ) e = '' // avoid null values in react components https://reactjs.org/docs/forms.html#controlled-input-null-value
  if(!rowstart){ 
      delimiter = ''; 
      rowstart = true 
  }        
  const v = propTransform[key] ? propTransform[key].forward(e) : e
  const iw = ( Math.max( propMap[key].length,  (v + "").length) *1.1 ) + 'ch' // input width
  return editing ? 
        <span> <input style={{width:iw}} type='text' name={key} title={propMap[key]} className="tightProjectInput" value ={v} 
                      onChange={onChange} placeholder={propMap[key]} /> {post}&nbsp;</span>
      : <span title = {propMap[key] || '' }>{delimiter}{v} {post}</span>
}
const Asiakasrow = ({a,editing, onChange, keyedChange, deleteUUID, paivitaEnnusteet})=>{
  const def = {data:a, editing, delimiter:', ', post:'', key:'', onChange, keyedChange}
  return(
    <div className='clientRow' key={a.uuid} >
        

        <div className='clientHeader' style={ editing ? {position:'relative'} : {} }>{newRow()}
          {el({ ...def , key: 'ytunnus' })} {el({...def , key:'nimi'})}
          {editing && <span style={{position:'absolute', right:'50px'}} onClick={() => {
            deleteUUID( a.uuid ); }}>
              <Icon id={a.uuid} color="red" name="close" title='poista rivi' />
          </span>}

        </div>
        <div className='clientDetail'>{newRow()}
        {el({...def, key:'yhteyshenkilo'})}
        </div><div>{newRow()}
        {el({...def, key:'viite'})}</div>
        <div>{newRow()}
        Maksuehto {el({...def, key:'maksuehto', post:' pv netto'})}
        </div>
        <div className='clientDetail'>{newRow()}
          <div>{el({...def, key:'katuosoite'}) }{newRow()}</div>
          <div>{el({...def, key:'postinumero'}) }{el({...def, key:'kaupunki'}) } {newRow()}</div>
          <div>{el({...def, key:'sahkopostilaskuosoite'}) }{newRow()}</div>
          <div>{el({...def, key:'finvoiceaddress'}) }{el({...def, key:'finvoiceroutercode'}) } {newRow()}</div>
        </div>
        <br />
        <div className='clientDetail'>{newRow()}
          {datel({...def,key:'sopimusalkanut'})}
          {datel({...def,key:'sopimuspaattyy'})}          
        </div>
        <div className='clientStrong'> {newRow()}
          {el({...def, key:'laskutus', post:' €'})}
          {el({...def, key:'paivahinta', post:' € / päivä'})}
          {editing ? <span> <select name='laskutuseria' onChange={e=>{keyedChange(a.uuid,'laskutuseria',e.target.value)}} >
              {[1,2,3,4,12].map( i=><option key={i} value={i} selected={a.laskutuseria === i}>{i}</option> )}
            </select> erää</span>
            :<span> {a.laskutuseria} erää</span>
          }          
        </div>
          {editing && <div><button onClick={e=>paivitaEnnusteet(a)}>Luo laskutusennusteet</button></div>}

      </div>
  )

}
/*
const BAsiakasrow = ({a,editing, onChange, deleteUUID})=>{
  const def = {data:a, editing, delimiter:', ', post:'', key:'', onChange}
  //${sql(select nimi,yhteyshenkilo,laskutustiedot,ytunnus,
  //asiakastyyppi,kklaskutusera, laskutettaviakuukausia, '',laskutuskuukaudet,sopimusalkanut from projektiasiakas where projekti_uid ='##arg##')}
  return(
    <div className='clientRow' key={a.uuid} >
        <div className='clientHeader' style={ editing ? {position:'relative'} : {} }>{newRow()}
          {el({ ...def , key: 'ytunnus' })} {el({...def , key:'nimi'})}
          {editing && <span style={{position:'absolute', right:'50px'}} onClick={() => {
            deleteUUID( a.uuid ); }}>
              <Icon id={a.uuid} color="red" name="close" title='poista rivi' />
          </span>}

        </div>
        <div className='clientDetail'>{newRow()}
        {el({...def, key:'yhteyshenkilo'})}
        {el({...def, key:'viite'})}
        {el({...def,key:'sopimusalkanut'})}
        </div>
        <div className='clientDetail'>{newRow()}
          {el({...def, key:'laskutustiedot'}) }
          
        </div>
        <div className='clientStrong'> {newRow()}
          {el({...def, key:'kklaskutusera', post:' €'})}
          {el({...def, key:'laskutettaviakuukausia', post:' kk'})}
          {el({...def, key:'laskutuskuukaudet'})}
        </div>
        {!editing &&
          <div className='clientStrong'>{(a.kklaskutusera*a.laskutettaviakuukausia).toFixed(2)} €/a</div>
        }

      </div>
  )

}*/

const EDIT_INTERVAL = 1000;
 class Asiakastiedot extends PureComponent {
    state = {
      editing: false,
      project: this.props.project,
      updateProjectData: this.props.updateProjectData,
      updateRow: this.props.updateRow,
      project_uid:this.props.project_uid
    }

   static getDerivedStateFromProps(props, state) {
       if (!props.shortlisted) {
           return {...state, ...props, editing: false}
       }
    if( props.project_uid !== state.project_uid  ) {
        if( state.rpoject_uid && state.project_uid[0] === 'Z' && state.editing){
            //TODO sloppy, periaatteessa on mahdollista, jettä joku vaihtaa Z-projektia editoidessa toiseen projektiin ennen kuin se ehtii päivittyä
            return {...state,project_uid:props.project_uid}
        }
        return { ...state, ...props, editing: false }
    }
    return {...props,...state};
  }
  toggleEditing(){
    if( this.state.editing){
      this.setState({editing:false})
//      this.props.refreshProjects() // aiheutti ongelmia, jos on hakuja päällä
    }else{
      this.setState({editing:true})
    }
  }
  /*
  addNewEntry = ()=>{
    const {project} = this.state
    const { updateProjectData, updateRow } = this.props
    let newAsiakas = {
      nimi: '',
      yhteyshenkilo: '',
      laskutustiedot: '',
      ytunnus: '',
      laskutus: 0,
      paivahinta: 0,
      projekti_uid: project.uid,
      projekti_uuid: project.uuid,
      uuid: uid()
    }
    project.asiakkaat.unshift(newAsiakas)
    updateProjectData('asiakkaat', project.asiakkaat)

    updateRow({
      info: 'new_asiakas_number',
      className: 'projektiasiakas',
      action: 'upsert',
      data:newAsiakas
    })   
  }
  */
  editTimers = {}
  keyedChange = (uuid,name,value)=>{
        // korjataan desimaalierotin numeerisesta tiedosta:
      // sloppy .. pitäisi tietää onko oikeasti numeerista
      if( /^[0-9,]+$/.test(value) ){
          value = fixnumber(value)
      }

      const { updateProjectData } = this.props
    const { project} = this.state
    let old_index = project.asiakkaat.findIndex( a => a.uuid === uuid )
    let old_data = project.asiakkaat[old_index]
    if( propTransform[name] ) value = propTransform[name].back(value)
    old_data[name] = value
    //laitetaan project_uuid kuntoon koska tämä ei tallennu kantaan
    if(!old_data["projekti_uuid"]){
      old_data["projekti_uuid"] = project.uuid
    }
    project.asiakkaat[old_index] = old_data
    updateProjectData('asiakkaat',project.asiakkaat)

    clearTimeout(this.editTimers[uuid])
    this.editTimers[uuid] = setTimeout( ()=>this.sendEdit(old_data), EDIT_INTERVAL)
    this.setState({project})

  }
  asiakasChanged = (a, e)=>{
    e.preventDefault()
    const {name,value} = e.target
    this.keyedChange( a.uuid, name,value )
  }
  sendEdit = (asiakas)=>{
    const {updateRow} = this.props
    updateRow({
      info: 'projekti.asiakas',
      className: 'projektiasiakas',
      action: 'upsert',
      data: asiakas
    })    
//    this.paivitaEnnusteet(asiakas)
  }
  paivitaEnnusteet = (asiakas)=>{
    // vanhva versio ei toiminut ollenkaan oikein
    // korjataan nyt siten, että 
    // loppu||vuodenloppu - alku||vuodenalku = pituus
    // eränpituus = pituus /eriä
    // laskutusajankohdat = alku+n*eranpituus


    // jos projekti on alkanut ennen ja päättyy null tai vuoden loppuun, niin
    // laskutuspäivät ovat erien mukaan kuun viimeiset

    try{
      const kks = (()=>{
        const startDate = asiakas.sopimusalkanut ? new Date( new Date(asiakas.sopimusalkanut) - - (30*24*3600000) ) // alusta ainakin 30 päivää
                                                 : new Date( currentYear+"-01-01") // vuoden alku
        const endDate = asiakas.sopimuspaattyy ? new Date( new Date(asiakas.sopimuspaattyy) - (30*24*3600000) ) //viimeinen loppulasku korkeintaan 30pv ennen loppua
                                               : new Date((currentYear - - 1)+"-01-01") // vuoden loppu
        const erapituus = (+endDate - +startDate)/asiakas.laskutuseria
        function* gen(max){let c=1; while(c<=max){yield c++}}
        const nrot = Array.from(gen(asiakas.laskutuseria))
        const kkss = nrot.map( (n)=>new Date(+startDate + n*erapituus) )
        return kkss
      })()

      //TODO refaktoroi tämä siten, että kaikki rivit lähetetään yhdessä updatessa

      const projekti = this.props.project
      const poistettavat = projekti.laskutusennusteet.filter(e=> e.asiakas === asiakas.nimi )
      poistettavat.forEach(p=>{
        this.props.updateRow({
          info:'projekti.ennusteet',
          className:'laskutusennuste',
          action:'delete',
          data:{uuid:p.uuid,projekti_uid: projekti.uid }
        })
      })
      const asumma = asiakas.laskutus / kks.length
      const uudet = kks.map(a=>({asiakas:asiakas.nimi,
         pvm:date2isodate(a),
          tyosumma:asumma,
           projekti_uid: projekti.uid, 
           uuid:uid() 
          }))
      uudet.forEach(e=>{
        this.props.updateRow({
          info: 'projekti.laskutusennuste',
          className: 'laskutusennuste',
          action: 'upsert',
          data: e
        })
      })
  
    }catch(e){
      console.log(e)
    }
    
  }
 deleteUUID = (uuid) =>{
   // tehdään confirm ennen kuin tehdään mitään muuta
   if( ! window.confirm("Tämä tuhoaa asiakastiedot peruuttamattomasti") ) return;
   const {project} = this.state
   const { updateProjectData, updateRow } = this.props
    let new_asiakkaat = project.asiakkaat.filter(a=>a.uuid !== uuid)
    updateRow({
      info:'projekti.asiakas',
      className:'projektiasiakas',
      action:'delete',
      data:{uuid}
    })
    updateProjectData('asiakkaat', new_asiakkaat)
    this.setState({project:{...project, asiakkaat:new_asiakkaat}})
  
  }
  
  keyedAddProject = (uuid,key,nro) => {
    const {project} = this.state;
    const { updateProjectData, updateRow, lookupData } = this.props
    
    const nnro = Number(nro)
    const selectedClient = lookupData.clients.find( a => a.nro === nnro )    
    const newClient = {...selectedClient, uuid:uid(), projekti_uid: project.uid, projekti_uuid:project.uuid,  laskutus:"",paivahinta:""}
    const newAsiakkaat = [...project.asiakkaat, newClient]
    const newProject = {...project, asiakkaat:newAsiakkaat}
    
    
    updateProjectData('asiakkaat', newAsiakkaat)
    updateRow({
      info: 'projekti.asiakas',
      className: 'projektiasiakas',
      action: 'upsert',
      data: newClient
    })
    this.setState({project:newProject})
    
  }
 render(){
    const{project, editing}=this.state
    const {deleteRow, dropDownData} = this.props
    const [total, dailytotal] = (()=>{
      return project && project.asiakkaat ? 
        project.asiakkaat.reduce( (o,a,i)=>{ return [o[0] - -(a.laskutus || 0.0), o[1] - -(a.paivahinta ||0.0) ]} ,[0,0]) // - - and not + to force numeric
        : [0,0];
    })();

    return <div   style={{boxSizing:'border-box'}}    >
         <span style={{ textAlign: "left", marginLeft: "20px" }} className="hoursIcon" onClick={() => this.toggleEditing()}>            
          <Icon  title={!editing ? "Muokkaa" : "Lopeta muokkaus"} color={editing ? "red" : "black"} size="large" name="edit" />
        </span>
        {editing && <span>
            
            <DropDown placeholder='Valitse asiakas' style={{ display: "inline-block", width:'350px', overflow:'visible' }}
                      valuemap={dropDownData.asiakkaat} keyedChange={this.keyedAddProject} 
                      uuid={project.uuid} name="asiakas" />

{/*                      <button onClick={this.addNewEntry}  style={{ display: "inline-block" }} > Uusi asiakas </button>  */}
          </span>}

        {project && project.asiakkaat && <div>
              {project.asiakkaat.map((a, i) => {
                return <React.Fragment key={a.uuid}>
                    {" "}
                    {project.uid[0]==='B' ?
                      <Asiakasrow key={a.uuid} a={a} editing={editing} paivitaEnnusteet={e=>this.paivitaEnnusteet(a)} onChange={e => this.asiakasChanged(a, e)} keyedChange={this.keyedChange} deleteUUID={this.deleteUUID} />
                    : <Asiakasrow key={a.uuid} a={a} editing={editing} paivitaEnnusteet={e=>this.paivitaEnnusteet(a)} onChange={e => this.asiakasChanged(a, e)} keyedChange={this.keyedChange} deleteUUID={this.deleteUUID} />}                 

                    {false && editing && <span  onClick={() => {
                          deleteRow({ name: "asiakkaat", i });
                        }}>
                        <Icon id={i} color="red" name="close" />
                      </span>}
                  </React.Fragment>;
              })}
            </div>}

        {project && project.asiakkaat && project.asiakkaat.length > 1 && (
          project.uid[0]==='B' ?
            <div className="clientTotal">
            Yhteensä: {(total - 0).toFixed(2)}
            </div>
          :
            <div className="clientTotal">
              Yhteensä: {(total - 0).toFixed(2)}
              €, {(dailytotal - 0).toFixed(2)} €/päivä.
            </div>
        )}
      </div>;
  }
}

export default Asiakastiedot;