export const STORE_USER = 'STORE_USER';
export const SELECTED_MONTH ='SELECTED_MONTH';
export const SELECTED_PROJECT="SELECTED_PROJECT"
export const STORE_HOURS = 'STORE_HOURS';
export const STORE_EXPENSES ='STORE_EXPENSES';
export const STORE_PERSON ='STORE_PERSON';
export const STORE_PROJECT_LIST = "STORE_PROJECT_LIST";
export const STORE_FILE_LIST = "STORE_FILE_LIST";
export const STORE_CURRENT_PROJETCS = "STORE_CURRENT_PROJECTS";
export const STORE_MONTHLY_HOURS = "STORE_MONTHLY_HOURS";
export const STORE_DROPDOWN = "STORE_DROPDOWN";
export const STORE_LOOKUP = "STORE_LOOKUP";
export const STORE_SEARCH = "STORE_SEARCH";
export const STORE_FILE_SEARCH = "STORE_FILE_SEARCH";
export const ADD_PROJECT = "ADD_PROJECT";
export const CREATE_USER_UUID = 'CREATE_USER_UUID'
export const ADD_COUNTER = 'ADD_COUNTER'



// ui actions


export const DISPLAY_PROJECT = "DISPLAY_PROJECT";
export const DISPLAY_LOADER ='DISPLAY_LOADER'
export const DISPLAY_HOURS ='DISPLAY_HOURS'
export const DUPLICATE_EXPENSES = "DUPLICATE_EXPENSES";
export const SEARCH_PERSON = "SEARCH_PERSON";
export const SEARCH_FILE = "SEARCH_FILE";
export const SHOW_EXPENSE_MODAL = "SHOW_EXPENSE_MODAL";
export const CANCEL_EXPENSE_MODAL = "CANCEL_EXPENSE_MODAL";
export const SEND_INVOICE = "SEND_INVOICE";
export const SHOW_CLIENT_INVOICE_MODAL = "SHOW_CLIENT_INVOICE_MODAL";
export const CANCEL_CLIENT_INVOICE_MODAL = "CANCEL_CLIENT_INVOICE_MODAL";
export const SEND_CLIENT_INVOICE = "SEND_CLIENT_INVOICE";


export const SHOW_REF_SEARCH_MODAL = "SHOW_REF_SEARCH_MODAL";
export const CANCEL_REF_SEARCH_MODAL = "CANCEL_REF_SEARCH_MODAL";
export const COPY_REF = "COPY_REF";

export const SHOW_FILE_SEARCH_MODAL   = "SHOW_FILE_SEARCH_MODAL";
export const CANCEL_FILE_SEARCH_MODAL = "CANCEL_FILE_SEARCH_MODAL";



//update action 


export const UPDATE_HOURS = "UPDATE_HOURS";
export const UPDATE_EXPENSES = "UPDATE_EXPENSES";
export const UPDATE_PROJECT_DATA = 'UPDATE_PROJECT_DATA'
export const UPDATE_ROW = "UPDATE_ROW";
export const TEE_KYSELY = "TEE_KYSELY";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const UPLOAD_XLS = "UPLOAD_XLS";
export const UPLOAD_FILE = "UPLOAD_FILE";

export const UPDATE_UPLOAD_PROGRESS = "UPDATE_UPLOAD_PROGRESS";




// saga helper action 
export const INIT_STORE_HOURS = 'INIT_STORE_HOURS';
export const INIT_STORE_EXPENSES = 'INIT_STORE_EXPENSES';
export const INIT_STORE_PROJECT_LIST = "INIT_STORE_PROJECT_LIST";
export const INIT_STORE_MONTHLY_HOURS = "INIT_STORE_MONTHLY_HOURS";
export const INIT_HANDLE_SECTECTION = "INIT_HANDLE_SECTECTION";
export const INIT_HANDLE_PROJECT_SECTECTION = "INIT_HANDLE_PROJECT_SECTECTION"
export const INIT_STORE_SHORTLISTED_MONTHLYHOURS = "INIT_STORE_SHORTLISTED_MONTHLYHOURS";
export const INIT_GET_ALLMONTHS ='INIT_GET_ALLMONTHS'
export const INIT_DATA ='INIT_DATA'
export const STORE_TOKEN='STORE_TOKEN'






