import React, { Component } from 'react'
import { Table, Icon, TextArea} from "semantic-ui-react";
import { v4 as uid } from 'uuid';
import CSVModal  from "./CSVModal.js"
import Dates from './Dates'
import DropDown from '../DropDown'
import {rwarn} from '../../../Warnings'
import {fix2,fixnumber,date2isodate} from '../../../Helpers'
import { v4 as uuid } from 'uuid';

const WAIT_INTERVAL = 5000;
const EDIT_INTERVAL = 1000;

export const date = () => {
const d = new Date().toISOString().slice(0, 10);

  return d
}
class MonthlyHours extends Component {
  state = {
    selectedMonth:this.props.selectedMonth,
    editing: false,
    hours: this.props.hours, // hours for whole month
    person: this.props.person
  };
  timer = null;

  static getDerivedStateFromProps(props, state) {
    if (!props.shortlisted) {
      return {...state, ...props, editing: false}
    }
    if(state.editing) return {state} // do not modify while editing
    if (props.selectedMonth!== state.selectedMonth){
      return { ...state,...props,editing:false}
    }
    if (!state.editing || props.hours.uuid !== state.hours.uuid) {
      return { ...state, ...props }
    }
    return null;
  }

  
  editTimers = {}
  keyedChangeHandler = (uuid, key, value) => {
    clearTimeout(this.timer);
        
    const { selectedMonth, updateHours } = this.props;
    const old_hours = this.state.hours;
    const el = old_hours.find(i => i.uuid === uuid);
    const new_el = { ...el, [key]: value };
    const hours = old_hours.map(e => (e.uuid === uuid ? new_el : e));

    this.setState({ hours });

    clearTimeout(this.editTimers[uuid])
    this.editTimers[uuid] = setTimeout(()=>this.sendEdit(new_el) , EDIT_INTERVAL );

    if (new_el.pvm.slice(0, 7) === selectedMonth) updateHours(hours);
    this.timer = setTimeout(this.refreshChange, WAIT_INTERVAL);
  };

  sendEdit = ( new_element )=>{
    const {selectedMonth,updateRow } = this.props
    updateRow({
      info: selectedMonth,
      className: "tyotunti",
      action: "upsert",
      data: new_element
    });
  }


  refreshChange = () => {
     const { selectedMonth, initGetMonthAggregated } = this.props;
     initGetMonthAggregated({month: selectedMonth})
  };

  // initial hours state is recieved from the props.hours so that edited hours can be sent to server for

  deleteEmptyRow = uuid => {
    if( ! window.confirm("Vahvista, että haluat poistaa työtuntikirjauksen") ) return;
   
    const { selectedMonth } = this.props;
    const hours = this.state.hours.filter(a => a.uuid !== uuid);

    this.setState({ hours });
    this.props.updateRow({
      info: selectedMonth,
      className: "tyotunti",
      action: "delete",
      data: { uuid }
    });
    this.props.updateHours(hours);
  };

  editStateHandler = () => {
    this.setState({ editing: !this.state.editing });
  };

  duplicateHour = uuid2copy => {
    const old = this.state.hours.filter(a => a.uuid === uuid2copy)[0];

    const selite = old.selite + " kopio"
    const new_hour = { ...old, uuid: uuid() , selite};
    // sort => uusi päivä oikealle paikalleen
    const hours = [new_hour, ...this.state.hours];
    this.setState({ hours });
    this.props.updateRow({
      info: "kopioitu_tunti",
      className: "tyotunti",
      action: "upsert",
      data:  new_hour
    });
    this.props.updateHours(hours);
  };


  addNewentries = () => {
    const hours = [...this.state.hours];
    const henkilo_uid = this.props.pseudo_uid || this.state.person.uid;
    const emptyRow = {
      pvm: date(),
      tunnit: "",
      projekti_uid: "X100099",
      selite: "",
      uuid: uid(),
      henkilo_uid: henkilo_uid 
    };
    hours.unshift(emptyRow);
    this.setState({ hours });
    const { selectedMonth } = this.props;
    this.props.updateRow({
      info: selectedMonth,
      className: "tyotunti",
      action: "upsert",
      data: emptyRow
    });
    this.props.updateHours(hours);
  };
  //  const zipObj = xs => ys => xs.reduce( (obj, x, i) => ({ ...obj, [x]: ys[i] }), {})

  insertHours = hs => {
    hs = hs.map(h=>({...h,pvm:h['YYYY-MM-DD']}))
    this.props.updateHours([...hs, ...this.state.hours]);
    // lisäksi täytyy lähettää kantaan

    const {selectedMonth,updateRow } = this.props
    for( var hr of hs ){
      hr['henkilo_uid'] = this.state.pseudo_uid || this.state.person.uid
      updateRow({
        info: selectedMonth,
        className: "tyotunti",
        action: "upsert",
        data: hr
      });
    }
    this.setState({ editing: false });
  };

  render() {
    const { editing, hours } = this.state;
    const { dropDownData , selectedMonth, person, lookupData} = this.props;
    const sixmonthsmilliseconds = 15768000000
    const editable = (person && person.shortlisted ) ||(new Date() - (new Date(selectedMonth)|| new Date()) ) <  sixmonthsmilliseconds

    const projectlookup = lookupData.projects
    const elimit = (()=>{
      var d = new Date() //nyt
      if( d.getDate() < 6){
        d.setDate(-1)
        d.setDate(0)
      } else{
        d.setDate(0)
      }
      return date2isodate(d)
    })()

    return <div style={{ overflow: "scroll", height: "100%" }}>
        <div>
              {editable &&
              <span className="hoursIcon" onClick={this.editStateHandler}>
                <Icon
                  color={editing ? "red" : "black"}
                  size="large"
                  name="edit outline"
                  title={!editing ? "Muokkaa" : "Lopeta muokkaus"}
                />
              </span>}
           
          
          {editing && (
            <span className="addIcon" onClick={this.addNewentries}>
              <Icon title='Lisää' name="add" size="large" />
            </span>
          )}
          {editing && (
            <CSVModal
              fields={["YYYY-MM-DD", "tunnit", "projekti_uid", "selite"]}
              parsedHours={hs => this.insertHours(hs)}
            />
          )}
        </div>

        <Table compact="very" style={{ textAlign: "laft" }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Päivä</Table.HeaderCell>
              <Table.HeaderCell>Projekti</Table.HeaderCell>
              <Table.HeaderCell>Tunnit</Table.HeaderCell>
              <Table.HeaderCell>Selite</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {hours
              ? hours.map((el, i) => {
                  let ed = editing
                  if( lookupData && lookupData["project_completed"][(el.projekti_uid||"").toUpperCase()] != null ) ed = false
                  if( el.pvm < elimit) ed = false
                  if( person && person.shortlisted) ed = editing

                  return !ed ? (
                    <Table.Row key={el.uuid}>
                      <Table.Cell>
                        {el.pvm ? el.pvm.slice(-2) : el.pvm}
                      </Table.Cell>
                      <Table.Cell><span title={projectlookup[(el.projekti_uid||"").toUpperCase()]||""}>
                      {(el.projekti_uid||"").toUpperCase()} </span></Table.Cell>
                      <Table.Cell>{el.tunnit > 24.0 ? rwarn('tunnit yli 24h!'):""}{fix2(el.tunnit)}</Table.Cell>
                      <Table.Cell style={{ textAlign: "left" }}>
                        {el.selite}
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    <Table.Row key={el.uuid}>
                      <Table.Cell>
                        <Dates
                          showcleardate={false}
                          keyedChangeHandler={this.keyedChangeHandler}
                          value={el.pvm}
                          field="pvm"
                          uuid={el.uuid}
                        />
                      </Table.Cell>

                    <Table.Cell>
                      <DropDown valuemap={dropDownData.active_projects} selected={(el.projekti_uid ||"").toUpperCase()} keyedChange={this.keyedChangeHandler} uuid={el.uuid} name="projekti_uid" />
                    </Table.Cell>
                    <Table.Cell>
                      <input onChange={e => {
                        if( e.target.validity.valid === false ) return;
                        let value = fixnumber( e.target.value );
                          this.keyedChangeHandler(el.uuid, e.target.name, value);
                        }} className="tunnit" type="text" pattern="[0-9 ]*[.,]?[0-9]*" name="tunnit" value={el.tunnit ? el.tunnit : ""} />
                    </Table.Cell>
                    <Table.Cell>
                      <TextArea autoHeight onChange={e => {
                          this.keyedChangeHandler(el.uuid, e.target.name, e.target.value);
                        }} className="hoursEdit" type="text" name="selite" value={el.selite ? el.selite : ""} />
                      <span onClick={e => {
                          this.deleteEmptyRow(el.uuid);
                        }}>
                        <Icon id={i} color="red" name="close" title="poista" />
                      </span>
                      <span  style={{cursor:'pointer'}} onClick={()=>{this.duplicateHour(el.uuid)}} >  <Icon name='copy' title='Kopioi' /></span>
                    </Table.Cell>
                  </Table.Row>);
              }) : <span />}
          </Table.Body>
        </Table>
      { editing&& <div style={{ height: "300px" ,width:"100%"}} />}
      </div>;
  }
}
export default MonthlyHours