import React from 'react';


export const timestamp2month = (t) => new Date(t).toISOString().slice(0,7)
export const month2timestamp = (t) => + new Date(t)

export const timestamp2iso = (t) => new Date(t).toISOString().slice(0,10)
export const iso2timestamp = (t) => + new Date(t)

export const date2isomonth = (d) => d.toISOString().slice(0,7)
export const month2isodate = (t) => new Date(t).toISOString().slice(0,10)

export const date2isodate = (d) => d.toISOString().slice(0,10)
export const isodate2date = (t) => new Date(t)

export const currentMonth = date2isomonth(new Date() )
export const currentYear  = date2isomonth(new Date() ).slice(0,4)
export const currentDate  = date2isodate( new Date() )

const fix = d => a=> a && a.toFixed ? a.toFixed(d) : a
export const fix0 = fix(0)
export const fix1 = fix(1)
export const fix2 = fix(2)

export const zeroPad = (val,len=2)=>{
    const finalLen = Math.max(len,(val+"").length)
    return (Array(len+1).join(0) + val).slice(-finalLen)
  }

export const fixnumber = (s)=>  s.replace(",",".").replace(" ","")

export const HSVtoRGB = (h,s,v) => {
  const sec = h/60
  const i=Math.floor(sec)
  const f = sec-i
  const p = v* (1-s)
  const q = v* (1-s*f)
  const t = v* ( 1 - s * ( 1 - f ) );
  switch(i){
    case 0: return `rgb(${Math.floor(v*255)},${Math.floor(t*255)},${Math.floor(p*255)})`//vtp
    case 1: return `rgb(${Math.floor(q*255)},${Math.floor(v*255)},${Math.floor(p*255)})`//qvp
    case 2: return `rgb(${Math.floor(p*255)},${Math.floor(v*255)},${Math.floor(t*255)})`//pvt
    case 3: return `rgb(${Math.floor(p*255)},${Math.floor(q*255)},${Math.floor(v*255)})`//pqv
    case 4: return `rgb(${Math.floor(t*255)},${Math.floor(p*255)},${Math.floor(v*255)})`//tpv
    case 5: return `rgb(${Math.floor(v*255)},${Math.floor(p*255)},${Math.floor(q*255)})`//vpq
    default: return `rgb(0,0,0)`
  }

}

export const score = score =>{
    if( score === undefined || score === null){
        return ""
    }
  const s=0.5
  const v=0.9
  const h = score/10*123 // 0°-123°
  return <span style={{ width:'22px'
                      , height:'22px'
                      , display:'inline-block'
                      , fontSize:'14px'
                      , fontWeight:'600'
                      , padding:'1px'
                      , textAlign:'center'
                      , border:`1px solid ${HSVtoRGB(h,s,v-0.1)}`
                      , borderRadius:'3px'
                      , backgroundColor:`${HSVtoRGB(h,s,v)}`
                      , color:'rgb(255,255,255)'
                    }}>{score}</span>

}
