import React, {PureComponent} from 'react'
import { Dropdown  } from "semantic-ui-react";
import { Icon } from "semantic-ui-react";



/*
  <Dropdown placeholder='Select Country' fluid search selection options={countryOptions} />
*/
let cache_options = null
let cache_valuemap = null

class DropDown extends PureComponent{
    static cache_options = null
    static cache_valuemap = null
    
    value = null;

    constructor(props){
        super(props)
        const {valuemap} = this.props
        if(valuemap !== cache_valuemap){
            cache_valuemap = valuemap
            const keys = Object.keys(valuemap).sort()
            const options = (()=>{ 
                // for arrays we map names and values to array items, for objects we do key-value mapping to object keys and values
                return Array.isArray(valuemap) ?
                      valuemap.map(v=>{return {text:v, value:v, key:v} })
                    : keys.map(k=>{ return { text:valuemap[k], value:k, key:k }})
            })()
            cache_options = options
            this.options = options
        }else{
            this.options = [...cache_options]
        }
    }
    semanticChangeHandler = (e,selection) => {
        e.preventDefault()
        this.value = selection.value
        const {keyedChange,uuid, name } = this.props
        keyedChange(uuid,name,selection.value)

    }

    clear = (e)=>{
        this.value = null;
        const s = {value:null}
        if(this.props.onChange){
            this.props.onChange(e,s)
        }else{
            this.semanticChangeHandler(e,s)
        }
    }
    render(){
        const { selected, placeholder , onChange} = this.props
        return(            
            <div style={{display:'inline-block'}}>
            <Dropdown placeholder={placeholder} style={{maxWidth:'250px', minWidth:'100px', display:'inline-block'}} 
             onChange={ onChange || this.semanticChangeHandler }  search selection value={selected} options={this.options}  />
             <Icon color="teal" name="close" title='Tyhjennä' onClick={this.clear} style={{position:'relative'}}/>
             </div>
        )
    }
}
/*
const DropDown = ({valuemap, selected, keyedChange, uuid, name, placeholder})=>{
    if(valuemap !== cache_valuemap){
        cache_valuemap = valuemap
        const keys = Object.keys(valuemap).sort()
        const options = (()=>{ 
            // for arrays we map names and values to array items, for objects we do key-value mapping to object keys and values
            return Array.isArray(valuemap) ?
                  valuemap.map(v=>{return {text:v, value:v, selected:(selected === v), key:v} })
                : keys.map(k=>{ return { text:valuemap[k], value:k, selected: ( selected === k), key:k }})
        })()
        cache_options = options
    }
    const semanticChangeHandler = (e,selection) => {
        keyedChange(uuid,name,selection.value)
    }
    return (
    )
}*/
export default DropDown;
