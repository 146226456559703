import React from 'react';
import backEnd from '../../BackEnd';

const tuntireports = [
    'tuntikululomake',    
]
const projektireports = [
//    'laskuta',
    'projektikortti'  ,
    'staffing'  
]

const shortlist = [
    'henkilosto',
//    'kuukausiraportointi2',
//    'tunnitprojektitkomssi',
    'resurssiraportointi',
    'lomasaldot',
    'tuntisaldot',
    'raportointipaivat',
    'konsernilaskutus',
    'laskutusennusteet',
    'staffing'
    ]
    
const download = (r, p, jwt)=>{ // r = report name
    if( r === placeholder) return // dummy
    if( r === 'projektikortti' && p.tuotevaiprojekti==='tuote') {
        r = 'tuotekortti'
    }
    let repstr = r
    if( projektireports.includes(r) || r==='tuotekortti'){
        repstr = `${r}:${p.uid}`
    }
    repstr = encodeURIComponent(repstr)
    const url =`${backEnd}legacyReport?jwt=${jwt}&reportstr=${repstr}`
//    window.location = url;
    const link = document.getElementById('legacyXLSLink')
    link.href = url
    link.click()
//    window.open(url, '_blank');
//    const win = window.open(url, '_blank');
//    win.focus();
}

const placeholder ='Lataa raportti...'
const ReportList = ({person,project,jwt})=>{
    const reports = (()=>{
        let r = [
            placeholder,
            ...tuntireports,
            ...(project ? projektireports : [] ),
            ...(person && person.shortlisted ? shortlist : [] )
        ]
        r = new Set(r)
        return [...r]
    })()

    return <div style={{ overflowY: "auto", marginLeft: "35px" }}>
        <select  onChange={e=>download(e.target.value, project,jwt)} className="select-style" placeholder={{placeholder}} value={{placeholder}} >
          {reports.map(rep => {
            return <option value={rep} key={rep} style={{ display: "block", margin: "5px", marginLeft: "25px" }}>
                {rep}
              </option>;
          })}
        </select>
        <a href='/' id='legacyXLSLink' download> </a>
        
      </div>;
}

export default ReportList;